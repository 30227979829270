// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import DynamicFormChoiceSchema from './dynamic-form-choice-schema';

const schema = {
  $id: '/dynamic-form-field-schema',
  type: 'object',
  required: ['orderSequence', 'type'],
  nullable: true,
  properties: {
    dynamicFormFieldDefinitionId: {
      type: 'string',
      format: 'uuid',
    },
    orderSequence: {
      type: 'integer',
    },
    description: {
      type: 'string',
      nullable: true,
    },
    isMandatory: {
      type: 'boolean',
    },
    enableMultiline: {
      type: 'boolean',
    },
    type: {
      type: 'integer',
    },
    groupFields: {
      type: 'array',
      nullable: true,
    },
    choices: {
      type: 'array',
      nullable: true,
      items: { ...DynamicFormChoiceSchema },
    },
  },
} as IJSONSchema;

export default schema;
