// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDynamicFormByIdRequestModel from '../../model/get-dynamic-form-by-id-request-model';
import CreateDynamicFormRequestModel from '../../model/create-dynamic-form-request-model';
import DynamicFormModel from '../../model/dynamic-form-model';

export default (service: Servicelayer) => ({
  /**  */
  async getDynamicFormById(request: GetDynamicFormByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetDynamicFormByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-dynamic-form-by-id'] ?? 'DynamicForms/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DynamicFormModel.toModel(dto));
  },
  /**  */
  async createDynamicForm(request: CreateDynamicFormRequestModel, config?: RequestConfig) {
    const requestDTO = CreateDynamicFormRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-dynamic-form'] ?? 'DynamicForms';
    const response = await service.post<any>(endpointPath, {
      body: {
        dynamicFormDefinitionId: requestDTO.dynamicFormDefinitionId,
        languageIso: requestDTO.languageIso,
        contactId: requestDTO.contactId,
        contact: requestDTO.contact,
        formFields: requestDTO.formFields,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
});
