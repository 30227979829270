// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetSubscriptionContractRequestModel from '../../model/get-subscription-contract-request-model';
import GetSubscriptionContractRequestDTO from '../dto/get-subscription-contract-request-dto';
import dtoSchema from '../schemas/get-subscription-contract-request-schema';

export default abstract class GetSubscriptionContractRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetSubscriptionContractRequestDTO>) {
    super();
    if (data) {
      this.subscriptionType = data.subscriptionType;
      this.userId = data.userId;
    }
  }
  /**
  * @type {int32}
  */
  subscriptionType?: 1 | 2; // SubscriptionTypeDTO
  /**
  * @type {int64}
  */
  userId?: number;

  static toModel(dto: DeepPartial<GetSubscriptionContractRequestDTO>): GetSubscriptionContractRequestModel;
  static toModel(dto: DeepPartial<GetSubscriptionContractRequestDTO> | undefined | null): GetSubscriptionContractRequestModel | undefined;
  static toModel(dto: DeepPartial<GetSubscriptionContractRequestDTO> | undefined | null): GetSubscriptionContractRequestModel | undefined {
    return dto ? new GetSubscriptionContractRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetSubscriptionContractRequestModel> | GetSubscriptionContractRequestModel): GetSubscriptionContractRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      subscriptionType: unwrapped.subscriptionType,
      userId: unwrapped.userId,
    } as GetSubscriptionContractRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetSubscriptionContractRequestModel, can not map to GetSubscriptionContractRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
