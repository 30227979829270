// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormChoiceResourceViewModel from '../../../../viewModel/resource/DynamicFormChoiceResourceViewModel';
import DynamicFormChoiceResourceDTO from '../../dto/resource/DynamicFormChoiceResourceDTO';

export default abstract class DynamicFormChoiceResourceModel extends BaseModel<DynamicFormChoiceResourceDTO> {
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get value() { return this.dto.value; }
  set value(value) { this.dto.value = value; }
  /**
  * @type {int32}
  */
  get orderSequence() { return this.dto.orderSequence; }
  set orderSequence(value) { this.dto.orderSequence = value; }
  /**
  */
  get isChosenByDefault() { return this.dto.isChosenByDefault; }
  set isChosenByDefault(value) { this.dto.isChosenByDefault = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.description = qs.description;
    this.dto.value = qs.value;
    value = Number.parseFloat(qs.ordersequence);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.orderSequence = value;
    value = qs.isChosenByDefault === 'true' ? true : qs.ischosenbydefault;
    value = qs.isChosenByDefault === 'false' ? false : undefined;
    this.dto.isChosenByDefault = value;
  }
}
