// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import MembershipStatusModel from '../../model/membership-status-model';
import MembershipStatusDTO from '../dto/membership-status-dto';
import dtoSchema from '../schemas/membership-status-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class MembershipStatusModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<MembershipStatusDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.membershipId = data.membershipId;
      this.name = data.name ?? undefined;
      this.statusId = data.statusId;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int64}
  */
  membershipId?: number;
  /**
  */
  name?: string;
  /**
  * @type {int32}
  */
  statusId?: number;

  static toModel(dto: DeepPartial<MembershipStatusDTO>): MembershipStatusModel;
  static toModel(dto: DeepPartial<MembershipStatusDTO> | undefined | null): MembershipStatusModel | undefined;
  static toModel(dto: DeepPartial<MembershipStatusDTO> | undefined | null): MembershipStatusModel | undefined {
    return dto ? new MembershipStatusModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<MembershipStatusModel> | MembershipStatusModel): MembershipStatusDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      membershipId: unwrapped.membershipId,
      name: unwrapped.name,
      statusId: unwrapped.statusId,
    } as MembershipStatusDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for MembershipStatusModel, can not map to MembershipStatusDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
