<template>
  <div class="gs-price-display">
    <div v-if="loading" class="ix-3">
      <p-progress-linear indeterminate></p-progress-linear>
    </div>
    <template v-else-if="priceDisplay !== undefined">
      <div class="price-section">
        <span>
          <p-conditional-wrapper :show="prominent">
            <strong>
              {{ priceDisplay }}
            </strong>
          </p-conditional-wrapper>
        </span>
        <div v-if="value.unitGrossPrice && value.quantity > 1" class="caption-2 mb-1">
          {{ $t('shop.perPiece') }} / {{ $format.currency(value.unitGrossPrice, value.currency) }}
        </div>
      </div>
      <div v-if="!$auth.isLoggedIn && prominent" class="caption-2 ix-7">
        {{ $t('shop.article.advantageOfMemberPricing') }}
        <a :href="$config.values['auth-redirects-login']">{{ $t('shop.article.pleaseLogIn') }}</a>.
      </div>
    </template>
    <template v-else>
      -
    </template>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsPriceDisplay',
    props: {
      value: { type: Object, default: () => ({}) as {totalGrossPrice?: number, unitGrossPrice?: number, currency: string} },
      loading: { type: Boolean, default: false },
      prominent: { type: Boolean, default: false },
    },
    data: () => ({

    }),
    computed: {
      priceDisplay(): string | undefined {
        const price = this.value?.totalGrossPrice ?? this.value?.unitGrossPrice;
        return this.$format.currency(price, this.value.currency);
      },
    },
  });
</script>
