// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CorrespondenceContactModel from '../../model/correspondence-contact-model';
import CorrespondenceContactDTO from '../dto/correspondence-contact-dto';
import dtoSchema from '../schemas/correspondence-contact-schema';
import ContactAddressModel from '../../model/contact-address-model';

export default abstract class CorrespondenceContactModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CorrespondenceContactDTO>) {
    super();
    if (data) {
      this.customerNumber = data.customerNumber ?? undefined;
      this.contactId = data.contactId ?? undefined;
      this.parentContactId = data.parentContactId ?? undefined;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.address = data.address ? ContactAddressModel.toModel(data.address) : undefined;
      this.isEqualToDefaultAddress = data.isEqualToDefaultAddress;
      this.isTrusteeAddress = data.isTrusteeAddress ?? undefined;
    }
  }
  /**
  * @type {int32}
  */
  customerNumber?: number;
  /**
  * @type {uuid}
  */
  contactId?: string;
  /**
  * @type {uuid}
  */
  parentContactId?: string;
  /**
  */
  languageIso2?: string;
  /**
  */
  address?: ContactAddressModel;
  /**
  */
  isEqualToDefaultAddress?: boolean;
  /**
  */
  isTrusteeAddress?: boolean;

  static toModel(dto: DeepPartial<CorrespondenceContactDTO>): CorrespondenceContactModel;
  static toModel(dto: DeepPartial<CorrespondenceContactDTO> | undefined | null): CorrespondenceContactModel | undefined;
  static toModel(dto: DeepPartial<CorrespondenceContactDTO> | undefined | null): CorrespondenceContactModel | undefined {
    return dto ? new CorrespondenceContactModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CorrespondenceContactModel> | CorrespondenceContactModel): CorrespondenceContactDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      customerNumber: unwrapped.customerNumber,
      contactId: unwrapped.contactId,
      parentContactId: unwrapped.parentContactId,
      languageIso2: unwrapped.languageIso2,
      address: unwrapped.address,
      isEqualToDefaultAddress: unwrapped.isEqualToDefaultAddress,
      isTrusteeAddress: unwrapped.isTrusteeAddress,
    } as CorrespondenceContactDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CorrespondenceContactModel, can not map to CorrespondenceContactDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
