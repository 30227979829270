import Vue from 'vue';
import ShopOrderResourceModel from '../../generated/types/model/resource/ShopOrderResourceModel';

export default class ShopOrderResourceViewModel extends ShopOrderResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  // Is used in table because p-table uses DTO Model and not ViewModel (p-table needs a refactoring)
  protected transformToDTO() {
    (this.dto as any).invoiceCustomerAddress = this.invoiceCustomerAddress;
    (this.dto as any).deliveryCustomerAddress = this.deliveryCustomerAddress;
  }

  get invoiceCustomerFullName() {
    if (Vue._.isSet(this.invoiceAddress_FirstName) && Vue._.isSet(this.invoiceAddress_LastName)) {
      return `${Vue._.isSet(this.invoiceAddress_Salutation) ? Vue.$t(`form.address.salutationEnum.${this.invoiceAddress_Salutation}`) : ''} ${this.invoiceAddress_FirstName} ${this.invoiceAddress_LastName ?? ''}`;
    }
    return undefined;
  }

  get deliveryCustomerFullName() {
    if (Vue._.isSet(this.deliveryAddress_FirstName) && Vue._.isSet(this.deliveryAddress_LastName)) {
      return `${Vue._.isSet(this.deliveryAddress_Salutation) ? Vue.$t(`form.address.salutationEnum.${this.deliveryAddress_Salutation}`) : ''} ${this.deliveryAddress_FirstName} ${this.deliveryAddress_LastName ?? ''}`;
    }
    return undefined;
  }

  get invoiceCustomerAddress() {
    if (Vue._.isSet(this.invoiceAddress_FirstName) && Vue._.isSet(this.invoiceAddress_LastName)) {
      return `${this.invoiceAddress_FirstName} ${this.invoiceAddress_LastName}`;
    }
    if (Vue._.isSet(this.invoiceAddress_Company)) {
      return this.invoiceAddress_Company;
    }
    return undefined;
  }

  get deliveryCustomerAddress() {
    if (Vue._.isSet(this.deliveryCustomerFullName) && Vue._.isSet(this.deliveryAddress_LastName)) {
      return `${this.deliveryAddress_FirstName} ${this.deliveryAddress_LastName}`;
    }
    if (Vue._.isSet(this.deliveryAddress_Company)) {
      return this.deliveryAddress_Company;
    }
    return undefined;
  }
}
