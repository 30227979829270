// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormChoiceModel from '../../model/dynamic-form-choice-model';
import DynamicFormChoiceDTO from '../dto/dynamic-form-choice-dto';
import dtoSchema from '../schemas/dynamic-form-choice-schema';

export default abstract class DynamicFormChoiceModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormChoiceDTO>) {
    super();
    if (data) {
      this.description = data.description ?? undefined;
      this.value = data.value ?? undefined;
      this.orderSequence = data.orderSequence;
      this.isChosenByDefault = data.isChosenByDefault;
    }
  }
  /**
  */
  description?: string;
  /**
  */
  value?: string;
  /**
  * @type {int32}
  */
  orderSequence?: number;
  /**
  */
  isChosenByDefault?: boolean;

  static toModel(dto: DeepPartial<DynamicFormChoiceDTO>): DynamicFormChoiceModel;
  static toModel(dto: DeepPartial<DynamicFormChoiceDTO> | undefined | null): DynamicFormChoiceModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormChoiceDTO> | undefined | null): DynamicFormChoiceModel | undefined {
    return dto ? new DynamicFormChoiceModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormChoiceModel> | DynamicFormChoiceModel): DynamicFormChoiceDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      description: unwrapped.description,
      value: unwrapped.value,
      orderSequence: unwrapped.orderSequence,
      isChosenByDefault: unwrapped.isChosenByDefault,
    } as DynamicFormChoiceDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DynamicFormChoiceModel, can not map to DynamicFormChoiceDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
