import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import DynamicFormsApi from '../generated/api/DynamicForms';

export default (service: Servicelayer) => ({
  ...DynamicFormsApi(service),


  getKitchenSink() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-kitchenSinkId']);
  },

  getMemberChangeAddress() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-memberChangeAddress']);
  },

  getGastroProfessionalTerminate() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-gastroProfessionalTerminate']);
  },

  getGastroJournalTerminate() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-gastroJournalTerminate']);
  },

  getGastroJournalPause() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-gastroJournalPause']);
  },

  getGastroJournalInterrupt() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-gastroJournalInterrupt']);
  },

  getGastroJournalForward() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-gastroJournalForward']);
  },

  getSupportFeedback() {
    return this.getDynamicFormById(Vue.$config.values['dynamicForm-supportFeedback']);
  },

});
