// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AccommodationModel from '../../model/accommodation-model';
import AccommodationDTO from '../dto/accommodation-dto';
import dtoSchema from '../schemas/accommodation-schema';

export default abstract class AccommodationModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AccommodationDTO>) {
    super();
    if (data) {
      this.isAccommodation = data.isAccommodation ?? undefined;
      this.accommodationTypeId = data.accommodationTypeId ?? undefined;
      this.accommodationTypeName = data.accommodationTypeName ?? undefined;
      this.isClassified = data.isClassified;
      this.classificationStarsCount = data.classificationStarsCount ?? undefined;
      this.classificationRoomCount = data.classificationRoomCount ?? undefined;
      this.classificationBedCount = data.classificationBedCount ?? undefined;
    }
  }
  /**
  */
  isAccommodation?: boolean;
  /**
  * @type {uuid}
  */
  accommodationTypeId?: string;
  /**
  */
  accommodationTypeName?: string;
  /**
  */
  isClassified?: boolean;
  /**
  * @type {int32}
  */
  classificationStarsCount?: number;
  /**
  * @type {int32}
  */
  classificationRoomCount?: number;
  /**
  * @type {int32}
  */
  classificationBedCount?: number;

  static toModel(dto: DeepPartial<AccommodationDTO>): AccommodationModel;
  static toModel(dto: DeepPartial<AccommodationDTO> | undefined | null): AccommodationModel | undefined;
  static toModel(dto: DeepPartial<AccommodationDTO> | undefined | null): AccommodationModel | undefined {
    return dto ? new AccommodationModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AccommodationModel> | AccommodationModel): AccommodationDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      isAccommodation: unwrapped.isAccommodation,
      accommodationTypeId: unwrapped.accommodationTypeId,
      accommodationTypeName: unwrapped.accommodationTypeName,
      isClassified: unwrapped.isClassified,
      classificationStarsCount: unwrapped.classificationStarsCount,
      classificationRoomCount: unwrapped.classificationRoomCount,
      classificationBedCount: unwrapped.classificationBedCount,
    } as AccommodationDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AccommodationModel, can not map to AccommodationDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
