import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ListModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/listModel';
import MembershipsApi from '../generated/api/Memberships';
import Int64LookupResourceViewModel from '../viewModel/resource/Int64LookupResourceViewModel';
import GetMembershipDocumentsRequestViewModel from '../viewModel/request/Memberships/GetMembershipDocumentsRequestViewModel';
import GetBusinessesRequestViewModel from '../viewModel/request/Memberships/GetBusinessesRequestViewModel';
import CreateMembershipRequestViewModel from '../viewModel/resource/CreateMembershipRequestViewModel';
import GetInitialHoklaRequestRequestViewModel from '../viewModel/request/HotelClassifications/GetInitialHoklaRequestRequestViewModel';
import GetInitialMembershipRequestRequestViewModel from '../viewModel/request/Memberships/GetInitialMembershipRequestRequestViewModel';

export default (service: Servicelayer) => ({
  ...MembershipsApi(service),

  async getMyInitialMembershipRequest() {
    const user = await Vue.$auth.getUser();
    const options = new GetInitialMembershipRequestRequestViewModel();
    options.userId = user?.id!;
    return this.getInitialMembershipRequest(options);
  },

  getMyMembershipId() {
    const membershipIdString = Vue.$auth.getClaim('gs-MembershipId');
    if (!membershipIdString) {
      return undefined;
    }
    return Number.parseInt(membershipIdString, 10);
  },

  getIsInactive() {
    const isMemberStatus = Vue.$auth.getClaim('gs-Member') ?? '0';
    return isMemberStatus === '0';
  },

  getIsProcessing() {
    const isMemberStatus = Vue.$auth.getClaim('gs-Member') ?? '0';
    return isMemberStatus === '1';
  },

  getIsPending() {
    const isMemberStatus = Vue.$auth.getClaim('gs-Member') ?? '0';
    return isMemberStatus === '2';
  },

  getIsMember() {
    const isMemberStatus = Vue.$auth.getClaim('gs-Member') ?? '0';
    return isMemberStatus === '3';
  },

  getIsBlocked() {
    const isMemberStatus = Vue.$auth.getClaim('gs-Member') ?? '0';
    return isMemberStatus === '4';
  },

  getIsExpired() {
    const isMemberStatus = Vue.$auth.getClaim('gs-Member') ?? '0';
    return isMemberStatus === '5';
  },

  async createMembership(model: Omit<CreateMembershipRequestViewModel, 'userId'>) {
    const request = new CreateMembershipRequestViewModel().fromModel(model);
    request.userId = Vue.$auth.user?.id!;
    return MembershipsApi(service).createMembership(request);
  },

  async getMyMembership() {
    const membershipId = service.api.memberships.getMyMembershipId();
    if (!membershipId) {
      return undefined;
    }
    try {
      const myMembership = await this.getMembershipDetail(membershipId);
      return myMembership;
    } catch (error: any) {
      if (error && error.status !== 404 && error.status !== 401) {
        throw error;
      }
    }
    return undefined;
  },

  async getListOfMyDocuments(query: GetMembershipDocumentsRequestViewModel) {
    const membershipId = service.api.memberships.getMyMembershipId();
    if (!membershipId) {
      return undefined;
    }
    return this.getMembershipDocuments(membershipId, query);
  },

  async getMyOpenInvoices() {
    const membershipId = service.api.memberships.getMyMembershipId();
    if (!membershipId) {
      return undefined;
    }
    return this.getMembershipOpenInvoices(membershipId);
  },

  async getListOfMyBusinesses(query: GetBusinessesRequestViewModel) {
    const membershipId = service.api.memberships.getMyMembershipId();
    if (!membershipId) {
      return undefined;
    }
    return this.getBusinesses(membershipId, query);
  },

  async getMyBusinessDetail(businessId: string) {
    const membershipId = service.api.memberships.getMyMembershipId();
    if (!membershipId) {
      return undefined;
    }
    return this.getBusinessDetail(membershipId, businessId);
  },

  async downloadByUrl(url: string, downloadFileName?: string) {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = url;
    tempLink.setAttribute('download', downloadFileName ?? 'GastroSuisse.pdf');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  },

  async getPayrollLookup(id?: string | number, searchTerm?: string) {
    const payrollLookup = new ListModel(Int64LookupResourceViewModel).fromDTO({
      links: [] as any[],
      paginationData: {} as any,
      items: [
        {
          caption: Vue.$t('memberPortal.createMembership.label.payrollCategory.1'),
          id: 1,
        },
        {
          caption: Vue.$t('memberPortal.createMembership.label.payrollCategory.2'),
          id: 2,
        },
        {
          caption: Vue.$t('memberPortal.createMembership.label.payrollCategory.3'),
          id: 3,
        },
        {
          caption: Vue.$t('memberPortal.createMembership.label.payrollCategory.4'),
          id: 4,
        },
        {
          caption: Vue.$t('memberPortal.createMembership.label.payrollCategory.5'),
          id: 5,
        },
      ],
    });
    if (id) {
      payrollLookup.items = payrollLookup.items.filter((item) => item.id === id);
    } else if (searchTerm) {
      payrollLookup.items = payrollLookup.items.filter((item) => item.caption!.includes(searchTerm));
    }
    return payrollLookup;
  },

});
