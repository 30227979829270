// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CheckIsMembershipValidRequestModel from '../../model/check-is-membership-valid-request-model';
import CheckIsMembershipValidRequestDTO from '../dto/check-is-membership-valid-request-dto';
import dtoSchema from '../schemas/check-is-membership-valid-request-schema';

export default abstract class CheckIsMembershipValidRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CheckIsMembershipValidRequestDTO>) {
    super();
    if (data) {
      this.customerNumber = data.customerNumber;
    }
  }
  /**
  * @type {int32}
  */
  customerNumber?: number;

  static toModel(dto: DeepPartial<CheckIsMembershipValidRequestDTO>): CheckIsMembershipValidRequestModel;
  static toModel(dto: DeepPartial<CheckIsMembershipValidRequestDTO> | undefined | null): CheckIsMembershipValidRequestModel | undefined;
  static toModel(dto: DeepPartial<CheckIsMembershipValidRequestDTO> | undefined | null): CheckIsMembershipValidRequestModel | undefined {
    return dto ? new CheckIsMembershipValidRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CheckIsMembershipValidRequestModel> | CheckIsMembershipValidRequestModel): CheckIsMembershipValidRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      customerNumber: unwrapped.customerNumber,
    } as CheckIsMembershipValidRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CheckIsMembershipValidRequestModel, can not map to CheckIsMembershipValidRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
