import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import Vue from 'vue';
import ShopInvoiceAddressResourceModel from '../../generated/types/model/resource/ShopInvoiceAddressResourceModel';

export default class ShopInvoiceAddressResourceViewModel extends ShopInvoiceAddressResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  private get isCompanySet() { return Vue._.isSet(this.company); }
}
