<template>
  <div>
    <template v-for="section, index in summary.sections">
      <p-container :key="section.title" fluid>
        <h5 v-if="!withAccordions && section.title">
          {{ section.title }}
        </h5>
        <p-conditional-wrapper :show="withAccordions && section.title">
          <p-accordion
            :key="section.title"
            v-model="accordionsExpaned[index]"
            :title="section.title"
          >
            <p-table v-if="asTable" :dense="dense" class="summary">
              <template #rows>
                <slot name="prepend-summary"></slot>
                <tr v-for="item in section.items" :key="item.label" :class="item.label">
                  <td :class="{'ix-3': $media.isMobile, 'ix-5': $media.isTablet, 'ix-7': $media.isDesktop}">
                    {{ item.label }}
                  </td>
                  <td>{{ item.displayValue }}</td>
                </tr>
                <slot name="append-summary"></slot>
              </template>
            </p-table>
            <div v-else>
              <p-row v-for="item in section.items" :key="item.label" :dense="dense" :class="item.label">
                <p-col xs12 md6 sm4 lg3>
                  <span>{{ item.label }}</span>
                </p-col>
                <p-col xs12 md6 sm4 lg3>
                  <span>{{ item.displayValue }}</span>
                </p-col>
              </p-row>
            </div>
          </p-accordion>
        </p-conditional-wrapper>
        <p-divider v-if="withAccordions" :key="section.title + 'divider'" class="mb-2"></p-divider>
      </p-container>
    </template>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import RequestSummaryResourceDTO from '../services/v1/generated/types/dto/resource/RequestSummaryResourceDTO';

  export default Vue.extend({
    props: {
      value: { type: Object, default: () => ({}) },
      asTable: { type: Boolean, default: false },
      withAccordions: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
    },
    data: () => ({
      accordionsExpaned: [],
    }),
    computed: {
      summary() {
        const summaryModel = (this.value as any)?.summary ?? this.value;
        const getDTO = summaryModel?.getDTO as ()=>any;
        const summary = getDTO ? getDTO.call(summaryModel) : this.value;
        return summary as RequestSummaryResourceDTO;
      },
    },
  });
</script>
