import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import IssuuDocumentsApi from '../generated/api/IssuuDocuments';

export default (service: Servicelayer) => ({
  ...IssuuDocumentsApi(service),

  // Add custom calls here

});
