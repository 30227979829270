// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import MembershipModel from '../../model/membership-model';
import MembershipDTO from '../dto/membership-dto';
import dtoSchema from '../schemas/membership-schema';
import ContactModel from '../../model/contact-model';
import CorrespondenceContactModel from '../../model/correspondence-contact-model';

export default abstract class MembershipModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<MembershipDTO>) {
    super();
    if (data) {
      this.membershipNumber = data.membershipNumber ?? undefined;
      this.entryDate = data.entryDate ?? undefined;
      this.registrationDate = data.registrationDate ?? undefined;
      this.payrollCategoryId = data.payrollCategoryId;
      this.isActive = data.isActive;
      this.membershipTemplateNumber = data.membershipTemplateNumber;
      this.businessContact = data.businessContact ? ContactModel.toModel(data.businessContact) : undefined;
      this.memberContact = data.memberContact ? ContactModel.toModel(data.memberContact) : undefined;
      this.partnerContact = data.partnerContact ? ContactModel.toModel(data.partnerContact) : undefined;
      this.billingContact = data.billingContact ? CorrespondenceContactModel.toModel(data.billingContact) : undefined;
      this.debtorContact = data.debtorContact ? ContactModel.toModel(data.debtorContact) : undefined;
      this.businessUnitContact = data.businessUnitContact ? ContactModel.toModel(data.businessUnitContact) : undefined;
      this.regionContact = data.regionContact ? ContactModel.toModel(data.regionContact) : undefined;
    }
  }
  /**
  */
  membershipNumber?: string;
  /**
  * @type {date-time}
  */
  entryDate?: string;
  /**
  * @type {date-time}
  */
  registrationDate?: string;
  /**
  * @type {int32}
  */
  payrollCategoryId?: number;
  /**
  */
  isActive?: boolean;
  /**
  * @type {int32}
  */
  membershipTemplateNumber?: number;
  /**
  */
  businessContact?: ContactModel;
  /**
  */
  memberContact?: ContactModel;
  /**
  */
  partnerContact?: ContactModel;
  /**
  */
  billingContact?: CorrespondenceContactModel;
  /**
  */
  debtorContact?: ContactModel;
  /**
  */
  businessUnitContact?: ContactModel;
  /**
  */
  regionContact?: ContactModel;

  static toModel(dto: DeepPartial<MembershipDTO>): MembershipModel;
  static toModel(dto: DeepPartial<MembershipDTO> | undefined | null): MembershipModel | undefined;
  static toModel(dto: DeepPartial<MembershipDTO> | undefined | null): MembershipModel | undefined {
    return dto ? new MembershipModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<MembershipModel> | MembershipModel): MembershipDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      membershipNumber: unwrapped.membershipNumber,
      entryDate: unwrapped.entryDate,
      registrationDate: unwrapped.registrationDate,
      payrollCategoryId: unwrapped.payrollCategoryId,
      isActive: unwrapped.isActive,
      membershipTemplateNumber: unwrapped.membershipTemplateNumber,
      businessContact: unwrapped.businessContact,
      memberContact: unwrapped.memberContact,
      partnerContact: unwrapped.partnerContact,
      billingContact: unwrapped.billingContact,
      debtorContact: unwrapped.debtorContact,
      businessUnitContact: unwrapped.businessUnitContact,
      regionContact: unwrapped.regionContact,
    } as MembershipDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for MembershipModel, can not map to MembershipDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
