// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import HotelClassificationBusinessListModel from '../../model/hotel-classification-business-list-model';
import HotelClassificationBusinessListDTO from '../dto/hotel-classification-business-list-dto';
import dtoSchema from '../schemas/hotel-classification-business-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class HotelClassificationBusinessListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<HotelClassificationBusinessListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.businessName = data.businessName ?? undefined;
      this.classificationExpirationDate = data.classificationExpirationDate ?? undefined;
      this.classificationStars = data.classificationStars ?? undefined;
      this.classificationComment = data.classificationComment ?? undefined;
      this.isClassified = data.isClassified;
      this.canRequestClassification = data.canRequestClassification;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  businessName?: string;
  /**
  * @type {date-time}
  */
  classificationExpirationDate?: string;
  /**
  */
  classificationStars?: string;
  /**
  */
  classificationComment?: string;
  /**
  */
  isClassified?: boolean;
  /**
  */
  canRequestClassification?: boolean;

  static toModel(dto: DeepPartial<HotelClassificationBusinessListDTO>): HotelClassificationBusinessListModel;
  static toModel(dto: DeepPartial<HotelClassificationBusinessListDTO> | undefined | null): HotelClassificationBusinessListModel | undefined;
  static toModel(dto: DeepPartial<HotelClassificationBusinessListDTO> | undefined | null): HotelClassificationBusinessListModel | undefined {
    return dto ? new HotelClassificationBusinessListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<HotelClassificationBusinessListModel> | HotelClassificationBusinessListModel): HotelClassificationBusinessListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      businessName: unwrapped.businessName,
      classificationExpirationDate: unwrapped.classificationExpirationDate,
      classificationStars: unwrapped.classificationStars,
      classificationComment: unwrapped.classificationComment,
      isClassified: unwrapped.isClassified,
      canRequestClassification: unwrapped.canRequestClassification,
    } as HotelClassificationBusinessListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for HotelClassificationBusinessListModel, can not map to HotelClassificationBusinessListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
