// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetInvoiceAddressRequestModel from '../../model/get-invoice-address-request-model';
import GetInvoiceAddressRequestDTO from '../dto/get-invoice-address-request-dto';
import dtoSchema from '../schemas/get-invoice-address-request-schema';

export default abstract class GetInvoiceAddressRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetInvoiceAddressRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
    }
  }
  /**
  * @type {int64}
  */
  userId?: number;

  static toModel(dto: DeepPartial<GetInvoiceAddressRequestDTO>): GetInvoiceAddressRequestModel;
  static toModel(dto: DeepPartial<GetInvoiceAddressRequestDTO> | undefined | null): GetInvoiceAddressRequestModel | undefined;
  static toModel(dto: DeepPartial<GetInvoiceAddressRequestDTO> | undefined | null): GetInvoiceAddressRequestModel | undefined {
    return dto ? new GetInvoiceAddressRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetInvoiceAddressRequestModel> | GetInvoiceAddressRequestModel): GetInvoiceAddressRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
    } as GetInvoiceAddressRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetInvoiceAddressRequestModel, can not map to GetInvoiceAddressRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
