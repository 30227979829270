import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ConsultingCasesApi from '../generated/api/ConsultingCases';

export default (service: Servicelayer) => ({
  ...ConsultingCasesApi(service),

  // Add custom calls here

});
