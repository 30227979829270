<template>
  <div class="gs-remove-from-cart">
    <p-button
      :dense="dense"
      :spacious="spacious"
      :loading="isLoading"
      :disabled="isLoading"
      @click="removeFromBasket"
    >
      <p-icon :dense="dense" :spacious="spacious">
        delete
      </p-icon>
    </p-button>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsRemoveFromCart',
    props: {
      dense: { type: Boolean, default: false },
      spacious: { type: Boolean, default: false },
      articleId: { type: Number, default: undefined },
    },
    data: () => ({
      isLoading: false,
    }),
    methods: {
      async removeFromBasket() {
        this.isLoading = true;
        await this.$service.api.shopBasket.removeFromBasket(this.articleId!);
        this.isLoading = false;
      },
    },
  });
</script>
