// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/consulting-case-list-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    customerContactId: {
      type: 'string',
      format: 'uuid',
    },
    subject: {
      type: 'string',
      nullable: true,
    },
    lastEdited: {
      type: 'string',
      format: 'date-time',
    },
    status: {
      enum: [
        0,
        1,
        2,
        3,
      ],
    },
  },
} as IJSONSchema;

export default schema;
