import Vue from 'vue';
import apiKeysServices from './api/api-keys';
import appServices from './api/app';
import appLogsServices from './api/app-logs';
import appSystemServices from './api/app-system';
import appSystemsServices from './api/app-systems';
import appSystemWebApisServices from './api/app-system-web-apis';
import attributesServices from './api/attributes';
import authServices from './api/auth';
import businessUnitsServices from './api/business-units';
import callLogsServices from './api/call-logs';
import consultingCasesServices from './api/consulting-cases';
import contactIdMappingsServices from './api/contact-id-mappings';
import digitalAssetServices from './api/digital-asset';
import documentsServices from './api/documents';
import dynamicFormServices from './api/dynamic-form';
import dynamicFormsServices from './api/dynamic-forms';
import esuranceServices from './api/e-surance';
import fieldMappingServices from './api/field-mapping';
import hotelClassificationsServices from './api/hotel-classifications';
import httpMethodLookupServices from './api/http-method-lookup';
import hygienecontrolServices from './api/hygienecontrol';
import integrationCallsServices from './api/integration-calls';
import integrationTasksServices from './api/integration-tasks';
import issuuDocumentsServices from './api/issuu-documents';
import locationsServices from './api/locations';
import meServices from './api/me';
import membershipsServices from './api/memberships';
import onlinePaymentTransactionServices from './api/online-payment-transaction';
import processesServices from './api/processes';
import sequencenumbersServices from './api/sequencenumbers';
import shopArticleDigitalAssetServices from './api/shop-article-digital-asset';
import shopArticlesServices from './api/shop-articles';
import shopArticleTaxesServices from './api/shop-article-taxes';
import shopArticleTaxHistoriesServices from './api/shop-article-tax-histories';
import shopBasketServices from './api/shop-basket';
import shopCategoriesServices from './api/shop-categories';
import shopCheckoutServices from './api/shop-checkout';
import shopOrderItemsServices from './api/shop-order-items';
import shopOrdersServices from './api/shop-orders';
import shopPaymentsServices from './api/shop-payments';
import shopSuppliersServices from './api/shop-suppliers';
import subscriptionsServices from './api/subscriptions';
import tenantsServices from './api/tenants';
import usersServices from './api/users';

export default () => ({
  apiKeys: { ...apiKeysServices(Vue.$service) },
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  appSystems: { ...appSystemsServices(Vue.$service) },
  appSystemWebApis: { ...appSystemWebApisServices(Vue.$service) },
  attributes: { ...attributesServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  businessUnits: { ...businessUnitsServices(Vue.$service) },
  callLogs: { ...callLogsServices(Vue.$service) },
  consultingCases: { ...consultingCasesServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  digitalAsset: { ...digitalAssetServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForm: { ...dynamicFormServices(Vue.$service) },
  dynamicForms: { ...dynamicFormsServices(Vue.$service) },
  esurance: { ...esuranceServices(Vue.$service) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  hotelClassifications: { ...hotelClassificationsServices(Vue.$service) },
  httpMethodLookup: { ...httpMethodLookupServices(Vue.$service) },
  hygienecontrol: { ...hygienecontrolServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  issuuDocuments: { ...issuuDocumentsServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  memberships: { ...membershipsServices(Vue.$service) },
  onlinePaymentTransaction: { ...onlinePaymentTransactionServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  shopArticleDigitalAsset: { ...shopArticleDigitalAssetServices(Vue.$service) },
  shopArticles: { ...shopArticlesServices(Vue.$service) },
  shopArticleTaxes: { ...shopArticleTaxesServices(Vue.$service) },
  shopArticleTaxHistories: { ...shopArticleTaxHistoriesServices(Vue.$service) },
  shopBasket: { ...shopBasketServices(Vue.$service) },
  shopCategories: { ...shopCategoriesServices(Vue.$service) },
  shopCheckout: { ...shopCheckoutServices(Vue.$service) },
  shopOrderItems: { ...shopOrderItemsServices(Vue.$service) },
  shopOrders: { ...shopOrdersServices(Vue.$service) },
  shopPayments: { ...shopPaymentsServices(Vue.$service) },
  shopSuppliers: { ...shopSuppliersServices(Vue.$service) },
  subscriptions: { ...subscriptionsServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
});
