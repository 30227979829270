// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import MemberBusinessAddressSchema from './member-business-address-schema';
import CorrespondenceAddressSchema from './correspondence-address-schema';
import MembershipBusinessSchema from './membership-business-schema';
import BasketSchema from './basket-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/create-membership-request-schema',
  type: 'object',
  required: ['userId', 'businessUnitId', 'membershipTemplateId', 'payrollCategoryId'],
  properties: {
    userId: {
      type: 'integer',
    },
    businessUnitId: {
      type: 'string',
      format: 'uuid',
    },
    membershipTemplateId: {
      type: 'string',
      format: 'uuid',
    },
    payrollCategoryId: {
      type: 'integer',
    },
    isMembershipReEntry: {
      type: 'boolean',
    },
    entryDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    socialInsuranceId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    hasOperatingLicence: {
      type: 'boolean',
      nullable: true,
    },
    operatingLicenceOwner: {
      type: 'string',
      nullable: true,
    },
    dateOperatingLicenceIssued: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    hasCertificateOfProficiency: {
      type: 'boolean',
      nullable: true,
    },
    dateCertificateOfProficiencyIssued: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    certificateOfProficiencyCantonId: {
      type: 'integer',
      nullable: true,
    },
    certificateOfProficiencyCourse: {
      type: 'string',
      nullable: true,
    },
    isMemberContactReadonly: {
      type: 'boolean',
    },
    memberContact: {
      ...ContactSchema,
    },
    isMemberBusinessContactReadonly: {
      type: 'boolean',
    },
    memberBusinessContact: {
      ...MemberBusinessAddressSchema,
    },
    isMemberCorrespondeceContactReadonly: {
      type: 'boolean',
    },
    memberCorrespondenceContact: {
      ...CorrespondenceAddressSchema,
    },
    isMembershipBusinessReadonly: {
      type: 'boolean',
    },
    membershipBusiness: {
      ...MembershipBusinessSchema,
    },
    orderBasket: {
      ...BasketSchema,
    },
    summary: {
      ...RequestSummarySchema,
    },
    acceptIsRelatedToGastro: {
      type: 'boolean',
    },
    acceptGsoc: {
      type: 'boolean',
    },
    acceptAgb: {
      type: 'boolean',
    },
    acceptFilledOutTruthful: {
      type: 'boolean',
    },
    acceptGsStatutes: {
      type: 'boolean',
    },
    newsletter: {
      type: 'boolean',
    },
    acceptGdpr: {
      type: 'boolean',
    },
    acceptContactForClassification: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
