// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import UpdateShopBasketItemRequestViewModel from '../../../../viewModel/resource/UpdateShopBasketItemRequestViewModel';
import UpdateShopBasketItemRequestDTO from '../../dto/resource/UpdateShopBasketItemRequestDTO';

export default abstract class UpdateShopBasketItemRequestModel extends BaseModel<UpdateShopBasketItemRequestDTO> {
  /**
  * @type {int64}
  */
  get articleId() { return this.dto.articleId; }
  set articleId(value) { this.dto.articleId = value; }
  /**
  * @type {int32}
  */
  get quantity() { return this.dto.quantity; }
  set quantity(value) { this.dto.quantity = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.articleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.articleId = value;
    value = Number.parseFloat(qs.quantity);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.quantity = value;
  }
}
