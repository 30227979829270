// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopBasketItemsListResourceViewModel from '../../../../viewModel/resource/ShopBasketItemsListResourceViewModel';
import ShopBasketResourceViewModel from '../../../../viewModel/resource/ShopBasketResourceViewModel';
import ShopBasketResourceDTO from '../../dto/resource/ShopBasketResourceDTO';

export default abstract class ShopBasketResourceModel extends BaseModel<ShopBasketResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {uuid}
  */
  get publicBasketId() { return this.dto.publicBasketId; }
  set publicBasketId(value) { this.dto.publicBasketId = value; }
  /**
  * @type {int32}
  */
  get totalQuantity() { return this.dto.totalQuantity; }
  set totalQuantity(value) { this.dto.totalQuantity = value; }
  /**
  * @type {double}
  */
  get totalGrossPrice() { return this.dto.totalGrossPrice; }
  set totalGrossPrice(value) { this.dto.totalGrossPrice = value; }
  /**
  * @type {double}
  */
  get totalNetPrice() { return this.dto.totalNetPrice; }
  set totalNetPrice(value) { this.dto.totalNetPrice = value; }
  /**
  * @type {double}
  */
  get totalTaxAmount() { return this.dto.totalTaxAmount; }
  set totalTaxAmount(value) { this.dto.totalTaxAmount = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, ShopBasketItemsListResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get language_ISO2() { return this.dto.language_ISO2; }
  set language_ISO2(value) { this.dto.language_ISO2 = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.publicBasketId = qs.publicbasketid;
    value = Number.parseFloat(qs.totalquantity);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalQuantity = value;
    value = Number.parseFloat(qs.totalgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalGrossPrice = value;
    value = Number.parseFloat(qs.totalnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalNetPrice = value;
    value = Number.parseFloat(qs.totaltaxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalTaxAmount = value;
    this.dto.currency = qs.currency;
    this.dto.language_ISO2 = qs.language_iso2;
  }
}
