<template>
  <div class="mx-2">
    <p-request-alert v-model="errorResponse"></p-request-alert>
    <div v-if="isLoading" class="my-4">
      <p-progress-linear indeterminate></p-progress-linear>
    </div>
    <template v-if="subscriptionData">
      <p-row>
        <p-col v-if="subscriptionData && subscriptionData.subscriptionArticle" xs12>
          <p-alert type="info" :value="true">
            {{ subscriptionData.subscriptionArticle.subscriptionDisplay }}
          </p-alert>
        </p-col>
        <p-col xs12>
          <h4>{{ $t('form.address.billingAddress') }}</h4>
          <p-row>
            <p-col xs12 sm10 md9 lg8 xl7>
              <gs-summary-display v-model="subscriptionData.billingSummary" as-table dense></gs-summary-display>
            </p-col>
          </p-row>
        </p-col>
        <p-col v-if="showDeliveryAddress" xs12>
          <p-col xs12>
            <h4>{{ $t('form.address.deliveryAddress') }}</h4>
            <p-row>
              <p-col xs12 sm10 md9 lg8 xl7>
                <gs-summary-display v-model="subscriptionData.deliverySummary" as-table dense></gs-summary-display>
              </p-col>
            </p-row>
          </p-col>
        </p-col>
      </p-row>
    </template>
    <template v-if="subscriptionData && _.component.hasSlot(this, 'actions')">
      <p-divider></p-divider>
      <slot name="actions"></slot>
    </template>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import SubscriptionContractResourceViewModel from '@/src/services/v1/viewModel/resource/SubscriptionContractResourceViewModel';

  export default Vue.extend({
    props: {
      serviceFunction: { default: () => (() => Promise.resolve()), type: Function },
      showDeliveryAddress: { default: false, type: Boolean },
    },
    data: () => ({
      isLoading: false,
      errorResponse: undefined,
      subscriptionData: undefined as SubscriptionContractResourceViewModel | undefined,
      membershipId: undefined as any,
    }),
    async beforeMount() {
      this.membershipId = await this.$service.api.memberships.getMyMembershipId();
      this.populateData();
    },
    methods: {
      async populateData() {
        this.isLoading = true;
        try {
          this.subscriptionData = await this.serviceFunction();
          this.$emit('input', this.subscriptionData);
          // response = await this.$service.api.subscriptions.getMyGastroProfessionalSubscriptions();
        } catch (error: any) {
          this.errorResponse = error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
