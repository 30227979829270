import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import BusinessUnitsApi from '../generated/api/BusinessUnits';

export default (service: Servicelayer) => ({
  ...BusinessUnitsApi(service),

  async getMyBusinessUnit() {
    const user = await Vue.$auth.getUser();
    return this.getBusinessUnitByUserId(user!.id!);
  },

});
