<template>
  <div class="gs-article-item-detail">
    <p-progress-linear v-if="!value.initalized" class="my-3" indeterminate></p-progress-linear>
    <template v-else>
      <p-row>
        <p-col md12 lg4>
          <gs-article-image :image-id="value.articleNumber" :height="$media.isDesktop ? 324 : 224"></gs-article-image>
        </p-col>
        <p-col md12 lg8>
          <p-row column fill-height no-gutters>
            <p-col grow>
              <h2>{{ value.caption }}</h2>
              <p-button
                v-if="isAdmin"
                class="absolute-top-right"
                dense
                @click="$router.push(`/gs/shop/articles/${value.id}/edit`, () => {})"
              >
                <p-icon dense>
                  edit
                </p-icon>
              </p-button>
              <p-divider class="mt-3 mb-6"></p-divider>
              <p class="pre-line caption-1">
                {{ value.shortDescription }}
              </p>
            </p-col>
            <p-col shrink>
              <p-row column :justify-end="$media.isDesktop">
                <p-col :justify-end="$media.isDesktop">
                  <gs-price-display prominent :loading="isPriceLoading" :value="price"></gs-price-display>
                </p-col>
                <p-col :justify-end="$media.isDesktop">
                  <div>
                    <gs-add-to-basket :article-id="value.id" has-quantity></gs-add-to-basket>
                  </div>
                </p-col>
              </p-row>
            </p-col>
          </p-row>
        </p-col>
      </p-row>
      <p-container>
        <p-row column>
          <template v-if="value.description">
            <p-col><p-divider class="my-4"></p-divider></p-col>
            <p-col>
              <h3>
                <strong>
                  {{ $t('shop.article.description') }}
                </strong>
              </h3>
            </p-col>
            <p-col>
              <p class="pre-line">
                {{ value.description }}
              </p>
            </p-col>
          </template>
          <template v-if="value.additionalInfo">
            <p-col><p-divider class="my-4"></p-divider></p-col>
            <p-col>
              <h3>
                <strong>
                  {{ $t('shop.article.additionalInfo') }}
                </strong>
              </h3>
            </p-col>
            <p-col>
              <p class="pre-line">
                {{ value.additionalInfo }}
              </p>
            </p-col>
          </template>
        </p-row>
      </p-container>
    </template>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import ShopArticleResourceViewModel from '@/src/services/v1/viewModel/resource/ShopArticleResourceViewModel';
  import GetShopArticlePriceRequestViewModel from '@/src/services/v1/viewModel/request/ShopArticles/GetShopArticlePriceRequestViewModel';
  import ShopTaxedPriceResourceViewModel from '@/src/services/v1/viewModel/resource/ShopTaxedPriceResourceViewModel';
  import gsAddToBasket from './gs-add-to-basket.vue';

  export default Vue.extend({
    name: 'GsArticleItemDetail',
    components: { gsAddToBasket },
    props: {
      value: { type: Object as PropType<ShopArticleResourceViewModel>, default: () => new ShopArticleResourceViewModel() },
    },
    data: () => ({
      price: new ShopTaxedPriceResourceViewModel(),
      isPriceLoading: false,
    }),
    computed: {
      isAdmin(): boolean {
        return this.$auth.isInRole('Admin', 'Hostadmin');
      },
    },
    watch: {
      'value.id': {
        immediate: true,
        handler() {
          if (this._.isSet(this.value.id)) {
            this.$debounce(() => {
              this.loadPrice();
            }, 50, this)();
          }
        },
      },
    },
    methods: {
      async loadPrice() {
        this.isPriceLoading = true;
        const req = new GetShopArticlePriceRequestViewModel().fromModel({
          userId: this.$auth.user?.id ?? undefined,
        });
        this.price = await this.$service.api.shopArticles.getShopArticlePrice(this.value.id!, req);
        this.isPriceLoading = false;
      },
    },
  });
</script>
