// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateShopOrderRequestModel from '../../model/update-shop-order-request-model';
import UpdateShopOrderRequestDTO from '../dto/update-shop-order-request-dto';
import dtoSchema from '../schemas/update-shop-order-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class UpdateShopOrderRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateShopOrderRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.userId = data.userId;
      this.orderNumber = data.orderNumber ?? undefined;
      this.orderReference = data.orderReference ?? undefined;
      this.orderStateId = data.orderStateId;
      this.orderDate = data.orderDate ?? undefined;
      this.currency = data.currency ?? undefined;
      this.totalGrossPrice = data.totalGrossPrice;
      this.totalNetPrice = data.totalNetPrice;
      this.totalTaxAmount = data.totalTaxAmount;
      this.invoiceAddress_Salutation = data.invoiceAddress_Salutation;
      this.invoiceAddress_Company = data.invoiceAddress_Company ?? undefined;
      this.invoiceAddress_FirstName = data.invoiceAddress_FirstName ?? undefined;
      this.invoiceAddress_LastName = data.invoiceAddress_LastName ?? undefined;
      this.invoiceAddress_Street = data.invoiceAddress_Street ?? undefined;
      this.invoiceAddress_Number = data.invoiceAddress_Number ?? undefined;
      this.invoiceAddress_POBox = data.invoiceAddress_POBox ?? undefined;
      this.invoiceAddress_AdditionalLine = data.invoiceAddress_AdditionalLine ?? undefined;
      this.invoiceAddress_PostalCode = data.invoiceAddress_PostalCode ?? undefined;
      this.invoiceAddress_City = data.invoiceAddress_City ?? undefined;
      this.invoiceAddress_CountryIso2 = data.invoiceAddress_CountryIso2 ?? undefined;
      this.invoiceAddress_Phone = data.invoiceAddress_Phone ?? undefined;
      this.invoiceAddress_MobilePhone = data.invoiceAddress_MobilePhone ?? undefined;
      this.invoiceAddress_Email = data.invoiceAddress_Email ?? undefined;
      this.invoiceAddress_CrmContactId = data.invoiceAddress_CrmContactId ?? undefined;
      this.deliveryAddress_IsDifferent = data.deliveryAddress_IsDifferent ?? undefined;
      this.deliveryAddress_Salutation = data.deliveryAddress_Salutation;
      this.deliveryAddress_Company = data.deliveryAddress_Company ?? undefined;
      this.deliveryAddress_FirstName = data.deliveryAddress_FirstName ?? undefined;
      this.deliveryAddress_LastName = data.deliveryAddress_LastName ?? undefined;
      this.deliveryAddress_Street = data.deliveryAddress_Street ?? undefined;
      this.deliveryAddress_Number = data.deliveryAddress_Number ?? undefined;
      this.deliveryAddress_POBox = data.deliveryAddress_POBox ?? undefined;
      this.deliveryAddress_AdditionalLine = data.deliveryAddress_AdditionalLine ?? undefined;
      this.deliveryAddress_PostalCode = data.deliveryAddress_PostalCode ?? undefined;
      this.deliveryAddress_City = data.deliveryAddress_City ?? undefined;
      this.deliveryAddress_CountryIso2 = data.deliveryAddress_CountryIso2 ?? undefined;
      this.deliveryAddress_Phone = data.deliveryAddress_Phone ?? undefined;
      this.deliveryAddress_MobilePhone = data.deliveryAddress_MobilePhone ?? undefined;
      this.deliveryAddress_Email = data.deliveryAddress_Email ?? undefined;
      this.language_ISO2 = data.language_ISO2 ?? undefined;
      this.membershipId = data.membershipId ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  */
  orderNumber?: string;
  /**
  */
  orderReference?: string;
  /**
  * @type {int64}
  */
  orderStateId?: 1 | 2 | 3 | 4 | 5; // ShopOrderStateDTO
  /**
  * @type {date-time}
  */
  orderDate?: string;
  /**
  */
  currency?: string;
  /**
  * @type {double}
  */
  totalGrossPrice?: number;
  /**
  * @type {double}
  */
  totalNetPrice?: number;
  /**
  * @type {double}
  */
  totalTaxAmount?: number;
  /**
  * @type {int32}
  */
  invoiceAddress_Salutation?: 0 | 1; // ShopSalutationTypeDTO
  /**
  */
  invoiceAddress_Company?: string;
  /**
  */
  invoiceAddress_FirstName?: string;
  /**
  */
  invoiceAddress_LastName?: string;
  /**
  */
  invoiceAddress_Street?: string;
  /**
  */
  invoiceAddress_Number?: string;
  /**
  */
  invoiceAddress_POBox?: string;
  /**
  */
  invoiceAddress_AdditionalLine?: string;
  /**
  */
  invoiceAddress_PostalCode?: string;
  /**
  */
  invoiceAddress_City?: string;
  /**
  */
  invoiceAddress_CountryIso2?: string;
  /**
  */
  invoiceAddress_Phone?: string;
  /**
  */
  invoiceAddress_MobilePhone?: string;
  /**
  */
  invoiceAddress_Email?: string;
  /**
  */
  invoiceAddress_CrmContactId?: string;
  /**
  */
  deliveryAddress_IsDifferent?: boolean;
  /**
  * @type {int32}
  */
  deliveryAddress_Salutation?: 0 | 1; // ShopSalutationTypeDTO
  /**
  */
  deliveryAddress_Company?: string;
  /**
  */
  deliveryAddress_FirstName?: string;
  /**
  */
  deliveryAddress_LastName?: string;
  /**
  */
  deliveryAddress_Street?: string;
  /**
  */
  deliveryAddress_Number?: string;
  /**
  */
  deliveryAddress_POBox?: string;
  /**
  */
  deliveryAddress_AdditionalLine?: string;
  /**
  */
  deliveryAddress_PostalCode?: string;
  /**
  */
  deliveryAddress_City?: string;
  /**
  */
  deliveryAddress_CountryIso2?: string;
  /**
  */
  deliveryAddress_Phone?: string;
  /**
  */
  deliveryAddress_MobilePhone?: string;
  /**
  */
  deliveryAddress_Email?: string;
  /**
  */
  language_ISO2?: string;
  /**
  * @type {int64}
  */
  membershipId?: number;

  static toModel(dto: DeepPartial<UpdateShopOrderRequestDTO>): UpdateShopOrderRequestModel;
  static toModel(dto: DeepPartial<UpdateShopOrderRequestDTO> | undefined | null): UpdateShopOrderRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateShopOrderRequestDTO> | undefined | null): UpdateShopOrderRequestModel | undefined {
    return dto ? new UpdateShopOrderRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateShopOrderRequestModel> | UpdateShopOrderRequestModel): UpdateShopOrderRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      userId: unwrapped.userId,
      orderNumber: unwrapped.orderNumber,
      orderReference: unwrapped.orderReference,
      orderStateId: unwrapped.orderStateId,
      orderDate: unwrapped.orderDate,
      currency: unwrapped.currency,
      totalGrossPrice: unwrapped.totalGrossPrice,
      totalNetPrice: unwrapped.totalNetPrice,
      totalTaxAmount: unwrapped.totalTaxAmount,
      invoiceAddress_Salutation: unwrapped.invoiceAddress_Salutation,
      invoiceAddress_Company: unwrapped.invoiceAddress_Company,
      invoiceAddress_FirstName: unwrapped.invoiceAddress_FirstName,
      invoiceAddress_LastName: unwrapped.invoiceAddress_LastName,
      invoiceAddress_Street: unwrapped.invoiceAddress_Street,
      invoiceAddress_Number: unwrapped.invoiceAddress_Number,
      invoiceAddress_POBox: unwrapped.invoiceAddress_POBox,
      invoiceAddress_AdditionalLine: unwrapped.invoiceAddress_AdditionalLine,
      invoiceAddress_PostalCode: unwrapped.invoiceAddress_PostalCode,
      invoiceAddress_City: unwrapped.invoiceAddress_City,
      invoiceAddress_CountryIso2: unwrapped.invoiceAddress_CountryIso2,
      invoiceAddress_Phone: unwrapped.invoiceAddress_Phone,
      invoiceAddress_MobilePhone: unwrapped.invoiceAddress_MobilePhone,
      invoiceAddress_Email: unwrapped.invoiceAddress_Email,
      invoiceAddress_CrmContactId: unwrapped.invoiceAddress_CrmContactId,
      deliveryAddress_IsDifferent: unwrapped.deliveryAddress_IsDifferent,
      deliveryAddress_Salutation: unwrapped.deliveryAddress_Salutation,
      deliveryAddress_Company: unwrapped.deliveryAddress_Company,
      deliveryAddress_FirstName: unwrapped.deliveryAddress_FirstName,
      deliveryAddress_LastName: unwrapped.deliveryAddress_LastName,
      deliveryAddress_Street: unwrapped.deliveryAddress_Street,
      deliveryAddress_Number: unwrapped.deliveryAddress_Number,
      deliveryAddress_POBox: unwrapped.deliveryAddress_POBox,
      deliveryAddress_AdditionalLine: unwrapped.deliveryAddress_AdditionalLine,
      deliveryAddress_PostalCode: unwrapped.deliveryAddress_PostalCode,
      deliveryAddress_City: unwrapped.deliveryAddress_City,
      deliveryAddress_CountryIso2: unwrapped.deliveryAddress_CountryIso2,
      deliveryAddress_Phone: unwrapped.deliveryAddress_Phone,
      deliveryAddress_MobilePhone: unwrapped.deliveryAddress_MobilePhone,
      deliveryAddress_Email: unwrapped.deliveryAddress_Email,
      language_ISO2: unwrapped.language_ISO2,
      membershipId: unwrapped.membershipId,
    } as UpdateShopOrderRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateShopOrderRequestModel, can not map to UpdateShopOrderRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
