import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ShopOrderItemsApi from '../generated/api/ShopOrderItems';

export default (service: Servicelayer) => ({
  ...ShopOrderItemsApi(service),

  // Add custom calls here

});
