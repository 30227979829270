// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DownloadAssetRequestModel from '../../model/download-asset-request-model';
import DownloadAssetRequestDTO from '../dto/download-asset-request-dto';
import dtoSchema from '../schemas/download-asset-request-schema';

export default abstract class DownloadAssetRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DownloadAssetRequestDTO>) {
    super();
    if (data) {
      this.orderId = data.orderId;
      this.orderItemId = data.orderItemId;
      this.assetId = data.assetId;
    }
  }
  /**
  * @type {int64}
  */
  orderId?: number;
  /**
  * @type {int64}
  */
  orderItemId?: number;
  /**
  * @type {int64}
  */
  assetId?: number;

  static toModel(dto: DeepPartial<DownloadAssetRequestDTO>): DownloadAssetRequestModel;
  static toModel(dto: DeepPartial<DownloadAssetRequestDTO> | undefined | null): DownloadAssetRequestModel | undefined;
  static toModel(dto: DeepPartial<DownloadAssetRequestDTO> | undefined | null): DownloadAssetRequestModel | undefined {
    return dto ? new DownloadAssetRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DownloadAssetRequestModel> | DownloadAssetRequestModel): DownloadAssetRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      orderId: unwrapped.orderId,
      orderItemId: unwrapped.orderItemId,
      assetId: unwrapped.assetId,
    } as DownloadAssetRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DownloadAssetRequestModel, can not map to DownloadAssetRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
