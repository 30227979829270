// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/update-basket-item-request-schema',
  type: 'object',
  required: ['articleId', 'quantity', 'id'],
  properties: {
    articleId: {
      type: 'integer',
    },
    quantity: {
      type: 'integer',
    },
    id: {
      type: 'integer',
    },
    userId: {
      type: 'integer',
    },
    publicBasketId: {
      type: 'string',
      format: 'uuid',
    },
  },
} as IJSONSchema;

export default schema;
