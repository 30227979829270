// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopBasketItemsListModel from '../../model/shop-basket-items-list-model';
import ShopBasketItemsListDTO from '../dto/shop-basket-items-list-dto';
import dtoSchema from '../schemas/shop-basket-items-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ShopBasketItemsListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopBasketItemsListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.basketId = data.basketId;
      this.basketPublicId = data.basketPublicId ?? undefined;
      this.shopArticleId = data.shopArticleId;
      this.shopArticleTaxId = data.shopArticleTaxId;
      this.taxRate = data.taxRate;
      this.taxName = data.taxName ?? undefined;
      this.taxShortName = data.taxShortName ?? undefined;
      this.articleNumber = data.articleNumber ?? undefined;
      this.supplierId = data.supplierId ?? undefined;
      this.supplierArticleNumber = data.supplierArticleNumber ?? undefined;
      this.caption = data.caption ?? undefined;
      this.mainPictureId = data.mainPictureId ?? undefined;
      this.quantity = data.quantity;
      this.description = data.description ?? undefined;
      this.shortDescription = data.shortDescription ?? undefined;
      this.totalNetPrice = data.totalNetPrice;
      this.totalGrossPrice = data.totalGrossPrice;
      this.totalTaxAmount = data.totalTaxAmount;
      this.unitGrossPrice = data.unitGrossPrice;
      this.unitNetPrice = data.unitNetPrice;
      this.taxAmount = data.taxAmount;
      this.hasShipping = data.hasShipping;
      this.isDeliveryCost = data.isDeliveryCost;
      this.hasLicense = data.hasLicense;
      this.isDownload = data.isDownload;
      this.digitalAssetId = data.digitalAssetId ?? undefined;
      this.crmItemPriceNumber = data.crmItemPriceNumber ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  basketId?: number;
  /**
  * @type {uuid}
  */
  basketPublicId?: string;
  /**
  * @type {int64}
  */
  shopArticleId?: number;
  /**
  * @type {double}
  */
  shopArticleTaxId?: number;
  /**
  * @type {double}
  */
  taxRate?: number;
  /**
  */
  taxName?: string;
  /**
  */
  taxShortName?: string;
  /**
  */
  articleNumber?: string;
  /**
  * @type {int64}
  */
  supplierId?: number;
  /**
  */
  supplierArticleNumber?: string;
  /**
  */
  caption?: string;
  /**
  * @type {int64}
  */
  mainPictureId?: number;
  /**
  * @type {int32}
  */
  quantity?: number;
  /**
  */
  description?: string;
  /**
  */
  shortDescription?: string;
  /**
  * @type {double}
  */
  totalNetPrice?: number;
  /**
  * @type {double}
  */
  totalGrossPrice?: number;
  /**
  * @type {double}
  */
  totalTaxAmount?: number;
  /**
  * @type {double}
  */
  unitGrossPrice?: number;
  /**
  * @type {double}
  */
  unitNetPrice?: number;
  /**
  * @type {double}
  */
  taxAmount?: number;
  /**
  */
  hasShipping?: boolean;
  /**
  */
  isDeliveryCost?: boolean;
  /**
  */
  hasLicense?: boolean;
  /**
  */
  isDownload?: boolean;
  /**
  * @type {int64}
  */
  digitalAssetId?: number;
  /**
  * @type {int64}
  */
  crmItemPriceNumber?: number;

  static toModel(dto: DeepPartial<ShopBasketItemsListDTO>): ShopBasketItemsListModel;
  static toModel(dto: DeepPartial<ShopBasketItemsListDTO> | undefined | null): ShopBasketItemsListModel | undefined;
  static toModel(dto: DeepPartial<ShopBasketItemsListDTO> | undefined | null): ShopBasketItemsListModel | undefined {
    return dto ? new ShopBasketItemsListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopBasketItemsListModel> | ShopBasketItemsListModel): ShopBasketItemsListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      basketId: unwrapped.basketId,
      basketPublicId: unwrapped.basketPublicId,
      shopArticleId: unwrapped.shopArticleId,
      shopArticleTaxId: unwrapped.shopArticleTaxId,
      taxRate: unwrapped.taxRate,
      taxName: unwrapped.taxName,
      taxShortName: unwrapped.taxShortName,
      articleNumber: unwrapped.articleNumber,
      supplierId: unwrapped.supplierId,
      supplierArticleNumber: unwrapped.supplierArticleNumber,
      caption: unwrapped.caption,
      mainPictureId: unwrapped.mainPictureId,
      quantity: unwrapped.quantity,
      description: unwrapped.description,
      shortDescription: unwrapped.shortDescription,
      totalNetPrice: unwrapped.totalNetPrice,
      totalGrossPrice: unwrapped.totalGrossPrice,
      totalTaxAmount: unwrapped.totalTaxAmount,
      unitGrossPrice: unwrapped.unitGrossPrice,
      unitNetPrice: unwrapped.unitNetPrice,
      taxAmount: unwrapped.taxAmount,
      hasShipping: unwrapped.hasShipping,
      isDeliveryCost: unwrapped.isDeliveryCost,
      hasLicense: unwrapped.hasLicense,
      isDownload: unwrapped.isDownload,
      digitalAssetId: unwrapped.digitalAssetId,
      crmItemPriceNumber: unwrapped.crmItemPriceNumber,
    } as ShopBasketItemsListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopBasketItemsListModel, can not map to ShopBasketItemsListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
