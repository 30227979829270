// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ESuranceMembershipModel from '../../model/e-surance-membership-model';
import ESuranceMembershipDTO from '../dto/e-surance-membership-dto';
import dtoSchema from '../schemas/e-surance-membership-schema';

export default abstract class ESuranceMembershipModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ESuranceMembershipDTO>) {
    super();
    if (data) {
      this.isReEntry = data.isReEntry;
      this.entryDate = data.entryDate;
      this.businessPostalCode = data.businessPostalCode;
      this.businessPostalCodeAddition = data.businessPostalCodeAddition;
      this.businessCity = data.businessCity;
      this.payrollCategoryKey = data.payrollCategoryKey;
      this.payrollCategoryName = data.payrollCategoryName;
      this.socialInsuranceTypeId = data.socialInsuranceTypeId;
      this.socialInsuranceTypeName = data.socialInsuranceTypeName;
    }
  }
  /**
  */
  isReEntry?: boolean;
  /**
  * @type {date-time}
  */
  entryDate?: string;
  /**
  * @minimum 1
  */
  businessPostalCode?: string;
  /**
  * @minimum 1
  */
  businessPostalCodeAddition?: string;
  /**
  * @minimum 1
  */
  businessCity?: string;
  /**
  * @type {int32}
  */
  payrollCategoryKey?: number;
  /**
  * @minimum 1
  */
  payrollCategoryName?: string;
  /**
  * @type {uuid}
  */
  socialInsuranceTypeId?: string;
  /**
  * @minimum 1
  */
  socialInsuranceTypeName?: string;

  static toModel(dto: DeepPartial<ESuranceMembershipDTO>): ESuranceMembershipModel;
  static toModel(dto: DeepPartial<ESuranceMembershipDTO> | undefined | null): ESuranceMembershipModel | undefined;
  static toModel(dto: DeepPartial<ESuranceMembershipDTO> | undefined | null): ESuranceMembershipModel | undefined {
    return dto ? new ESuranceMembershipModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ESuranceMembershipModel> | ESuranceMembershipModel): ESuranceMembershipDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      isReEntry: unwrapped.isReEntry,
      entryDate: unwrapped.entryDate,
      businessPostalCode: unwrapped.businessPostalCode,
      businessPostalCodeAddition: unwrapped.businessPostalCodeAddition,
      businessCity: unwrapped.businessCity,
      payrollCategoryKey: unwrapped.payrollCategoryKey,
      payrollCategoryName: unwrapped.payrollCategoryName,
      socialInsuranceTypeId: unwrapped.socialInsuranceTypeId,
      socialInsuranceTypeName: unwrapped.socialInsuranceTypeName,
    } as ESuranceMembershipDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ESuranceMembershipModel, can not map to ESuranceMembershipDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
