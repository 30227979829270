// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CalculateMembershipRequestModel from '../../model/calculate-membership-request-model';
import CalculateMembershipRequestDTO from '../dto/calculate-membership-request-dto';
import dtoSchema from '../schemas/calculate-membership-request-schema';

export default abstract class CalculateMembershipRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CalculateMembershipRequestDTO>) {
    super();
    if (data) {
      this.businessUnitId = data.businessUnitId;
      this.categoryId = data.categoryId;
      this.isMembershipReEntry = data.isMembershipReEntry;
      this.postalCode = data.postalCode;
    }
  }
  /**
  * @type {uuid}
  */
  businessUnitId?: string;
  /**
  * @type {int32}
  */
  categoryId?: number;
  /**
  */
  isMembershipReEntry?: boolean;
  /**
  */
  postalCode?: string;

  static toModel(dto: DeepPartial<CalculateMembershipRequestDTO>): CalculateMembershipRequestModel;
  static toModel(dto: DeepPartial<CalculateMembershipRequestDTO> | undefined | null): CalculateMembershipRequestModel | undefined;
  static toModel(dto: DeepPartial<CalculateMembershipRequestDTO> | undefined | null): CalculateMembershipRequestModel | undefined {
    return dto ? new CalculateMembershipRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CalculateMembershipRequestModel> | CalculateMembershipRequestModel): CalculateMembershipRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      businessUnitId: unwrapped.businessUnitId,
      categoryId: unwrapped.categoryId,
      isMembershipReEntry: unwrapped.isMembershipReEntry,
      postalCode: unwrapped.postalCode,
    } as CalculateMembershipRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CalculateMembershipRequestModel, can not map to CalculateMembershipRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
