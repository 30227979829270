// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormPayloadModel from '../../model/dynamic-form-payload-model';
import DynamicFormPayloadDTO from '../dto/dynamic-form-payload-dto';
import dtoSchema from '../schemas/dynamic-form-payload-schema';
import DynamicFormContactModel from '../../model/dynamic-form-contact-model';
import DynamicFormFieldPayloadModel from '../../model/dynamic-form-field-payload-model';

export default abstract class DynamicFormPayloadModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormPayloadDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.definitionId = data.definitionId ?? undefined;
      this.contactId = data.contactId ?? undefined;
      this.contact = data.contact ? DynamicFormContactModel.toModel(data.contact) : undefined;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.businessUnit = data.businessUnit ?? undefined;
      this.fields = data.fields?.map((i) => DynamicFormFieldPayloadModel.toModel(i)) ?? [];
      this.transactionId = data.transactionId ?? undefined;
      this.contactFormType = data.contactFormType;
      this.addressOwnerNumber = data.addressOwnerNumber ?? undefined;
      this.subject = data.subject ?? undefined;
    }
  }
  /**
  */
  id?: string;
  /**
  */
  definitionId?: string;
  /**
  */
  contactId?: string;
  /**
  */
  contact?: DynamicFormContactModel;
  /**
  */
  languageIso2?: string;
  /**
  * @type {int64}
  */
  businessUnit?: number;
  /**
  */
  fields?: DynamicFormFieldPayloadModel[];
  /**
  */
  transactionId?: string;
  /**
  * @type {int32}
  */
  contactFormType?: number;
  /**
  * @type {int32}
  */
  addressOwnerNumber?: number;
  /**
  */
  subject?: string;

  static toModel(dto: DeepPartial<DynamicFormPayloadDTO>): DynamicFormPayloadModel;
  static toModel(dto: DeepPartial<DynamicFormPayloadDTO> | undefined | null): DynamicFormPayloadModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormPayloadDTO> | undefined | null): DynamicFormPayloadModel | undefined {
    return dto ? new DynamicFormPayloadModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormPayloadModel> | DynamicFormPayloadModel): DynamicFormPayloadDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      definitionId: unwrapped.definitionId,
      contactId: unwrapped.contactId,
      contact: unwrapped.contact,
      languageIso2: unwrapped.languageIso2,
      businessUnit: unwrapped.businessUnit,
      fields: unwrapped.fields,
      transactionId: unwrapped.transactionId,
      contactFormType: unwrapped.contactFormType,
      addressOwnerNumber: unwrapped.addressOwnerNumber,
      subject: unwrapped.subject,
    } as DynamicFormPayloadDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DynamicFormPayloadModel, can not map to DynamicFormPayloadDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
