// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ShopArticleTaxResourceListResultViewModel from '../../viewModel/resource/ShopArticleTaxResourceListResultViewModel';
import ShopArticleTaxResourceViewModel from '../../viewModel/resource/ShopArticleTaxResourceViewModel';
import ShopArticleTaxHistoryResourceListResultViewModel from '../../viewModel/resource/ShopArticleTaxHistoryResourceListResultViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getShopArticleTaxes(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-taxes'] ?? 'ShopArticleTaxes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleTaxResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async createShopArticleTax(model: ShopArticleTaxResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-article-tax'] ?? 'ShopArticleTaxes';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopArticleTaxById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-by-id'] ?? 'ShopArticleTaxes/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleTaxResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateShopArticleTax(id: number, model: ShopArticleTaxResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-article-tax'] ?? 'ShopArticleTaxes/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteShopArticleTaxById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-article-tax-by-id'] ?? 'ShopArticleTaxes/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopArticleTaxHistoryById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-history-by-id'] ?? 'ShopArticleTaxes/{id}/History';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleTaxHistoryResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getShopArticleTaxLookup(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-lookup'] ?? 'ShopArticleTaxes/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
});
