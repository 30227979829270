<template>
  <div class="mx-2">
    <template v-if="errorResponse">
      <p-request-alert v-model="errorResponse" :dismissable="false"></p-request-alert>
    </template>
    <template v-else-if="isLoading">
      <p-progress-linear v-if="isLoading" indeterminate></p-progress-linear>
    </template>
    <template v-else-if="isMembershipProcessing && !hasSubscription">
      <p-progress-linear indeterminate color="info" height="4" class="mb-4"></p-progress-linear>
      <p-alert class="mb-4" :value="isMembershipProcessing" type="info" :dismissable="false">
        {{ $t('memberPortal.dashboard.text.membershipInProcess') }}<br>
        {{ $t('memberPortal.dashboard.text.functionalitiesLimited') }}<br>
        <template v-if="additionalProcessingText">
          {{ additionalProcessingText }}
        </template>
      </p-alert>
    </template>
    <template v-else-if="showPending && isMembershipPending && !hasSubscription">
      <p-alert class="mb-4" :value="isMembershipPending" type="info" :dismissable="false">
        {{ $t('memberPortal.dashboard.text.membershipPending') }}
      </p-alert>
    </template>
    <template v-else-if="isMembershipBlocked">
      <p-alert class="mb-4" :value="isMembershipBlocked" type="error" :dismissable="false">
        {{ $t('memberPortal.dashboard.text.membershipLocked') }}
      </p-alert>
    </template>
    <template v-else-if="hasVisibleContent">
      <template v-if="isMembershipExpired">
        <p-alert
          v-if="expiredAlertDismissed"
          class="mb-4 pre-line"
          dismissable
          :value="isMembershipExpired"
          type="warning"
          outline
          @input="onExpiredAlertDismissed"
        >
          {{ $t('memberPortal.dashboard.text.membershipExpired') }}
        </p-alert>
      </template>
      <p-row spacious>
        <template v-if="hasSubscription && (isMembershipActive || isMembershipPending)">
          <slot name="membership-with-subscription"></slot>
        </template>
        <template v-else-if="hasSubscription && isMembershipInactive">
          <slot name="no-membership-with-subscription"></slot>
        </template>
        <template v-else-if="isMembershipInactive && !hasSubscription">
          <slot name="no-membership"></slot>
        </template>
        <template v-else-if="(isMembershipActive || isMembershipPending) && !hasSubscription">
          <slot name="membership"></slot>
        </template>
        <slot name="always"></slot>
      </p-row>
    </template>
    <template v-else>
      <p-alert :value="true" type="error" :dismissable="false" spacious>
        {{ $t('memberPortal.dashboard.text.unknownMemberStatus') }}
      </p-alert>
    </template>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import BusinessUnitResourceViewModel from '../services/v1/viewModel/resource/BusinessUnitResourceViewModel';

  export default Vue.extend({
    props: {
      showPending: { default: false, type: Boolean },
      hasSubscription: { default: false, type: Boolean },
    },
    data: () => ({
      additionalProcessingText: undefined as string | undefined,
      errorResponse: undefined as any,
      isMembershipInactive: false,
      isMembershipProcessing: false,
      isMembershipActive: false,
      isMembershipBlocked: false,
      isMembershipExpired: false,
      isMembershipPending: false,
      isLoading: false,
      intervalHandle: undefined as number | undefined,
      stateUnit: new BusinessUnitResourceViewModel(),
    }),
    computed: {
      expiredAlertDismissed: {
        get(): boolean {
          return !this.$sessionStorage.get('expired-alert-dismissed');
        },
        set(value: boolean) {
          this.$sessionStorage.set('expired-alert-dismissed', value);
        },
      },
      hasVisibleContent() : boolean {
        const showingPending = this.showPending && this.isMembershipPending && !this.hasSubscription;
        return !showingPending && (this.isMembershipPending || this.isMembershipActive || this.hasSubscription || this.isMembershipInactive);
      },
    },
    async mounted() {
      this.isLoading = true;
      this.intervalHandle = setInterval(async () => {
        this.refreshUserData();
      }, 15000);

      try {
        await this.refreshUserData();
      } catch (error) {
        this.errorResponse = error;
      }

      if (!this.hasVisibleContent) {
        try {
          this.stateUnit = await this.$service.api.businessUnits.getMyBusinessUnit();
        } catch (error: any) {
          if (error.status === 404) {
            // Do nothing, user does not have a membership or businessunit cannot be found
          } else {
            this.errorResponse = error;
          }
        }

        const stateCode = this.stateUnit?.stateCode?.toLowerCase();
        const translateParams = {
          name: `${this.stateUnit.unitName}`,
          email: `${this.stateUnit.eMailAddress}`,
          phoneNumber: `${this.$format.phonePretty(this.stateUnit.phoneNumber!)}`,
        };
        const translateKey = `page.dashboard.text.membershipRequestIsProcessing.${stateCode}`;
        const processingText = this.$tAlt(translateKey, undefined as any, translateParams);
        this.additionalProcessingText = processingText;
      }

      this.isLoading = false;
    },
    beforeDestroy() {
      clearInterval(this.intervalHandle);
    },
    methods: {
      onExpiredAlertDismissed() {
        this.expiredAlertDismissed = true;
      },
      async refreshUserData() {
        if (this.$config.values['networking-write-to-console']) {
          console.log('[MEMBERSHIP-CHECK-WRAPPER] Refreshing token in order to check membership claim');
        }
        try {
          await this.$auth.refresh();
        } catch (error) {
          this.errorResponse = error;
        }
        const previousProcessing = this.isMembershipProcessing;
        this.isMembershipInactive = this.$service.api.memberships.getIsInactive() || this.$service.api.memberships.getIsExpired();
        this.isMembershipProcessing = this.$service.api.memberships.getIsProcessing();
        this.isMembershipActive = this.$service.api.memberships.getIsMember();
        this.isMembershipPending = this.$service.api.memberships.getIsPending();
        this.isMembershipBlocked = this.$service.api.memberships.getIsBlocked();
        this.isMembershipExpired = this.$service.api.memberships.getIsExpired();
        if (this.hasVisibleContent) {
          clearInterval(this.intervalHandle);
          if (previousProcessing) {
            window.location.reload();
          }
        }
      },
    },
  });
</script>
