// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import DeleteShopArticleDigitalAssetByIdRequestModel from '../../model/delete-shop-article-digital-asset-by-id-request-model';
import DownloadAssetRequestModel from '../../model/download-asset-request-model';

export default (service: Servicelayer) => ({
  /**  */
  async deleteShopArticleDigitalAssetById(request: DeleteShopArticleDigitalAssetByIdRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopArticleDigitalAssetByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-article-digital-asset-by-id'] ?? 'ShopArticleDigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async downloadAsset(request: DownloadAssetRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadAssetRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-asset'] ?? 'ShopArticleDigitalAsset/Download/{orderId}/{orderItemId}/{assetId}';
    endpointPath = endpointPath.replace(/\{orderId\}/g, `${requestDTO.orderId}`);
    endpointPath = endpointPath.replace(/\{orderItemId\}/g, `${requestDTO.orderItemId}`);
    endpointPath = endpointPath.replace(/\{assetId\}/g, `${requestDTO.assetId}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
