// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ConsultingCaseListModel from '../../model/consulting-case-list-model';
import ConsultingCaseListDTO from '../dto/consulting-case-list-dto';
import dtoSchema from '../schemas/consulting-case-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ConsultingCaseListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ConsultingCaseListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.customerContactId = data.customerContactId;
      this.subject = data.subject ?? undefined;
      this.lastEdited = data.lastEdited;
      this.status = data.status;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {uuid}
  */
  customerContactId?: string;
  /**
  */
  subject?: string;
  /**
  * @type {date-time}
  */
  lastEdited?: string;
  /**
  * @type {int32}
  */
  status?: 0 | 1 | 2 | 3; // ConsultingCaseStatusDTO

  static toModel(dto: DeepPartial<ConsultingCaseListDTO>): ConsultingCaseListModel;
  static toModel(dto: DeepPartial<ConsultingCaseListDTO> | undefined | null): ConsultingCaseListModel | undefined;
  static toModel(dto: DeepPartial<ConsultingCaseListDTO> | undefined | null): ConsultingCaseListModel | undefined {
    return dto ? new ConsultingCaseListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ConsultingCaseListModel> | ConsultingCaseListModel): ConsultingCaseListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      customerContactId: unwrapped.customerContactId,
      subject: unwrapped.subject,
      lastEdited: unwrapped.lastEdited,
      status: unwrapped.status,
    } as ConsultingCaseListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ConsultingCaseListModel, can not map to ConsultingCaseListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
