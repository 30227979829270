// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/update-invoice-address-request-schema',
  type: 'object',
  required: ['id', 'street', 'postalCode', 'city', 'countryIso2', 'email', 'userId'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    salutation: {
      enum: [
        0,
        1,
      ],
    },
    company: {
      type: 'string',
      nullable: true,
    },
    firstName: {
      type: 'string',
      nullable: true,
    },
    lastName: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      minLength: 1,
    },
    houseNumber: {
      type: 'string',
      nullable: true,
    },
    poBox: {
      type: 'string',
      nullable: true,
    },
    additionalLine: {
      type: 'string',
      nullable: true,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
    },
    city: {
      type: 'string',
      minLength: 1,
    },
    countryIso2: {
      type: 'string',
      minLength: 1,
    },
    phone: {
      type: 'string',
      nullable: true,
    },
    mobilePhone: {
      type: 'string',
      nullable: true,
    },
    email: {
      type: 'string',
      minLength: 1,
    },
    isReadonly: {
      type: 'boolean',
    },
    userId: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
