// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopOrderItemRequestModel from '../../model/get-shop-order-item-request-model';
import UpdateShopOrderItemRequestModel from '../../model/update-shop-order-item-request-model';
import DeleteShopOrderItemRequestModel from '../../model/delete-shop-order-item-request-model';
import CreateShopOrderItemRequestModel from '../../model/create-shop-order-item-request-model';
import ShopOrderItemModel from '../../model/shop-order-item-model';

export default (service: Servicelayer) => ({
  /**  */
  async getShopOrderItem(request: GetShopOrderItemRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopOrderItemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-order-item'] ?? 'ShopOrderItems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopOrderItemModel.toModel(dto));
  },
  /**  */
  async updateShopOrderItem(request: UpdateShopOrderItemRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopOrderItemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-order-item'] ?? 'ShopOrderItems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shopOrderId: requestDTO.shopOrderId,
        shopArticleId: requestDTO.shopArticleId,
        shopArticleNumber: requestDTO.shopArticleNumber,
        caption: requestDTO.caption,
        shopSupplierId: requestDTO.shopSupplierId,
        shopSupplierArticleNumber: requestDTO.shopSupplierArticleNumber,
        isDelivery: requestDTO.isDelivery,
        hasLicense: requestDTO.hasLicense,
        isDownload: requestDTO.isDownload,
        digitalAssetId: requestDTO.digitalAssetId,
        quantity: requestDTO.quantity,
        unitNetPrice: requestDTO.unitNetPrice,
        unitGrossPrice: requestDTO.unitGrossPrice,
        taxRate: requestDTO.taxRate,
        taxAmount: requestDTO.taxAmount,
        totalNetPrice: requestDTO.totalNetPrice,
        totalGrossPrice: requestDTO.totalGrossPrice,
        crmItemPriceNumber: requestDTO.crmItemPriceNumber,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopOrderItem(request: DeleteShopOrderItemRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopOrderItemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-order-item'] ?? 'ShopOrderItems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async createShopOrderItem(request: CreateShopOrderItemRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopOrderItemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-order-item'] ?? 'ShopOrderItems';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shopOrderId: requestDTO.shopOrderId,
        shopArticleId: requestDTO.shopArticleId,
        shopArticleNumber: requestDTO.shopArticleNumber,
        caption: requestDTO.caption,
        shopSupplierId: requestDTO.shopSupplierId,
        shopSupplierArticleNumber: requestDTO.shopSupplierArticleNumber,
        isDelivery: requestDTO.isDelivery,
        hasLicense: requestDTO.hasLicense,
        isDownload: requestDTO.isDownload,
        digitalAssetId: requestDTO.digitalAssetId,
        quantity: requestDTO.quantity,
        unitNetPrice: requestDTO.unitNetPrice,
        unitGrossPrice: requestDTO.unitGrossPrice,
        taxRate: requestDTO.taxRate,
        taxAmount: requestDTO.taxAmount,
        totalNetPrice: requestDTO.totalNetPrice,
        totalGrossPrice: requestDTO.totalGrossPrice,
        crmItemPriceNumber: requestDTO.crmItemPriceNumber,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
