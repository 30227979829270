import Vue from 'vue';
import ShopDeliveryAddressResourceModel from '../../generated/types/model/resource/ShopDeliveryAddressResourceModel';

export default class ShopDeliveryAddressResourceViewModel extends ShopDeliveryAddressResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  private get isCompanySet() { return Vue._.isSet(this.company); }
  private get isNameSet() { return Vue._.isSet(this.firstName) || Vue._.isSet(this.lastName); }
}
