import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ShopCategoriesListResourceListResultModel from '../../generated/types/model/resource/ShopCategoriesListResourceListResultModel';
import ShopCategoriesListResourceViewModel from './ShopCategoriesListResourceViewModel';

export default class ShopCategoriesListResourceListResultViewModel extends ShopCategoriesListResourceListResultModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  get defaultCategory() {
    const defaultCategories = this.items.filter((category) => category.isDefault === true);
    if (defaultCategories.length > 1) {
      Vue.$log.error('More than one default category defined, make sure only one default category is set!');
    }
    const [defaultCat] = defaultCategories;
    return defaultCat;
  }

  get sortedItems() {
    const items = _.deepClone(this.items);
    items.sort((a, b) => (a.sort ?? a.id!) - (b.sort ?? b.id!));
    items.forEach((item) => {
      item.children = items.filter((i) => i.parentCategoryId === item.id);
      item.children.sort((a, b) => (a.sort ?? a.id!) - (b.sort ?? b.id!));
      item.children.forEach((child) => {
        child.parent = item;
      });
      let curr = item.parent;
      let depth = 0;
      while (curr) {
        curr = curr.parent;
        depth += 1;
        // Prevent an infinite loop which would freeze the page
        if (depth > 200) {
          Vue.$log.error('Categories structure is self referential, which would cause a loop... fix the categories data!');
          break;
        }
      }
      item.depth = depth;
    });
    const sorted = items.filter((i) => !i.parentCategoryId);
    return sorted;
  }
}
