// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import ShopBasketItemsListSchema from './shop-basket-items-list-schema';

const schema = {
  $id: '/shop-basket-schema',
  type: 'object',
  required: ['id', 'totalQuantity', 'totalGrossPrice', 'totalNetPrice', 'totalTaxAmount'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    publicBasketId: {
      type: 'string',
      format: 'uuid',
    },
    totalQuantity: {
      type: 'integer',
    },
    totalGrossPrice: {
      type: 'number',
    },
    totalNetPrice: {
      type: 'number',
    },
    totalTaxAmount: {
      type: 'number',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    items: {
      type: 'array',
      nullable: true,
      items: { ...ShopBasketItemsListSchema },
    },
    language_ISO2: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
