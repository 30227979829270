import Vue from 'vue';
import CreateHotelClassificationRequestModel from '../../generated/types/model/resource/CreateHotelClassificationRequestModel';
import utils from '../utils';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';

export default class CreateHotelClassificationRequestViewModel extends CreateHotelClassificationRequestModel {
  protected transformToDTO() {
    // Make sure the the website is filled in... because of redundant data 🤷‍♂️
    this.dto.webAddress = this.webAddress ?? this.businessContact?.address?.website;
    // Make sure the address control writes the company name to the busines name field so everything is filled out
    this.dto.businessName = this.businessName ?? this.businessContact?.address?.companyName!;
    this.dto.summary = this.summary.getDTO();
  }

  protected transformFromDTO() {}

  get summary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('memberPortal.classifyHotel.label.stepTitles.2'),
          items: [
            {
              label: Vue.$t('memberPortal.classifyHotel.text.requestClassification'),
              displayValue: `${this.classificationStars}`,
            },
          ],
        },
        {
          title: Vue.$t('memberPortal.classifyHotel.label.stepTitles.3'),
          items: [
            {
              label: Vue.$t('memberPortal.classifyHotel.label.nameOfManagingDirector'),
              displayValue: `${Vue.$t(`form.address.salutationEnum.${this.businessContactCeoSalutation!}`)} ${this.businessContactCeoFirstName} ${this.businessContactCeoLastName}`,
            },
            {
              label: Vue.$t('memberPortal.classifyHotel.label.nameOfContactPerson'),
              displayValue: `${Vue.$t(`form.address.salutationEnum.${this.businessContactPersonSalutation!}`)} ${this.businessContactPersonFirstName} ${this.businessContactPersonLastName}`,
            },
            // Not needed since it's already being filled into the contact data
            // {
            //   label: Vue.$t('memberPortal.classifyHotel.label.webPage'),
            //   displayValue: this.webAddress,
            // },
            ...this.businessContact.address.getSummaryItems(),
            {
              label: Vue.$t('memberPortal.classifyHotel.text.isBillingAddressDifferent'),
              displayValue: this.billingContact.isDifferentFromOwner ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
            ...utils.insertIf(
              this.billingContact.isDifferentFromOwner!,
              this.billingContact.address.getSummaryItems(),
              [],
            ),
          ],
        },
        {
          title: Vue.$t('memberPortal.classifyHotel.label.stepTitles.4'),
          items: [
            {
              label: Vue.$t('memberPortal.classifyHotel.label.accommodationType'),
              displayValue: this.accommodationTypeName,
            },
            {
              label: Vue.$t('memberPortal.classifyHotel.label.isSeasonal'),
              displayValue: this.isSeasonal ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
            {
              label: Vue.$t('memberPortal.classifyHotel.label.numberOfRooms'),
              displayValue: `${this.countOfRooms}`,
            },
            {
              label: Vue.$t('memberPortal.classifyHotel.label.numberOfBeds'),
              displayValue: `${this.countOfBeds ?? 0}`,
            },
          ],
        },
      ].map((section) => ({
        ...section,
        items: section?.items?.filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null) ?? [],
      })),
    });
  }
}
