// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GuidLookupResourcePagingResultViewModel from '../../viewModel/resource/GuidLookupResourcePagingResultViewModel';
import CreateESuranceMembershipRequestViewModel from '../../viewModel/resource/CreateESuranceMembershipRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async lookupSocialInsuranceTypes(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-social-insurance-types'] ?? 'ESurance/SocialInsuranceTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new GuidLookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async lookupAccommodationTypes(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-accommodation-types'] ?? 'ESurance/AccommodationTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new GuidLookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async lookupGastronomyTypes(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-gastronomy-types'] ?? 'ESurance/GastronomyTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new GuidLookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createESuranceMembership(model: CreateESuranceMembershipRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-e-surance-membership'] ?? 'ESurance/Membership';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
