// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/shop-articles-list-by-category-schema',
  type: 'object',
  required: ['id', 'mainPictureId', 'validFrom', 'shopArticleTaxId', 'unitGrossPrice', 'unitNetPrice', 'taxAmount', 'taxRate', 'categoryId', 'sortIndex', 'shopCategoryId'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    articleNumber: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    shortDescription: {
      type: 'string',
      nullable: true,
    },
    mainPictureId: {
      type: 'integer',
    },
    validFrom: {
      type: 'string',
      format: 'date-time',
    },
    validTo: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    shopArticleTaxId: {
      type: 'number',
    },
    isPublished: {
      type: 'boolean',
    },
    language_ISO2: {
      type: 'string',
      nullable: true,
    },
    unitGrossPrice: {
      type: 'number',
    },
    unitNetPrice: {
      type: 'number',
    },
    taxAmount: {
      type: 'number',
    },
    taxRate: {
      type: 'number',
    },
    taxName: {
      type: 'string',
      nullable: true,
    },
    taxShortName: {
      type: 'string',
      nullable: true,
    },
    hasLicense: {
      type: 'boolean',
    },
    isDownload: {
      type: 'boolean',
    },
    digitalAssetId: {
      type: 'integer',
      nullable: true,
    },
    hasShipping: {
      type: 'boolean',
    },
    categoryId: {
      type: 'integer',
    },
    sortIndex: {
      type: 'integer',
    },
    shopCategoryId: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
