<template>
  <p-app theme="glittr">
    <template slot="header">
      <p-toolbar dense color="surface-2">
        <p-row>
          <p-col> </p-col>
          <p-col justify-end>
            <span class="language-selection-menu-wrapper">
              <gs-language-selection></gs-language-selection>
            </span>
            <span class="pwa-add-button-wrapper">
              <p-button :style="{ visibility: isPwaButtonVisible ? 'visible' : 'hidden' }" class="pwa-add-button" dense :title="$t('app.installPWAApp')">
                <p-icon dense>install_desktop</p-icon>
              </p-button>
            </span>
            <span v-if="!$auth.isLoggedIn">
              <p-button dense @click="$auth.redirectToLogin()">
                Login
              </p-button>
            </span>
            <p-menu v-else v-model="profileMenuVisible" bottom left>
              <template slot="activator">
                <p-button color="text" dense>
                  <p-icon dense>
                    person
                  </p-icon>
                </p-button>
              </template>
              <p-card>
                <p-row column>
                  <router-link to="/_me/profile" tag="p-button">
                    {{ $t("core.page.profile.button.showProfile") }}
                  </router-link>
                  <p-button @click="doLogoff">
                    {{ $t("core.page.profile.button.logoff") }}
                    <p-icon right>
                      exit_to_app
                    </p-icon>
                  </p-button>
                </p-row>
              </p-card>
            </p-menu>
          </p-col>
        </p-row>
      </p-toolbar>
      <p-toolbar flat color="app-bg">
        <p-button color="white" flat @click="toggleSideDrawerVisible()">
          <p-icon>menu</p-icon>
        </p-button>
        <router-link to="/">
          <p-img
            class="header-logo"
            width="260"
            height="40px"
            contain
            :src="require('@src/assets/logo.svg')"
          ></p-img>
        </router-link>
      </p-toolbar>
    </template>

    <p-side-drawer
      v-model="isSidedrawerVisible"
      :overlay="$media.isMobile"
      :minified.sync="isNavMinifed"
      minifiable
      color="base"
    >
      <template #drawer-header>
        <div>
          <p-row column no-gutters>
            <p-col justify-center>
              <span>{{ $format.ellipsis(username, 28) }}</span>
            </p-col>
            <p-col justify-center>
              <span>{{ $format.ellipsis(membershipNumber, 28) }}</span>
            </p-col>
          </p-row>
        </div>
      </template>
      <template #drawer-content>
        <p-list-group>
          <p-list spacious>
            <p-list-entry
              v-for="nav in navigation"
              :key="nav.caption"
              color="white"
              :icon-left="nav.icon"
              :to="nav.to"
              @click="navigationClicked"
            >
              {{ nav.caption }}
            </p-list-entry>
            <p-list-group v-if="isAdmin" color="white" icon-left="memory">
              <template #activator>
                Integration
              </template>
              <p-list-entry color="white" to="/_admin/processes" icon-left="account_tree" @click="navigationClicked">
                {{ $t("core.page.processes.page.title") }}
              </p-list-entry>
              <p-list-entry color="white" :to="`/_admin/integrationlog?dateFrom=${date}`" icon-left="merge_type" @click="navigationClicked">
                {{ $t("core.page.integrationlog.page.title") }}
              </p-list-entry>
              <p-list-entry color="white" to="/_admin/contactidmapping" icon-left="social_distance" @click="navigationClicked">
                {{ $t("core.page.contactidmapping.page.title") }}
              </p-list-entry>
              <p-list-entry color="white" to="/_admin/fieldmapping" icon-left="compare_arrows" @click="navigationClicked">
                {{ $t('core.page.fieldmapping.page.list.title') }}
              </p-list-entry>
            </p-list-group>
          </p-list>
        </p-list-group>
        <p-row class=" mt-6">
          <p-col align-end>
            <p-button @click="openHomepage()">
              <p-icon class="ml-4 mr-4">
                public
              </p-icon>{{ $t('memberPortal.gastrosuisse.page.title') }}
            </p-button>
          </p-col>
        </p-row>
      </template>
      <template v-if="isAdmin" #drawer-footer-actions>
        <p-button :secondary="isSettingsVisible" :tertiary="!isSettingsVisible" @click="toggleSettings">
          <p-icon>settings</p-icon>
        </p-button>
      </template>
      <template #content>
        <p-side-drawer v-model="isSettingsVisible" :overlay="$media.isMobile">
          <template #drawer-header-image>
            <p-icon>settings</p-icon>
          </template>
          <template #drawer-header-title>
            {{ $t("admin.settings.page.title") }}
          </template>
          <template #drawer-content>
            <p-list-group>
              <p-list class="mt-3" spacious>
                <p-list-entry to="/_admin/dashboard" icon-left="dashboard" @click="settingsClicked">
                  {{ $t('core.page.dashboard.page.title') }}
                </p-list-entry>
                <p-list-entry :to="`/_admin/applog?levels=error&dateFrom=${date}`" icon-left="list" @click="settingsClicked">
                  {{ $t('core.page.appLog.page.title') }}
                </p-list-entry>
                <p-list-entry :to="`/_admin/calllog?from=${date}`" icon-left="list">
                  {{ $t('core.page.callLog.page.title') }}
                </p-list-entry>
                <p-list-entry to="/_admin/users" icon-left="person" @click="settingsClicked">
                  {{ $t('core.page.user.page.title') }}
                </p-list-entry>
                <p-list-entry to="/_admin/sequencenumbers" icon-left="pin" @click="settingsClicked">
                  {{ $t('core.page.sequencenumbers.page.title') }}
                </p-list-entry>
                <p-list-group icon-left="shopping_cart">
                  <template #activator>
                    {{ $t('shop.page.title') }}
                  </template>
                  <p-list-entry to="/_admin/shop/articles" icon-left="local_offer" @click="settingsClicked">
                    {{ $t('shop.articles') }}
                  </p-list-entry>
                  <p-list-entry to="/_admin/shop/orders" icon-left="local_mall" @click="settingsClicked">
                    {{ $t('shop.orders') }}
                  </p-list-entry>
                  <p-list-entry to="/_admin/shop/digital-assets" icon-left="document_scanner" @click="settingsClicked">
                    {{ $t('shop.shopDigitalAssets') }}
                  </p-list-entry>
                </p-list-group>
              </p-list>
            </p-list-group>
          </template>
          <template v-if="$media.isMobile" #drawer-footer-actions>
            <p-button tertiary @click="isSettingsVisible = false">
              <p-icon>arrow_back</p-icon>
            </p-button>
          </template>
          <template #content>
            <slot></slot>
          </template>
        </p-side-drawer>
      </template>
    </p-side-drawer>
  </p-app>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import User from '@glittr/frontend-core/src/plugins/auth/user';
  import MembershipModel from '../../services/v1/viewModel/resource/MembershipResourceViewModel';

  export default Vue.extend({
    auth: true,
    props: {
      hasBackButton: { type: Boolean, default: false },
      navigation: { type: Array as PropType<{ caption: string, to: string, icon: string }[]>, default: () => [] },
    },
    data: () => ({
      membership: undefined as MembershipModel | undefined,
      date: Vue.$date.now().format('YYYY-MM-DD'),
      profileMenuVisible: false,
      isSidedrawerVisible: Vue.$media.isNotMobile,
      isSettingsVisible: false,
      isNavMinifed: false,
      isMembershipDataLoading: false,
    }),
    computed: {
      user(): User {
        return this.$auth.user ?? ({} as User);
      },
      isHostAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('HostAdmin');
        }
        return isAdmin;
      },
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
      isPwaButtonVisible(): boolean {
        return !!Vue.$store.values.showPwaInstallPromptFunction;
      },
      username(): string {
        if (this.isMembershipDataLoading) {
          return '…';
        }
        if (this.hasMembership && this.membership) {
          const owner = this.membership.memberContact;
          if (owner.address.isCompany) {
            return `${owner.address.companyName}`;
          }
          return `${owner.address.firstName} ${owner.address.lastName}`;
        }
        return this.$auth.user?.userName ?? '';
      },
      membershipNumber(): string {
        if (this.isMembershipDataLoading) {
          return '…';
        }
        if (this.hasMembership && this.membership) {
          return `${this.membership.membershipNumber}`;
        }
        return this.$t('app.noMembership');
      },
      hasMembership(): boolean {
        return this.$service.api.memberships.getIsMember();
      },
    },
    watch: {
      isNavMinifed() {
        this.$sessionStorage.set('isNavMinifed', this.isNavMinifed);
      },
    },
    beforeMount() {
      if (this.$media.isNotMobile) {
        this.isSidedrawerVisible = this.$sessionStorage.get<boolean>('isSidedrawerVisible') ?? true;
        this.isSettingsVisible = this.isAdmin && (this.$sessionStorage.get<boolean>('isSettingsVisible') ?? false);
        this.isNavMinifed = this.$sessionStorage.get<boolean>('isNavMinifed') ?? false;
      }
    },
    async mounted() {
      await this.populateMembershipData();
      // PWA
      const addBtn = document.querySelector('.pwa-add-button') as HTMLElement;
      if (addBtn && Vue.$store.values.showPwaInstallPromptFunction) {
        addBtn.addEventListener('click', Vue.$store.values.showPwaInstallPromptFunction);
      }
    },
    methods: {
      openHomepage() {
        window.open('https://gastrosuisse.ch', '_blank');
      },
      toggleSettings() {
        this.isSettingsVisible = !this.isSettingsVisible;
        if (this.$media.isMobile) {
          this.toggleSideDrawerVisible(false);
        } else {
          this.isNavMinifed = this.isSettingsVisible;
        }
        this.$sessionStorage.set('isSettingsVisible', this.isSettingsVisible);
      },
      navigationClicked() {
        if (this.$media.isMobile) {
          this.toggleSideDrawerVisible(false);
        }
      },
      settingsClicked() {
        if (this.$media.isMobile) {
          this.toggleSettings();
        }
      },
      doLogoff() {
        this.$router.push(this.$config.values['auth-redirects-logout'], () => {});
      },
      async populateMembershipData() {
        this.isMembershipDataLoading = true;
        this.membership = await this.$service.api.memberships.getMyMembership();
        this.isMembershipDataLoading = false;
      },
      toggleSideDrawerVisible(isVisible?: boolean) {
        const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
        this.isSidedrawerVisible = newVisibleState;
        this.$sessionStorage.set('isSidedrawerVisible', this.isSidedrawerVisible);
      },
    },
  });
</script>
