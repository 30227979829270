import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import ShopArticleResourceModel from '../../generated/types/model/resource/ShopArticleResourceModel';

export default class ShopArticleResourceViewModel extends ShopArticleResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  schema = {
    ...queryBuilder.properties({
      // Isnt working for p-date-time-field must be fixed in glittr.core
      // validFrom: { type: 'string', required: true },
      caption: { type: 'string', required: true },
      articleNumber: { type: 'string', required: true },
      taxId: { type: 'number', required: true },
      crmArticleId: { type: 'string', required: true },
    }),
  };
}
