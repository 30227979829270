// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopOrderStateResourceViewModel from '../../../../viewModel/resource/ShopOrderStateResourceViewModel';
import ShopSalutationTypeResourceViewModel from '../../../../viewModel/resource/ShopSalutationTypeResourceViewModel';
import ShopOrderResourceViewModel from '../../../../viewModel/resource/ShopOrderResourceViewModel';
import ShopOrderResourceDTO from '../../dto/resource/ShopOrderResourceDTO';

export default abstract class ShopOrderResourceModel extends BaseModel<ShopOrderResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  */
  get orderNumber() { return this.dto.orderNumber; }
  set orderNumber(value) { this.dto.orderNumber = value; }
  /**
  */
  get orderReference() { return this.dto.orderReference; }
  set orderReference(value) { this.dto.orderReference = value; }
  /**
  * @type {int64}
  */
  get orderStateId() { return this.dto.orderStateId; }
  set orderStateId(value) { this.dto.orderStateId = value; }
  /**
  * @type {date-time}
  */
  get orderDate() { return this.dto.orderDate; }
  set orderDate(value) { this.dto.orderDate = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get totalGrossPrice() { return this.dto.totalGrossPrice; }
  set totalGrossPrice(value) { this.dto.totalGrossPrice = value; }
  /**
  * @type {double}
  */
  get totalNetPrice() { return this.dto.totalNetPrice; }
  set totalNetPrice(value) { this.dto.totalNetPrice = value; }
  /**
  * @type {double}
  */
  get totalTaxAmount() { return this.dto.totalTaxAmount; }
  set totalTaxAmount(value) { this.dto.totalTaxAmount = value; }
  /**
  * @type {int32}
  */
  get invoiceAddress_Salutation() { return this.dto.invoiceAddress_Salutation; }
  set invoiceAddress_Salutation(value) { this.dto.invoiceAddress_Salutation = value; }
  /**
  */
  get invoiceAddress_Company() { return this.dto.invoiceAddress_Company; }
  set invoiceAddress_Company(value) { this.dto.invoiceAddress_Company = value; }
  /**
  */
  get invoiceAddress_FirstName() { return this.dto.invoiceAddress_FirstName; }
  set invoiceAddress_FirstName(value) { this.dto.invoiceAddress_FirstName = value; }
  /**
  */
  get invoiceAddress_LastName() { return this.dto.invoiceAddress_LastName; }
  set invoiceAddress_LastName(value) { this.dto.invoiceAddress_LastName = value; }
  /**
  */
  get invoiceAddress_Street() { return this.dto.invoiceAddress_Street; }
  set invoiceAddress_Street(value) { this.dto.invoiceAddress_Street = value; }
  /**
  */
  get invoiceAddress_Number() { return this.dto.invoiceAddress_Number; }
  set invoiceAddress_Number(value) { this.dto.invoiceAddress_Number = value; }
  /**
  */
  get invoiceAddress_POBox() { return this.dto.invoiceAddress_POBox; }
  set invoiceAddress_POBox(value) { this.dto.invoiceAddress_POBox = value; }
  /**
  */
  get invoiceAddress_AdditionalLine() { return this.dto.invoiceAddress_AdditionalLine; }
  set invoiceAddress_AdditionalLine(value) { this.dto.invoiceAddress_AdditionalLine = value; }
  /**
  */
  get invoiceAddress_PostalCode() { return this.dto.invoiceAddress_PostalCode; }
  set invoiceAddress_PostalCode(value) { this.dto.invoiceAddress_PostalCode = value; }
  /**
  */
  get invoiceAddress_City() { return this.dto.invoiceAddress_City; }
  set invoiceAddress_City(value) { this.dto.invoiceAddress_City = value; }
  /**
  */
  get invoiceAddress_CountryIso2() { return this.dto.invoiceAddress_CountryIso2; }
  set invoiceAddress_CountryIso2(value) { this.dto.invoiceAddress_CountryIso2 = value; }
  /**
  */
  get invoiceAddress_Phone() { return this.dto.invoiceAddress_Phone; }
  set invoiceAddress_Phone(value) { this.dto.invoiceAddress_Phone = value; }
  /**
  */
  get invoiceAddress_MobilePhone() { return this.dto.invoiceAddress_MobilePhone; }
  set invoiceAddress_MobilePhone(value) { this.dto.invoiceAddress_MobilePhone = value; }
  /**
  */
  get invoiceAddress_Email() { return this.dto.invoiceAddress_Email; }
  set invoiceAddress_Email(value) { this.dto.invoiceAddress_Email = value; }
  /**
  */
  get invoiceAddress_CrmContactId() { return this.dto.invoiceAddress_CrmContactId; }
  set invoiceAddress_CrmContactId(value) { this.dto.invoiceAddress_CrmContactId = value; }
  /**
  */
  get deliveryAddress_IsDifferent() { return this.dto.deliveryAddress_IsDifferent; }
  set deliveryAddress_IsDifferent(value) { this.dto.deliveryAddress_IsDifferent = value; }
  /**
  * @type {int32}
  */
  get deliveryAddress_Salutation() { return this.dto.deliveryAddress_Salutation; }
  set deliveryAddress_Salutation(value) { this.dto.deliveryAddress_Salutation = value; }
  /**
  */
  get deliveryAddress_Company() { return this.dto.deliveryAddress_Company; }
  set deliveryAddress_Company(value) { this.dto.deliveryAddress_Company = value; }
  /**
  */
  get deliveryAddress_FirstName() { return this.dto.deliveryAddress_FirstName; }
  set deliveryAddress_FirstName(value) { this.dto.deliveryAddress_FirstName = value; }
  /**
  */
  get deliveryAddress_LastName() { return this.dto.deliveryAddress_LastName; }
  set deliveryAddress_LastName(value) { this.dto.deliveryAddress_LastName = value; }
  /**
  */
  get deliveryAddress_Street() { return this.dto.deliveryAddress_Street; }
  set deliveryAddress_Street(value) { this.dto.deliveryAddress_Street = value; }
  /**
  */
  get deliveryAddress_Number() { return this.dto.deliveryAddress_Number; }
  set deliveryAddress_Number(value) { this.dto.deliveryAddress_Number = value; }
  /**
  */
  get deliveryAddress_POBox() { return this.dto.deliveryAddress_POBox; }
  set deliveryAddress_POBox(value) { this.dto.deliveryAddress_POBox = value; }
  /**
  */
  get deliveryAddress_AdditionalLine() { return this.dto.deliveryAddress_AdditionalLine; }
  set deliveryAddress_AdditionalLine(value) { this.dto.deliveryAddress_AdditionalLine = value; }
  /**
  */
  get deliveryAddress_PostalCode() { return this.dto.deliveryAddress_PostalCode; }
  set deliveryAddress_PostalCode(value) { this.dto.deliveryAddress_PostalCode = value; }
  /**
  */
  get deliveryAddress_City() { return this.dto.deliveryAddress_City; }
  set deliveryAddress_City(value) { this.dto.deliveryAddress_City = value; }
  /**
  */
  get deliveryAddress_CountryIso2() { return this.dto.deliveryAddress_CountryIso2; }
  set deliveryAddress_CountryIso2(value) { this.dto.deliveryAddress_CountryIso2 = value; }
  /**
  */
  get deliveryAddress_Phone() { return this.dto.deliveryAddress_Phone; }
  set deliveryAddress_Phone(value) { this.dto.deliveryAddress_Phone = value; }
  /**
  */
  get deliveryAddress_MobilePhone() { return this.dto.deliveryAddress_MobilePhone; }
  set deliveryAddress_MobilePhone(value) { this.dto.deliveryAddress_MobilePhone = value; }
  /**
  */
  get deliveryAddress_Email() { return this.dto.deliveryAddress_Email; }
  set deliveryAddress_Email(value) { this.dto.deliveryAddress_Email = value; }
  /**
  */
  get language_ISO2() { return this.dto.language_ISO2; }
  set language_ISO2(value) { this.dto.language_ISO2 = value; }
  /**
  * @type {int64}
  */
  get membershipId() { return this.dto.membershipId; }
  set membershipId(value) { this.dto.membershipId = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.userid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.orderNumber = qs.ordernumber;
    this.dto.orderReference = qs.orderreference;
    value = Number.parseFloat(qs.orderstateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.orderStateId = value;
    this.dto.orderDate = qs.orderdate;
    this.dto.currency = qs.currency;
    value = Number.parseFloat(qs.totalgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalGrossPrice = value;
    value = Number.parseFloat(qs.totalnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalNetPrice = value;
    value = Number.parseFloat(qs.totaltaxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalTaxAmount = value;
    value = Number.parseFloat(qs.invoiceaddress_salutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.invoiceAddress_Salutation = value;
    this.dto.invoiceAddress_Company = qs.invoiceaddress_company;
    this.dto.invoiceAddress_FirstName = qs.invoiceaddress_firstname;
    this.dto.invoiceAddress_LastName = qs.invoiceaddress_lastname;
    this.dto.invoiceAddress_Street = qs.invoiceaddress_street;
    this.dto.invoiceAddress_Number = qs.invoiceaddress_number;
    this.dto.invoiceAddress_POBox = qs.invoiceaddress_pobox;
    this.dto.invoiceAddress_AdditionalLine = qs.invoiceaddress_additionalline;
    this.dto.invoiceAddress_PostalCode = qs.invoiceaddress_postalcode;
    this.dto.invoiceAddress_City = qs.invoiceaddress_city;
    this.dto.invoiceAddress_CountryIso2 = qs.invoiceaddress_countryiso2;
    this.dto.invoiceAddress_Phone = qs.invoiceaddress_phone;
    this.dto.invoiceAddress_MobilePhone = qs.invoiceaddress_mobilephone;
    this.dto.invoiceAddress_Email = qs.invoiceaddress_email;
    this.dto.invoiceAddress_CrmContactId = qs.invoiceaddress_crmcontactid;
    value = qs.deliveryAddress_IsDifferent === 'true' ? true : qs.deliveryaddress_isdifferent;
    value = qs.deliveryAddress_IsDifferent === 'false' ? false : undefined;
    this.dto.deliveryAddress_IsDifferent = value;
    value = Number.parseFloat(qs.deliveryaddress_salutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.deliveryAddress_Salutation = value;
    this.dto.deliveryAddress_Company = qs.deliveryaddress_company;
    this.dto.deliveryAddress_FirstName = qs.deliveryaddress_firstname;
    this.dto.deliveryAddress_LastName = qs.deliveryaddress_lastname;
    this.dto.deliveryAddress_Street = qs.deliveryaddress_street;
    this.dto.deliveryAddress_Number = qs.deliveryaddress_number;
    this.dto.deliveryAddress_POBox = qs.deliveryaddress_pobox;
    this.dto.deliveryAddress_AdditionalLine = qs.deliveryaddress_additionalline;
    this.dto.deliveryAddress_PostalCode = qs.deliveryaddress_postalcode;
    this.dto.deliveryAddress_City = qs.deliveryaddress_city;
    this.dto.deliveryAddress_CountryIso2 = qs.deliveryaddress_countryiso2;
    this.dto.deliveryAddress_Phone = qs.deliveryaddress_phone;
    this.dto.deliveryAddress_MobilePhone = qs.deliveryaddress_mobilephone;
    this.dto.deliveryAddress_Email = qs.deliveryaddress_email;
    this.dto.language_ISO2 = qs.language_iso2;
    value = Number.parseFloat(qs.membershipid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.membershipId = value;
  }
}
