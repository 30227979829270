// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import MembershipOpenInvoiceListModel from '../../model/membership-open-invoice-list-model';
import MembershipOpenInvoiceListDTO from '../dto/membership-open-invoice-list-dto';
import dtoSchema from '../schemas/membership-open-invoice-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class MembershipOpenInvoiceListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<MembershipOpenInvoiceListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.subject = data.subject ?? undefined;
      this.businessDivision = data.businessDivision ?? undefined;
      this.invoiceDate = data.invoiceDate ?? undefined;
      this.dueDate = data.dueDate ?? undefined;
      this.currency = data.currency ?? undefined;
      this.amountInvoiced = data.amountInvoiced;
      this.amountOutstanding = data.amountOutstanding;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  subject?: string;
  /**
  */
  businessDivision?: string;
  /**
  * @type {date-time}
  */
  invoiceDate?: string;
  /**
  * @type {date-time}
  */
  dueDate?: string;
  /**
  */
  currency?: string;
  /**
  * @type {double}
  */
  amountInvoiced?: number;
  /**
  * @type {double}
  */
  amountOutstanding?: number;

  static toModel(dto: DeepPartial<MembershipOpenInvoiceListDTO>): MembershipOpenInvoiceListModel;
  static toModel(dto: DeepPartial<MembershipOpenInvoiceListDTO> | undefined | null): MembershipOpenInvoiceListModel | undefined;
  static toModel(dto: DeepPartial<MembershipOpenInvoiceListDTO> | undefined | null): MembershipOpenInvoiceListModel | undefined {
    return dto ? new MembershipOpenInvoiceListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<MembershipOpenInvoiceListModel> | MembershipOpenInvoiceListModel): MembershipOpenInvoiceListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      subject: unwrapped.subject,
      businessDivision: unwrapped.businessDivision,
      invoiceDate: unwrapped.invoiceDate,
      dueDate: unwrapped.dueDate,
      currency: unwrapped.currency,
      amountInvoiced: unwrapped.amountInvoiced,
      amountOutstanding: unwrapped.amountOutstanding,
    } as MembershipOpenInvoiceListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for MembershipOpenInvoiceListModel, can not map to MembershipOpenInvoiceListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
