// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopArticlesListResourceViewModel from '../../../../viewModel/resource/ShopArticlesListResourceViewModel';
import ShopArticlesListResourceDTO from '../../dto/resource/ShopArticlesListResourceDTO';

export default abstract class ShopArticlesListResourceModel extends BaseModel<ShopArticlesListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get articleNumber() { return this.dto.articleNumber; }
  set articleNumber(value) { this.dto.articleNumber = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get shortDescription() { return this.dto.shortDescription; }
  set shortDescription(value) { this.dto.shortDescription = value; }
  /**
  * @type {int64}
  */
  get mainPictureId() { return this.dto.mainPictureId; }
  set mainPictureId(value) { this.dto.mainPictureId = value; }
  /**
  * @type {date-time}
  */
  get validFrom() { return this.dto.validFrom; }
  set validFrom(value) { this.dto.validFrom = value; }
  /**
  * @type {date-time}
  */
  get validTo() { return this.dto.validTo; }
  set validTo(value) { this.dto.validTo = value; }
  /**
  * @type {double}
  */
  get shopArticleTaxId() { return this.dto.shopArticleTaxId; }
  set shopArticleTaxId(value) { this.dto.shopArticleTaxId = value; }
  /**
  */
  get isPublished() { return this.dto.isPublished; }
  set isPublished(value) { this.dto.isPublished = value; }
  /**
  */
  get language_ISO2() { return this.dto.language_ISO2; }
  set language_ISO2(value) { this.dto.language_ISO2 = value; }
  /**
  * @type {double}
  */
  get unitGrossPrice() { return this.dto.unitGrossPrice; }
  set unitGrossPrice(value) { this.dto.unitGrossPrice = value; }
  /**
  * @type {double}
  */
  get unitNetPrice() { return this.dto.unitNetPrice; }
  set unitNetPrice(value) { this.dto.unitNetPrice = value; }
  /**
  * @type {double}
  */
  get taxAmount() { return this.dto.taxAmount; }
  set taxAmount(value) { this.dto.taxAmount = value; }
  /**
  * @type {double}
  */
  get taxRate() { return this.dto.taxRate; }
  set taxRate(value) { this.dto.taxRate = value; }
  /**
  */
  get taxName() { return this.dto.taxName; }
  set taxName(value) { this.dto.taxName = value; }
  /**
  */
  get taxShortName() { return this.dto.taxShortName; }
  set taxShortName(value) { this.dto.taxShortName = value; }
  /**
  */
  get hasLicense() { return this.dto.hasLicense; }
  set hasLicense(value) { this.dto.hasLicense = value; }
  /**
  */
  get isDownload() { return this.dto.isDownload; }
  set isDownload(value) { this.dto.isDownload = value; }
  /**
  * @type {int64}
  */
  get digitalAssetId() { return this.dto.digitalAssetId; }
  set digitalAssetId(value) { this.dto.digitalAssetId = value; }
  /**
  */
  get hasShipping() { return this.dto.hasShipping; }
  set hasShipping(value) { this.dto.hasShipping = value; }
  /**
  * @type {int64}
  */
  get categoryId() { return this.dto.categoryId; }
  set categoryId(value) { this.dto.categoryId = value; }
  /**
  * @type {int32}
  */
  get sortIndex() { return this.dto.sortIndex; }
  set sortIndex(value) { this.dto.sortIndex = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.articleNumber = qs.articlenumber;
    this.dto.caption = qs.caption;
    this.dto.description = qs.description;
    this.dto.shortDescription = qs.shortdescription;
    value = Number.parseFloat(qs.mainpictureid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.mainPictureId = value;
    this.dto.validFrom = qs.validfrom;
    this.dto.validTo = qs.validto;
    value = Number.parseFloat(qs.shoparticletaxid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopArticleTaxId = value;
    value = qs.isPublished === 'true' ? true : qs.ispublished;
    value = qs.isPublished === 'false' ? false : undefined;
    this.dto.isPublished = value;
    this.dto.language_ISO2 = qs.language_iso2;
    value = Number.parseFloat(qs.unitgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitGrossPrice = value;
    value = Number.parseFloat(qs.unitnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitNetPrice = value;
    value = Number.parseFloat(qs.taxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxAmount = value;
    value = Number.parseFloat(qs.taxrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxRate = value;
    this.dto.taxName = qs.taxname;
    this.dto.taxShortName = qs.taxshortname;
    value = qs.hasLicense === 'true' ? true : qs.haslicense;
    value = qs.hasLicense === 'false' ? false : undefined;
    this.dto.hasLicense = value;
    value = qs.isDownload === 'true' ? true : qs.isdownload;
    value = qs.isDownload === 'false' ? false : undefined;
    this.dto.isDownload = value;
    value = Number.parseFloat(qs.digitalassetid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.digitalAssetId = value;
    value = qs.hasShipping === 'true' ? true : qs.hasshipping;
    value = qs.hasShipping === 'false' ? false : undefined;
    this.dto.hasShipping = value;
    value = Number.parseFloat(qs.categoryid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.categoryId = value;
    value = Number.parseFloat(qs.sortindex);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sortIndex = value;
  }
}
