// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SubscriptionArticleModel from '../../model/subscription-article-model';
import SubscriptionArticleDTO from '../dto/subscription-article-dto';
import dtoSchema from '../schemas/subscription-article-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class SubscriptionArticleModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SubscriptionArticleDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.subscriptionTemplateNumber = data.subscriptionTemplateNumber;
      this.itemId = data.itemId;
      this.itemNumber = data.itemNumber;
      this.itemPriceNumber = data.itemPriceNumber;
      this.itemPriceType = data.itemPriceType;
      this.name = data.name ?? undefined;
      this.currency = data.currency ?? undefined;
      this.unitPrice = data.unitPrice;
      this.vatRate = data.vatRate;
      this.durationInMonths = data.durationInMonths ?? undefined;
      this.durationInWeeks = data.durationInWeeks ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int32}
  */
  subscriptionTemplateNumber?: number;
  /**
  * @type {uuid}
  */
  itemId?: string;
  /**
  * @type {int32}
  */
  itemNumber?: number;
  /**
  * @type {int32}
  */
  itemPriceNumber?: number;
  /**
  * @type {int32}
  */
  itemPriceType?: number;
  /**
  */
  name?: string;
  /**
  */
  currency?: string;
  /**
  * @type {double}
  */
  unitPrice?: number;
  /**
  * @type {double}
  */
  vatRate?: number;
  /**
  * @type {int32}
  */
  durationInMonths?: number;
  /**
  * @type {int32}
  */
  durationInWeeks?: number;

  static toModel(dto: DeepPartial<SubscriptionArticleDTO>): SubscriptionArticleModel;
  static toModel(dto: DeepPartial<SubscriptionArticleDTO> | undefined | null): SubscriptionArticleModel | undefined;
  static toModel(dto: DeepPartial<SubscriptionArticleDTO> | undefined | null): SubscriptionArticleModel | undefined {
    return dto ? new SubscriptionArticleModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SubscriptionArticleModel> | SubscriptionArticleModel): SubscriptionArticleDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      subscriptionTemplateNumber: unwrapped.subscriptionTemplateNumber,
      itemId: unwrapped.itemId,
      itemNumber: unwrapped.itemNumber,
      itemPriceNumber: unwrapped.itemPriceNumber,
      itemPriceType: unwrapped.itemPriceType,
      name: unwrapped.name,
      currency: unwrapped.currency,
      unitPrice: unwrapped.unitPrice,
      vatRate: unwrapped.vatRate,
      durationInMonths: unwrapped.durationInMonths,
      durationInWeeks: unwrapped.durationInWeeks,
    } as SubscriptionArticleDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SubscriptionArticleModel, can not map to SubscriptionArticleDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
