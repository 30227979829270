<template>
  <div class="gs-shop-category-item">
    TODO: Shop category item
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsShopCategoryItem',
    props: {

    },
    data: () => ({

    }),
  });
</script>
