// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import CreateDynamicFormFieldRequestViewModel from '../../../../viewModel/resource/CreateDynamicFormFieldRequestViewModel';
import CreateDynamicFormRequestViewModel from '../../../../viewModel/resource/CreateDynamicFormRequestViewModel';
import CreateDynamicFormRequestDTO from '../../dto/resource/CreateDynamicFormRequestDTO';

export default abstract class CreateDynamicFormRequestModel extends BaseModel<CreateDynamicFormRequestDTO> {
  /**
  * @type {uuid}
  */
  get dynamicFormDefinitionId() { return this.dto.dynamicFormDefinitionId; }
  set dynamicFormDefinitionId(value) { this.dto.dynamicFormDefinitionId = value; }
  /**
  */
  get languageIso() { return this.dto.languageIso; }
  set languageIso(value) { this.dto.languageIso = value; }
  /**
  * @type {uuid}
  */
  get contactId() { return this.dto.contactId; }
  set contactId(value) { this.dto.contactId = value; }
  /**
  */
  get contact() { return this.getObjectModel(() => this.dto.contact, ContactResourceViewModel)!; }
  set contact(value) { this.setObjectModel(() => this.dto.contact, value); }
  /**
  */
  get formFields() { return this.getArrayModels(() => this.dto.formFields, CreateDynamicFormFieldRequestViewModel)!; }
  set formFields(value) { this.setArrayModels(() => this.dto.formFields, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.dynamicFormDefinitionId = qs.dynamicformdefinitionid;
    this.dto.languageIso = qs.languageiso;
    this.dto.contactId = qs.contactid;
  }
}
