// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSuppliersLookupRequestModel from '../../model/get-suppliers-lookup-request-model';
import GetShopSuppliersRequestModel from '../../model/get-shop-suppliers-request-model';
import CreateShopSupplierRequestModel from '../../model/create-shop-supplier-request-model';
import GetShopSupplierByIdRequestModel from '../../model/get-shop-supplier-by-id-request-model';
import UpdateShopSupplierRequestModel from '../../model/update-shop-supplier-request-model';
import DeleteShopSupplierByIdRequestModel from '../../model/delete-shop-supplier-by-id-request-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';
import ShopSupplierListResultModel from '../../model/shop-supplier-list-result-model';
import ShopSupplierModel from '../../model/shop-supplier-model';

export default (service: Servicelayer) => ({
  /**  */
  async getSuppliersLookup(request: GetSuppliersLookupRequestModel, config?: RequestConfig) {
    const requestDTO = GetSuppliersLookupRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-suppliers-lookup'] ?? 'ShopSuppliers/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getShopSuppliers(request: GetShopSuppliersRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopSuppliersRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-suppliers'] ?? 'ShopSuppliers';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopSupplierListResultModel.toModel(dto));
  },
  /**  */
  async createShopSupplier(request: CreateShopSupplierRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopSupplierRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-supplier'] ?? 'ShopSuppliers';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        name: requestDTO.name,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopSupplierById(request: GetShopSupplierByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopSupplierByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-supplier-by-id'] ?? 'ShopSuppliers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopSupplierModel.toModel(dto));
  },
  /**  */
  async updateShopSupplier(request: UpdateShopSupplierRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopSupplierRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-supplier'] ?? 'ShopSuppliers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        name: requestDTO.name,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopSupplierById(request: DeleteShopSupplierByIdRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopSupplierByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-supplier-by-id'] ?? 'ShopSuppliers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
