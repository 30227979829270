<template>
  <p-app theme="glittr">
    <template #header>
      <p-toolbar color="app-bg" dense flat>
        <p-row justify-end>
          <span class="language-selection-menu-wrapper">
            <gs-language-selection></gs-language-selection>
          </span>
          <span class="pwa-add-button-wrapper">
            <p-button :style="{visibility: isPwaButtonVisible ? 'visible' : 'hidden'}" class="pwa-add-button" dense :title="$t('app.installPWAApp')">
              <p-icon dense>install_desktop</p-icon>
            </p-button>
          </span>
          <span>
            <p-button dense @click="$auth.redirectToLogin()">
              Login
            </p-button>
          </span>
        </p-row>
      </p-toolbar>
      <p-toolbar color="surface-2" flat>
        <p-col shrink>
          <div
            tabindex="0"
            class="pointer"
            aria-label="Logo"
            @keyup.enter="$auth.redirectToLogin()"
            @keyup.space="$auth.redirectToLogin()"
            @click="$auth.redirectToLogin()"
          >
            <p-img
              class="header-logo"
              width="260"
              height="40px"
              alt="Logo"
              contain
              :src="require('@src/assets/logo.svg')"
            ></p-img>
          </div>
        </p-col>
      </p-toolbar>
    </template>
    <div slot="default" class="fill-height">
      <slot class="content" />
    </div>
  </p-app>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    data: () => ({
      theme: 'glittr-pixel-light',
    }),
    computed: {
      isPwaButtonVisible(): boolean {
        return !!Vue.$store.values.showPwaInstallPromptFunction;
      },
    },
    async mounted() {
      // PWA
      const addBtn = document.querySelector('.pwa-add-button') as HTMLElement;
      if (addBtn && Vue.$store.values.showPwaInstallPromptFunction) {
        addBtn.addEventListener('click', Vue.$store.values.showPwaInstallPromptFunction);
      }
    },
  });
</script>
