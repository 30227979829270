// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopArticleTaxHistoryModel from '../../model/shop-article-tax-history-model';
import ShopArticleTaxHistoryDTO from '../dto/shop-article-tax-history-dto';
import dtoSchema from '../schemas/shop-article-tax-history-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ShopArticleTaxHistoryModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopArticleTaxHistoryDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.shopArticleTaxId = data.shopArticleTaxId;
      this.taxRate = data.taxRate;
      this.validFrom = data.validFrom;
      this.validTo = data.validTo ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  shopArticleTaxId?: number;
  /**
  * @type {double}
  */
  taxRate?: number;
  /**
  * @type {date-time}
  */
  validFrom?: string;
  /**
  * @type {date-time}
  */
  validTo?: string;

  static toModel(dto: DeepPartial<ShopArticleTaxHistoryDTO>): ShopArticleTaxHistoryModel;
  static toModel(dto: DeepPartial<ShopArticleTaxHistoryDTO> | undefined | null): ShopArticleTaxHistoryModel | undefined;
  static toModel(dto: DeepPartial<ShopArticleTaxHistoryDTO> | undefined | null): ShopArticleTaxHistoryModel | undefined {
    return dto ? new ShopArticleTaxHistoryModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopArticleTaxHistoryModel> | ShopArticleTaxHistoryModel): ShopArticleTaxHistoryDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      shopArticleTaxId: unwrapped.shopArticleTaxId,
      taxRate: unwrapped.taxRate,
      validFrom: unwrapped.validFrom,
      validTo: unwrapped.validTo,
    } as ShopArticleTaxHistoryDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopArticleTaxHistoryModel, can not map to ShopArticleTaxHistoryDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
