// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateDynamicFormRequestModel from '../../model/create-dynamic-form-request-model';
import CreateDynamicFormRequestDTO from '../dto/create-dynamic-form-request-dto';
import dtoSchema from '../schemas/create-dynamic-form-request-schema';
import ContactModel from '../../model/contact-model';
import CreateDynamicFormFieldRequestModel from '../../model/create-dynamic-form-field-request-model';

export default abstract class CreateDynamicFormRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateDynamicFormRequestDTO>) {
    super();
    if (data) {
      this.dynamicFormDefinitionId = data.dynamicFormDefinitionId;
      this.languageIso = data.languageIso ?? undefined;
      this.contactId = data.contactId ?? undefined;
      this.contact = data.contact ? ContactModel.toModel(data.contact) : undefined;
      this.formFields = data.formFields?.map((i) => CreateDynamicFormFieldRequestModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {uuid}
  */
  dynamicFormDefinitionId?: string;
  /**
  */
  languageIso?: string;
  /**
  * @type {uuid}
  */
  contactId?: string;
  /**
  */
  contact?: ContactModel;
  /**
  */
  formFields?: CreateDynamicFormFieldRequestModel[];

  static toModel(dto: DeepPartial<CreateDynamicFormRequestDTO>): CreateDynamicFormRequestModel;
  static toModel(dto: DeepPartial<CreateDynamicFormRequestDTO> | undefined | null): CreateDynamicFormRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateDynamicFormRequestDTO> | undefined | null): CreateDynamicFormRequestModel | undefined {
    return dto ? new CreateDynamicFormRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateDynamicFormRequestModel> | CreateDynamicFormRequestModel): CreateDynamicFormRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      dynamicFormDefinitionId: unwrapped.dynamicFormDefinitionId,
      languageIso: unwrapped.languageIso,
      contactId: unwrapped.contactId,
      contact: unwrapped.contact,
      formFields: unwrapped.formFields,
    } as CreateDynamicFormRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateDynamicFormRequestModel, can not map to CreateDynamicFormRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
