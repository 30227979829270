// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import BusinessUnitResourceViewModel from '../../../../viewModel/resource/BusinessUnitResourceViewModel';
import BusinessUnitResourceDTO from '../../dto/resource/BusinessUnitResourceDTO';

export default abstract class BusinessUnitResourceModel extends BaseModel<BusinessUnitResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int32}
  */
  get number() { return this.dto.number; }
  set number(value) { this.dto.number = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get unitName() { return this.dto.unitName; }
  set unitName(value) { this.dto.unitName = value; }
  /**
  */
  get isMembershipAdditionalInfoRequired() { return this.dto.isMembershipAdditionalInfoRequired; }
  set isMembershipAdditionalInfoRequired(value) { this.dto.isMembershipAdditionalInfoRequired = value; }
  /**
  */
  get isMembershipOnlinePaymentAllowed() { return this.dto.isMembershipOnlinePaymentAllowed; }
  set isMembershipOnlinePaymentAllowed(value) { this.dto.isMembershipOnlinePaymentAllowed = value; }
  /**
  */
  get isMembershipDirectRegistration() { return this.dto.isMembershipDirectRegistration; }
  set isMembershipDirectRegistration(value) { this.dto.isMembershipDirectRegistration = value; }
  /**
  */
  get eMailAddress() { return this.dto.eMailAddress; }
  set eMailAddress(value) { this.dto.eMailAddress = value; }
  /**
  */
  get stateCode() { return this.dto.stateCode; }
  set stateCode(value) { this.dto.stateCode = value; }
  /**
  */
  get phoneNumber() { return this.dto.phoneNumber; }
  set phoneNumber(value) { this.dto.phoneNumber = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    value = Number.parseFloat(qs.number);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.number = value;
    this.dto.name = qs.name;
    this.dto.unitName = qs.unitname;
    value = qs.isMembershipAdditionalInfoRequired === 'true' ? true : qs.ismembershipadditionalinforequired;
    value = qs.isMembershipAdditionalInfoRequired === 'false' ? false : undefined;
    this.dto.isMembershipAdditionalInfoRequired = value;
    value = qs.isMembershipOnlinePaymentAllowed === 'true' ? true : qs.ismembershiponlinepaymentallowed;
    value = qs.isMembershipOnlinePaymentAllowed === 'false' ? false : undefined;
    this.dto.isMembershipOnlinePaymentAllowed = value;
    value = qs.isMembershipDirectRegistration === 'true' ? true : qs.ismembershipdirectregistration;
    value = qs.isMembershipDirectRegistration === 'false' ? false : undefined;
    this.dto.isMembershipDirectRegistration = value;
    this.dto.eMailAddress = qs.emailaddress;
    this.dto.stateCode = qs.statecode;
    this.dto.phoneNumber = qs.phonenumber;
  }
}
