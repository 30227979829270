import ShopCategoriesListResourceModel from '../../generated/types/model/resource/ShopCategoriesListResourceModel';

export default class ShopCategoriesListResourceViewModel extends ShopCategoriesListResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  depth: number = 0;
  // eslint-disable-next-line no-use-before-define
  parent?: ShopCategoriesListResourceViewModel;
  // eslint-disable-next-line no-use-before-define
  children: ShopCategoriesListResourceViewModel[] = [];
}
