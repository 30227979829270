// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ESuranceGastronomyInfoSchema from './e-surance-gastronomy-info-schema';
import AccommodationSchema from './accommodation-schema';

const schema = {
  $id: '/e-surance-membership-business-schema',
  type: 'object',
  required: ['accessionDate', 'name', 'street', 'houseNumber', 'zipCode', 'city', 'hasApprenticeTraining', 'isSeasonalBusiness', 'gastronomyInfo', 'accommodation'],
  properties: {
    accessionDate: {
      type: 'string',
      format: 'date-time',
    },
    name: {
      type: 'string',
      minLength: 1,
    },
    street: {
      type: 'string',
      minLength: 1,
    },
    houseNumber: {
      type: 'string',
      minLength: 1,
    },
    zipCode: {
      type: 'string',
      minLength: 1,
    },
    city: {
      type: 'string',
      minLength: 1,
    },
    hasApprenticeTraining: {
      type: 'boolean',
    },
    isSeasonalBusiness: {
      type: 'boolean',
    },
    gastronomyInfo: {
      ...ESuranceGastronomyInfoSchema,
    },
    accommodation: {
      ...AccommodationSchema,
    },
  },
} as IJSONSchema;

export default schema;
