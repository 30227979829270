// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetIssuuDocumentsRequestModel from '../../model/get-issuu-documents-request-model';
import GetIssuuDocumentsRequestDTO from '../dto/get-issuu-documents-request-dto';
import dtoSchema from '../schemas/get-issuu-documents-request-schema';

export default abstract class GetIssuuDocumentsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetIssuuDocumentsRequestDTO>) {
    super();
    if (data) {
      this.pageSize = data.pageSize;
      this.startIndex = data.startIndex;
    }
  }
  /**
  * @type {int32}
  */
  pageSize?: number;
  /**
  * @type {int32}
  */
  startIndex?: number;

  static toModel(dto: DeepPartial<GetIssuuDocumentsRequestDTO>): GetIssuuDocumentsRequestModel;
  static toModel(dto: DeepPartial<GetIssuuDocumentsRequestDTO> | undefined | null): GetIssuuDocumentsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetIssuuDocumentsRequestDTO> | undefined | null): GetIssuuDocumentsRequestModel | undefined {
    return dto ? new GetIssuuDocumentsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetIssuuDocumentsRequestModel> | GetIssuuDocumentsRequestModel): GetIssuuDocumentsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      pageSize: unwrapped.pageSize,
      startIndex: unwrapped.startIndex,
    } as GetIssuuDocumentsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetIssuuDocumentsRequestModel, can not map to GetIssuuDocumentsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
