// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import IssuuLookupResourceViewModel from '../../../../viewModel/resource/IssuuLookupResourceViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import IssuuLookupResourceListResultViewModel from '../../../../viewModel/resource/IssuuLookupResourceListResultViewModel';
import IssuuLookupResourceListResultDTO from '../../dto/resource/IssuuLookupResourceListResultDTO';

export default abstract class IssuuLookupResourceListResultModel extends BaseModel<IssuuLookupResourceListResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, IssuuLookupResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
