import BasketResourceModel from '../../generated/types/model/resource/BasketResourceModel';

export default class BasketResourceViewModel extends BasketResourceModel {
  protected transformToDTO() {
    this.dto.grossTotal = this.grossTotal;
  }
  protected transformFromDTO() {}

  get isOnlinePayment() {
    return this.paymentMethod === 2
        || this.paymentMethod === 3
        || this.paymentMethod === 6
        || this.paymentMethod === 7;
  }

  get grossTotal() {
    let total = 0;
    this.items.forEach((item) => {
      total += item.grossPrice;
    });
    return total;
  }
}
