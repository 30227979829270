// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ShopArticleTaxHistorySchema from './shop-article-tax-history-schema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/shop-article-tax-history-list-result-schema',
  type: 'object',
  required: [],
  properties: {
    items: {
      type: 'array',
      nullable: true,
      items: { ...ShopArticleTaxHistorySchema },
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
  },
} as IJSONSchema;

export default schema;
