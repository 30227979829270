// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import HotelClassificationBusinessListResourceViewModel from '../../../../viewModel/resource/HotelClassificationBusinessListResourceViewModel';
import HotelClassificationBusinessListResourceDTO from '../../dto/resource/HotelClassificationBusinessListResourceDTO';

export default abstract class HotelClassificationBusinessListResourceModel extends BaseModel<HotelClassificationBusinessListResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get businessName() { return this.dto.businessName; }
  set businessName(value) { this.dto.businessName = value; }
  /**
  * @type {date-time}
  */
  get classificationExpirationDate() { return this.dto.classificationExpirationDate; }
  set classificationExpirationDate(value) { this.dto.classificationExpirationDate = value; }
  /**
  */
  get classificationStars() { return this.dto.classificationStars; }
  set classificationStars(value) { this.dto.classificationStars = value; }
  /**
  */
  get classificationComment() { return this.dto.classificationComment; }
  set classificationComment(value) { this.dto.classificationComment = value; }
  /**
  */
  get isClassified() { return this.dto.isClassified; }
  set isClassified(value) { this.dto.isClassified = value; }
  /**
  */
  get canRequestClassification() { return this.dto.canRequestClassification; }
  set canRequestClassification(value) { this.dto.canRequestClassification = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.businessName = qs.businessname;
    this.dto.classificationExpirationDate = qs.classificationexpirationdate;
    this.dto.classificationStars = qs.classificationstars;
    this.dto.classificationComment = qs.classificationcomment;
    value = qs.isClassified === 'true' ? true : qs.isclassified;
    value = qs.isClassified === 'false' ? false : undefined;
    this.dto.isClassified = value;
    value = qs.canRequestClassification === 'true' ? true : qs.canrequestclassification;
    value = qs.canRequestClassification === 'false' ? false : undefined;
    this.dto.canRequestClassification = value;
  }
}
