// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetOrderRequestModel from '../../model/get-order-request-model';
import GetOrderRequestDTO from '../dto/get-order-request-dto';
import dtoSchema from '../schemas/get-order-request-schema';

export default abstract class GetOrderRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetOrderRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
    }
  }
  /**
  * @type {int64}
  */
  userId?: number;

  static toModel(dto: DeepPartial<GetOrderRequestDTO>): GetOrderRequestModel;
  static toModel(dto: DeepPartial<GetOrderRequestDTO> | undefined | null): GetOrderRequestModel | undefined;
  static toModel(dto: DeepPartial<GetOrderRequestDTO> | undefined | null): GetOrderRequestModel | undefined {
    return dto ? new GetOrderRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetOrderRequestModel> | GetOrderRequestModel): GetOrderRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
    } as GetOrderRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetOrderRequestModel, can not map to GetOrderRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
