// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/e-surance-gastronomy-info-schema',
  type: 'object',
  required: ['hasGastronomy', 'gastronomyTypeId', 'gastronomyTypeName', 'countOfSeatsIndoor', 'countOfSeatsOutdoor', 'countOfSeatsHall', 'isWheelchairAccessible', 'isHandicappedRestroomAvailable', 'hasSmokingRoom'],
  properties: {
    hasGastronomy: {
      type: 'boolean',
    },
    gastronomyTypeId: {
      type: 'string',
      format: 'uuid',
    },
    gastronomyTypeName: {
      type: 'string',
      minLength: 1,
    },
    countOfSeatsIndoor: {
      type: 'integer',
    },
    countOfSeatsOutdoor: {
      type: 'integer',
    },
    countOfSeatsHall: {
      type: 'integer',
    },
    isWheelchairAccessible: {
      type: 'boolean',
    },
    isHandicappedRestroomAvailable: {
      type: 'boolean',
    },
    hasSmokingRoom: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
