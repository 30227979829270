// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import SubscriptionTypeResourceViewModel from '../../../../viewModel/resource/SubscriptionTypeResourceViewModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import DeliveryAddressResourceViewModel from '../../../../viewModel/resource/DeliveryAddressResourceViewModel';
import BasketResourceViewModel from '../../../../viewModel/resource/BasketResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import CreateSubscriptionContractRequestViewModel from '../../../../viewModel/resource/CreateSubscriptionContractRequestViewModel';
import CreateSubscriptionContractRequestDTO from '../../dto/resource/CreateSubscriptionContractRequestDTO';

export default abstract class CreateSubscriptionContractRequestModel extends BaseModel<CreateSubscriptionContractRequestDTO> {
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  * @type {int32}
  */
  get subscriptionType() { return this.dto.subscriptionType; }
  set subscriptionType(value) { this.dto.subscriptionType = value; }
  /**
  * @type {int32}
  */
  get subscriptionTemplateNumber() { return this.dto.subscriptionTemplateNumber; }
  set subscriptionTemplateNumber(value) { this.dto.subscriptionTemplateNumber = value; }
  /**
  * @type {date-time}
  */
  get entryDate() { return this.dto.entryDate; }
  set entryDate(value) { this.dto.entryDate = value; }
  /**
  */
  get isBillingContactReadonly() { return this.dto.isBillingContactReadonly; }
  set isBillingContactReadonly(value) { this.dto.isBillingContactReadonly = value; }
  /**
  */
  get billingContact() { return this.getObjectModel(() => this.dto.billingContact, ContactResourceViewModel)!; }
  set billingContact(value) { this.setObjectModel(() => this.dto.billingContact, value); }
  /**
  */
  get isDeliveryContactReadonly() { return this.dto.isDeliveryContactReadonly; }
  set isDeliveryContactReadonly(value) { this.dto.isDeliveryContactReadonly = value; }
  /**
  */
  get deliveryContact() { return this.getObjectModel(() => this.dto.deliveryContact, DeliveryAddressResourceViewModel)!; }
  set deliveryContact(value) { this.setObjectModel(() => this.dto.deliveryContact, value); }
  /**
  */
  get orderBasket() { return this.getObjectModel(() => this.dto.orderBasket, BasketResourceViewModel)!; }
  set orderBasket(value) { this.setObjectModel(() => this.dto.orderBasket, value); }
  /**
  */
  get summary() { return this.getObjectModel(() => this.dto.summary, RequestSummaryResourceViewModel)!; }
  set summary(value) { this.setObjectModel(() => this.dto.summary, value); }
  /**
  */
  get newsletter() { return this.dto.newsletter; }
  set newsletter(value) { this.dto.newsletter = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }
  /**
  */
  get acceptAgb() { return this.dto.acceptAgb; }
  set acceptAgb(value) { this.dto.acceptAgb = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.userid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    value = Number.parseFloat(qs.subscriptiontype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.subscriptionType = value;
    value = Number.parseFloat(qs.subscriptiontemplatenumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.subscriptionTemplateNumber = value;
    this.dto.entryDate = qs.entrydate;
    value = qs.isBillingContactReadonly === 'true' ? true : qs.isbillingcontactreadonly;
    value = qs.isBillingContactReadonly === 'false' ? false : undefined;
    this.dto.isBillingContactReadonly = value;
    value = qs.isDeliveryContactReadonly === 'true' ? true : qs.isdeliverycontactreadonly;
    value = qs.isDeliveryContactReadonly === 'false' ? false : undefined;
    this.dto.isDeliveryContactReadonly = value;
    value = qs.newsletter === 'true' ? true : qs.newsletter;
    value = qs.newsletter === 'false' ? false : undefined;
    this.dto.newsletter = value;
    this.dto.languageIso2 = qs.languageiso2;
    value = qs.acceptGdpr === 'true' ? true : qs.acceptgdpr;
    value = qs.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
    value = qs.acceptAgb === 'true' ? true : qs.acceptagb;
    value = qs.acceptAgb === 'false' ? false : undefined;
    this.dto.acceptAgb = value;
  }
}
