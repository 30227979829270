import SubscriptionTypeResourceModel from '../../generated/types/model/resource/SubscriptionTypeResourceModel';

// type SubscriptionTypeResourceViewModel = SubscriptionTypeResourceModel;
// export default SubscriptionTypeResourceViewModel;

// eslint-disable-next-line no-shadow
enum SubscriptionTypeResourceViewModel {
  GastroProfessional = 1,
  GastroJournal = 2
}
export default SubscriptionTypeResourceViewModel;
