<template>
  <div class="gs-shop-category-entry">
    <template v-if="value.children.length > 0">
      <p-list-group v-model="selected" :class="'category-list-entry depth-' + value.depth" @click="onCategoryClick(value)">
        <template #activator>
          <p-button v-if="isAdmin" class="mr-2" secondary dense @click="gotoEditCategory(value.id)">
            <p-icon dense>
              edit
            </p-icon>
          </p-button> {{ value.caption }}
        </template>
        <GsShopCategoryEntry
          v-for="child in value.children"
          :key="child.caption"
          :value="child"
          @click="onCategoryClick($event)"
        ></GsShopCategoryEntry>
      </p-list-group>
    </template>
    <template v-else>
      <p-list-entry :class="'category-list-entry depth-' + value.depth" @click="onCategoryClick(value)">
        <p-button v-if="isAdmin" class="mr-2" secondary dense @click="gotoEditCategory(value.id)">
          <p-icon dense>
            edit
          </p-icon>
        </p-button> {{ value.caption }}
      </p-list-entry>
    </template>
  </div>
</template>

<script lang="ts">
  import ShopCategoriesListResourceViewModel from '@/src/services/v1/viewModel/resource/ShopCategoriesListResourceViewModel';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'GsShopCategoryEntry',
    props: {
      value: { type: Object as PropType<ShopCategoriesListResourceViewModel>, default: () => new ShopCategoriesListResourceViewModel() },
    },
    data: () => ({
      selected: undefined as any,
    }),
    computed: {
      isAdmin(): boolean {
        return this.$auth.isInRole('Admin', 'HostAdmin');
      },
    },
    methods: {
      gotoEditCategory(id: number | string | undefined | null) {
        this.$router.push(`/gs/shop/categories/${id}/edit`, () => {});
      },
      onCategoryClick(category: any) {
        this.$emit('click', category);
      },
    },
  });
</script>
