// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopInvoiceAddressModel from '../../model/shop-invoice-address-model';
import ShopInvoiceAddressDTO from '../dto/shop-invoice-address-dto';
import dtoSchema from '../schemas/shop-invoice-address-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ShopInvoiceAddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopInvoiceAddressDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.salutation = data.salutation;
      this.company = data.company ?? undefined;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.street = data.street;
      this.houseNumber = data.houseNumber ?? undefined;
      this.poBox = data.poBox ?? undefined;
      this.additionalLine = data.additionalLine ?? undefined;
      this.postalCode = data.postalCode;
      this.city = data.city;
      this.countryIso2 = data.countryIso2;
      this.phone = data.phone ?? undefined;
      this.mobilePhone = data.mobilePhone ?? undefined;
      this.email = data.email;
      this.isReadonly = data.isReadonly;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int32}
  */
  salutation?: 0 | 1; // ShopSalutationTypeDTO
  /**
  */
  company?: string;
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  * @minimum 1
  */
  street?: string;
  /**
  */
  houseNumber?: string;
  /**
  */
  poBox?: string;
  /**
  */
  additionalLine?: string;
  /**
  * @minimum 1
  */
  postalCode?: string;
  /**
  * @minimum 1
  */
  city?: string;
  /**
  * @minimum 1
  */
  countryIso2?: string;
  /**
  */
  phone?: string;
  /**
  */
  mobilePhone?: string;
  /**
  * @minimum 1
  */
  email?: string;
  /**
  */
  isReadonly?: boolean;

  static toModel(dto: DeepPartial<ShopInvoiceAddressDTO>): ShopInvoiceAddressModel;
  static toModel(dto: DeepPartial<ShopInvoiceAddressDTO> | undefined | null): ShopInvoiceAddressModel | undefined;
  static toModel(dto: DeepPartial<ShopInvoiceAddressDTO> | undefined | null): ShopInvoiceAddressModel | undefined {
    return dto ? new ShopInvoiceAddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopInvoiceAddressModel> | ShopInvoiceAddressModel): ShopInvoiceAddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      salutation: unwrapped.salutation,
      company: unwrapped.company,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      poBox: unwrapped.poBox,
      additionalLine: unwrapped.additionalLine,
      postalCode: unwrapped.postalCode,
      city: unwrapped.city,
      countryIso2: unwrapped.countryIso2,
      phone: unwrapped.phone,
      mobilePhone: unwrapped.mobilePhone,
      email: unwrapped.email,
      isReadonly: unwrapped.isReadonly,
    } as ShopInvoiceAddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopInvoiceAddressModel, can not map to ShopInvoiceAddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
