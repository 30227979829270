import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import UsersApi from '../generated/api/Users';
import UserRegisterRequestViewModel from '../viewModel/resource/UserRegisterRequestViewModel';

export default (service: Servicelayer) => ({
  ...UsersApi(service),

  async getMyUserContact() {
    try {
      const user = await Vue.$auth.getUser();
      return await this.getUserContact(user?.id!);
    } catch (error: any) {
      if (error && error.status !== 404) {
        throw error;
      }
    }
    return undefined;
  },

  // Add custom calls here
  async register(model: UserRegisterRequestViewModel) {
    model.email = model.userName;
    const dto = model.getDTO();
    dto.userLanguage = Vue.$translation.get() ?? 'de';
    dto.userCulture = Vue.$format.userCulture ?? 'de-CH';
    const response = await service.post<ServiceResponse<number>>('core/Users/Register', { body: dto, writeValuesToConsole: false });
    Vue.$auth.refresh();
    return response.data.result as number;
  },
});
