// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import AccommodationSchema from './accommodation-schema';
import GastronomySchema from './gastronomy-schema';

const schema = {
  $id: '/membership-business-schema',
  type: 'object',
  required: [],
  properties: {
    isCompany: {
      type: 'boolean',
    },
    isCompanyRegistered: {
      type: 'boolean',
      nullable: true,
    },
    companyRegisterNr: {
      type: 'string',
      nullable: true,
    },
    companyLegalFormId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    companyName: {
      type: 'string',
      nullable: true,
    },
    salutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    firstName: {
      type: 'string',
      nullable: true,
    },
    lastName: {
      type: 'string',
      nullable: true,
    },
    dateOfBirth: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    phoneNumber: {
      type: 'string',
      nullable: true,
    },
    mobilePhoneNumber: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      nullable: true,
    },
    houseNumber: {
      type: 'string',
      nullable: true,
    },
    addressLine1: {
      type: 'string',
      nullable: true,
    },
    poBox: {
      type: 'string',
      nullable: true,
    },
    postalCode: {
      type: 'string',
      nullable: true,
    },
    postalCodeAddition: {
      type: 'string',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    countryIso2: {
      type: 'string',
      nullable: true,
    },
    languageIso2: {
      type: 'string',
      nullable: true,
    },
    website: {
      type: 'string',
      nullable: true,
    },
    id: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    startDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    name: {
      type: 'string',
      nullable: true,
    },
    isActive: {
      type: 'boolean',
    },
    isTeachingApprentices: {
      type: 'boolean',
      nullable: true,
    },
    isSeasonal: {
      type: 'boolean',
      nullable: true,
    },
    accommodation: {
      ...AccommodationSchema,
    },
    gastronomy: {
      ...GastronomySchema,
    },
  },
} as IJSONSchema;

export default schema;
