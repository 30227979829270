// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetMembershipStatusRequestModel from '../../model/get-membership-status-request-model';
import CalculateMembershipRequestModel from '../../model/calculate-membership-request-model';
import CalculateMembershipByEntryDateRequestModel from '../../model/calculate-membership-by-entry-date-request-model';
import LinkMembershipRequestModel from '../../model/link-membership-request-model';
import GetInitialMembershipRequestRequestModel from '../../model/get-initial-membership-request-request-model';
import CreateMembershipRequestModel from '../../model/create-membership-request-model';
import GetMembershipDetailRequestModel from '../../model/get-membership-detail-request-model';
import GetBusinessesRequestModel from '../../model/get-businesses-request-model';
import GetBusinessDetailRequestModel from '../../model/get-business-detail-request-model';
import GetMembershipDocumentsRequestModel from '../../model/get-membership-documents-request-model';
import GetMembershipOpenInvoicesRequestModel from '../../model/get-membership-open-invoices-request-model';
import MembershipStatusModel from '../../model/membership-status-model';
import BasketModel from '../../model/basket-model';
import MembershipModel from '../../model/membership-model';
import MembershipBusinessListPagingResultModel from '../../model/membership-business-list-paging-result-model';
import MembershipBusinessModel from '../../model/membership-business-model';
import MembershipDocumentListPagingResultModel from '../../model/membership-document-list-paging-result-model';
import MembershipOpenInvoiceListPagingResultModel from '../../model/membership-open-invoice-list-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getMembershipStatus(request: GetMembershipStatusRequestModel, config?: RequestConfig) {
    const requestDTO = GetMembershipStatusRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-status'] ?? 'Memberships/Status/{membershipKey}';
    endpointPath = endpointPath.replace(/\{membershipKey\}/g, `${requestDTO.membershipKey}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipStatusModel.toModel(dto));
  },
  /**  */
  async calculateMembership(request: CalculateMembershipRequestModel, config?: RequestConfig) {
    const requestDTO = CalculateMembershipRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-calculate-membership'] ?? 'Memberships/Calculate/BusinessUnit/{businessUnitId}/Category/{categoryId}';
    endpointPath = endpointPath.replace(/\{businessUnitId\}/g, `${requestDTO.businessUnitId}`);
    endpointPath = endpointPath.replace(/\{categoryId\}/g, `${requestDTO.categoryId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        isMembershipReEntry: requestDTO.isMembershipReEntry,
        postalCode: requestDTO.postalCode,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(BasketModel.toModel(dto));
  },
  /**  */
  async calculateMembershipByEntryDate(request: CalculateMembershipByEntryDateRequestModel, config?: RequestConfig) {
    const requestDTO = CalculateMembershipByEntryDateRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-calculate-membership-by-entry-date'] ?? 'Memberships/CalculateBasket/{membershipEntryDate}';
    endpointPath = endpointPath.replace(/\{membershipEntryDate\}/g, `${requestDTO.membershipEntryDate}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        businessUnitNumber: requestDTO.businessUnitNumber,
        businessPostalCode: requestDTO.businessPostalCode,
        membershipCategoryId: requestDTO.membershipCategoryId,
        membershipTemplateId: requestDTO.membershipTemplateId,
        grossTotal: requestDTO.grossTotal,
        currency: requestDTO.currency,
        items: requestDTO.items,
        periodStart: requestDTO.periodStart,
        periodEnd: requestDTO.periodEnd,
        paymentMethod: requestDTO.paymentMethod,
        onlinePayment: requestDTO.onlinePayment,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(BasketModel.toModel(dto));
  },
  /**  */
  async linkMembership(request: LinkMembershipRequestModel, config?: RequestConfig) {
    const requestDTO = LinkMembershipRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-link-membership'] ?? 'Memberships/Link';
    const response = await service.post<any>(endpointPath, {
      query: {
        verificationCode: requestDTO.verificationCode,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getInitialMembershipRequest(request: GetInitialMembershipRequestRequestModel, config?: RequestConfig) {
    const requestDTO = GetInitialMembershipRequestRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-initial-membership-request'] ?? 'Memberships/GetInitialRequest';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CreateMembershipRequestModel.toModel(dto));
  },
  /**  */
  async createMembership(request: CreateMembershipRequestModel, config?: RequestConfig) {
    const requestDTO = CreateMembershipRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-membership'] ?? 'Memberships';
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        businessUnitId: requestDTO.businessUnitId,
        membershipTemplateId: requestDTO.membershipTemplateId,
        payrollCategoryId: requestDTO.payrollCategoryId,
        isMembershipReEntry: requestDTO.isMembershipReEntry,
        entryDate: requestDTO.entryDate,
        socialInsuranceId: requestDTO.socialInsuranceId,
        hasOperatingLicence: requestDTO.hasOperatingLicence,
        operatingLicenceOwner: requestDTO.operatingLicenceOwner,
        dateOperatingLicenceIssued: requestDTO.dateOperatingLicenceIssued,
        hasCertificateOfProficiency: requestDTO.hasCertificateOfProficiency,
        dateCertificateOfProficiencyIssued: requestDTO.dateCertificateOfProficiencyIssued,
        certificateOfProficiencyCantonId: requestDTO.certificateOfProficiencyCantonId,
        certificateOfProficiencyCourse: requestDTO.certificateOfProficiencyCourse,
        isMemberContactReadonly: requestDTO.isMemberContactReadonly,
        memberContact: requestDTO.memberContact,
        isMemberBusinessContactReadonly: requestDTO.isMemberBusinessContactReadonly,
        memberBusinessContact: requestDTO.memberBusinessContact,
        isMemberCorrespondeceContactReadonly: requestDTO.isMemberCorrespondeceContactReadonly,
        memberCorrespondenceContact: requestDTO.memberCorrespondenceContact,
        isMembershipBusinessReadonly: requestDTO.isMembershipBusinessReadonly,
        membershipBusiness: requestDTO.membershipBusiness,
        orderBasket: requestDTO.orderBasket,
        summary: requestDTO.summary,
        acceptIsRelatedToGastro: requestDTO.acceptIsRelatedToGastro,
        acceptGsoc: requestDTO.acceptGsoc,
        acceptAgb: requestDTO.acceptAgb,
        acceptFilledOutTruthful: requestDTO.acceptFilledOutTruthful,
        acceptGsStatutes: requestDTO.acceptGsStatutes,
        newsletter: requestDTO.newsletter,
        acceptGdpr: requestDTO.acceptGdpr,
        acceptContactForClassification: requestDTO.acceptContactForClassification,
      },
      ...config,
    });
  },
  /**  */
  async getMembershipDetail(request: GetMembershipDetailRequestModel, config?: RequestConfig) {
    const requestDTO = GetMembershipDetailRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-detail'] ?? 'Memberships/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipModel.toModel(dto));
  },
  /**  */
  async getBusinesses(request: GetBusinessesRequestModel, config?: RequestConfig) {
    const requestDTO = GetBusinessesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-businesses'] ?? 'Memberships/{id}/Businesses';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipBusinessListPagingResultModel.toModel(dto));
  },
  /**  */
  async getBusinessDetail(request: GetBusinessDetailRequestModel, config?: RequestConfig) {
    const requestDTO = GetBusinessDetailRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-business-detail'] ?? 'Memberships/{id}/Businesses/{businessId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{businessId\}/g, `${requestDTO.businessId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipBusinessModel.toModel(dto));
  },
  /**  */
  async getMembershipDocuments(request: GetMembershipDocumentsRequestModel, config?: RequestConfig) {
    const requestDTO = GetMembershipDocumentsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-documents'] ?? 'Memberships/{id}/Documents';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipDocumentListPagingResultModel.toModel(dto));
  },
  /**  */
  async getMembershipOpenInvoices(request: GetMembershipOpenInvoicesRequestModel, config?: RequestConfig) {
    const requestDTO = GetMembershipOpenInvoicesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-open-invoices'] ?? 'Memberships/{id}/OpenInvoices';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipOpenInvoiceListPagingResultModel.toModel(dto));
  },
});
