import Vue from 'vue';
import core from '@glittr/frontend-core';
import router from './router';
import servicesV1 from './services/v1';
import servicesV2 from './services/v2';
import gsLanguageSelection from './components/gs-language-selection.vue';
import gsWizard from './components/processes/gs-wizard.vue';
import gsWizardStep from './components/processes/gs-wizard-step.vue';
import gsToggleValue from './components/gs-toggle-value.vue';
import gsMembershipCheckWrapper from './components/gs-membership-check-wrapper.vue';
import gsFormAddress from './components/forms/gs-form-address.vue';
import gsRaiseNowTamaro from './components/gs-raise-now-tamaro.vue';
import gsLocationField from './components/forms/gs-location-field.vue';
import gsCountryLookup from './components/forms/gs-country-lookup.vue';
import gsStateLookup from './components/forms/gs-state-lookup.vue';
import gsSubscriptionInfo from './components/subscription/gs-subscription-info.vue';
import gsAddressDisplay from './components/gs-address-display.vue';
import gsSummaryDisplay from './components/gs-summary-display.vue';
import gsIssu from './components/gs-issu.vue';
import omDynamicForm from './components/om-dynamic-form.vue';
import registerServiceWorker from './register-service-worker';

import gsAddToBasket from './components/shop/gs-add-to-basket.vue';
import gsArticleItem from './components/shop/gs-article-item.vue';
import gsArticleDetail from './components/shop/gs-article-detail.vue';
import gsArticleImage from './components/shop/gs-article-image.vue';
import gsBasketItem from './components/shop/gs-basket-item.vue';
import gsBasketSummary from './components/shop/gs-basket-summary.vue';
import gsPriceDisplay from './components/shop/gs-price-display.vue';
import gsRemoveFromBasket from './components/shop/gs-remove-from-basket.vue';
import gsShopCategories from './components/shop/gs-shop-categories.vue';
import gsShopCategoryItem from './components/shop/gs-shop-category-item.vue';
import gsBasketButton from './components/shop/gs-basket-button.vue';
import gsShopFormAddress from './components/shop/gs-shop-form-address.vue';
import gsShopCategoryEntry from './components/shop/gs-shop-category-entry.vue';
import gsShopCountryLookup from './components/shop/gs-shop-country-lookup.vue';
import gsTranslationField from './components/shop/gs-translation-field.vue';
import gsShopCategoryLookup from './components/shop/gs-shop-category-lookup.vue';
import gsShopSupplierLookup from './components/shop/gs-shop-supplier-lookup.vue';
import gsArticleTaxLookup from './components/shop/gs-article-tax-lookup.vue';

Vue.config.productionTip = false;

// Core entry point
Vue.use(core, {
  // appId: 'mount-id',
  router,
  Vue,
});

Vue.$guards.waitForAppLoad().then(() => {
  registerServiceWorker();
});

// APP____________________________________________________________
Vue.component('GsLanguageSelection', gsLanguageSelection);
Vue.component('GsMembershipCheckWrapper', gsMembershipCheckWrapper);
Vue.component('GsWizard', gsWizard);
Vue.component('GsToggleValue', gsToggleValue);
Vue.component('GsFormAddress', gsFormAddress);
Vue.component('GsRaiseNowTamaro', gsRaiseNowTamaro);
Vue.component('GsWizardStep', gsWizardStep);
Vue.component('GsLocationField', gsLocationField);
Vue.component('GsCountryLookup', gsCountryLookup);
Vue.component('GsStateLookup', gsStateLookup);
Vue.component('GsSubscriptionInfo', gsSubscriptionInfo);
Vue.component('GsAddressDisplay', gsAddressDisplay);
Vue.component('GsSummaryDisplay', gsSummaryDisplay);
Vue.component('GsIssu', gsIssu);
Vue.component('OmDynamicForm', omDynamicForm);

Vue.component('GsAddToBasket', gsAddToBasket);
Vue.component('GsArticleItem', gsArticleItem);
Vue.component('GsArticleDetail', gsArticleDetail);
Vue.component('GsArticleImage', gsArticleImage);
Vue.component('GsBasketItem', gsBasketItem);
Vue.component('GsBasketSummary', gsBasketSummary);
Vue.component('GsPriceDisplay', gsPriceDisplay);
Vue.component('GsRemoveFromBasket', gsRemoveFromBasket);
Vue.component('GsShopCategories', gsShopCategories);
Vue.component('GsShopCategoryItem', gsShopCategoryItem);
Vue.component('GsBasketButton', gsBasketButton);
Vue.component('GsShopFormAddress', gsShopFormAddress);
Vue.component('GsShopCategoryEntry', gsShopCategoryEntry);
Vue.component('GsShopCountryLookup', gsShopCountryLookup);
Vue.component('GsTranslationField', gsTranslationField);
Vue.component('GsShopCategoryLookup', gsShopCategoryLookup);
Vue.component('GsShopSupplierLookup', gsShopSupplierLookup);
Vue.component('GsArticleTaxLookup', gsArticleTaxLookup);

Vue.use(servicesV1);
Vue.use(servicesV2);

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
// Vue.use(yourPlugin);

// GUARDS
// Vue.$guardWrapper.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    /** Does the user need to be logged in to view this page? */
    hasBackButton?: boolean;
  }
}

declare module '@core/plugins/config/config' {
  interface ConfigType {
    /** General Config */
    'gs-article-images-path': string,
    'gs-business-legal-form-einzelunternehmen-lookup-id': string,

    /** Shows the debug panel */
    'raisenow-tamaro-debug': boolean,
    /** Testmode enables test payments, if set to false real payments are performed! */
    'raisenow-tamaro-testmode': boolean,
    /** Fixed amount to pay with, is either a number, or false to disable fixed amount */
    'raisenow-tamaro-fixed-amount': boolean | number,
    /** Url to load the tamaro widget */
    'raisenow-tamaro-javascript-url': string,

    /** Dynamic Form ID's */
    'dynamicForm-kitchenSinkId': string,
    'dynamicForm-gastroProfessionalTerminate': string,
    'dynamicForm-gastroJournalTerminate': string,
    'dynamicForm-gastroJournalPause': string,
    'dynamicForm-gastroJournalInterrupt': string,
    'dynamicForm-gastroJournalForward': string,
    'dynamicForm-memberChangeAddress': string,
    'dynamicForm-supportFeedback': string,
    'is-shop-enabled': boolean,
    'acceptedUploadExtensions':string[]
  }
}

require('./store');
