import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import AttributesApi from '../generated/api/Attributes';
import LookupAttributesRequestViewModel from '../viewModel/request/Attributes/LookupAttributesRequestViewModel';

export default (service: Servicelayer) => ({
  ...AttributesApi(service),

  async getSocialInsuranceTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'SocialInsurance',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getLegalFormTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'LegalForm',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getGastronomyTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'Gastronomy',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getAccommodationTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'Accommodation',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },

});
