// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopInvoiceAddressResourceViewModel from '../../../../viewModel/resource/ShopInvoiceAddressResourceViewModel';
import ShopDeliveryAddressResourceViewModel from '../../../../viewModel/resource/ShopDeliveryAddressResourceViewModel';
import ShopOrderStateResourceViewModel from '../../../../viewModel/resource/ShopOrderStateResourceViewModel';
import ShopCheckoutOrderResourceViewModel from '../../../../viewModel/resource/ShopCheckoutOrderResourceViewModel';
import ShopCheckoutOrderResourceDTO from '../../dto/resource/ShopCheckoutOrderResourceDTO';

export default abstract class ShopCheckoutOrderResourceModel extends BaseModel<ShopCheckoutOrderResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get invoiceAddress() { return this.getObjectModel(() => this.dto.invoiceAddress, ShopInvoiceAddressResourceViewModel)!; }
  set invoiceAddress(value) { this.setObjectModel(() => this.dto.invoiceAddress, value); }
  /**
  */
  get deliveryAddress() { return this.getObjectModel(() => this.dto.deliveryAddress, ShopDeliveryAddressResourceViewModel)!; }
  set deliveryAddress(value) { this.setObjectModel(() => this.dto.deliveryAddress, value); }
  /**
  * @type {int64}
  */
  get orderStateId() { return this.dto.orderStateId; }
  set orderStateId(value) { this.dto.orderStateId = value; }
  /**
  */
  get orderNumber() { return this.dto.orderNumber; }
  set orderNumber(value) { this.dto.orderNumber = value; }
  /**
  */
  get orderReference() { return this.dto.orderReference; }
  set orderReference(value) { this.dto.orderReference = value; }
  /**
  * @type {double}
  */
  get totalNetPrice() { return this.dto.totalNetPrice; }
  set totalNetPrice(value) { this.dto.totalNetPrice = value; }
  /**
  * @type {double}
  */
  get totalGrossPrice() { return this.dto.totalGrossPrice; }
  set totalGrossPrice(value) { this.dto.totalGrossPrice = value; }
  /**
  * @type {double}
  */
  get totalTaxAmount() { return this.dto.totalTaxAmount; }
  set totalTaxAmount(value) { this.dto.totalTaxAmount = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.orderstateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.orderStateId = value;
    this.dto.orderNumber = qs.ordernumber;
    this.dto.orderReference = qs.orderreference;
    value = Number.parseFloat(qs.totalnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalNetPrice = value;
    value = Number.parseFloat(qs.totalgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalGrossPrice = value;
    value = Number.parseFloat(qs.totaltaxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalTaxAmount = value;
    this.dto.currency = qs.currency;
  }
}
