// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopArticlesRequestModel from '../../model/get-shop-articles-request-model';
import CreateShopArticleRequestModel from '../../model/create-shop-article-request-model';
import GetShopArticlesByDigitalAssetIdRequestModel from '../../model/get-shop-articles-by-digital-asset-id-request-model';
import GetShopArticleByIdRequestModel from '../../model/get-shop-article-by-id-request-model';
import UpdateShopArticleRequestModel from '../../model/update-shop-article-request-model';
import GetShopArticlePriceRequestModel from '../../model/get-shop-article-price-request-model';
import ShopArticlesListPagingResultModel from '../../model/shop-articles-list-paging-result-model';
import ShopArticlePagingResultModel from '../../model/shop-article-paging-result-model';
import ShopArticleModel from '../../model/shop-article-model';
import ShopTaxedPriceModel from '../../model/shop-taxed-price-model';

export default (service: Servicelayer) => ({
  /**  */
  async getShopArticles(request: GetShopArticlesRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticlesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-articles'] ?? 'ShopArticles';
    const response = await service.get<any>(endpointPath, {
      query: {
        isPublished: requestDTO.isPublished,
        productType: requestDTO.productType,
        hasShipping: requestDTO.hasShipping,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
        exclude: requestDTO.exclude,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticlesListPagingResultModel.toModel(dto));
  },
  /**  */
  async createShopArticle(request: CreateShopArticleRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopArticleRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-article'] ?? 'ShopArticles';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        supplierId: requestDTO.supplierId,
        articleNumber: requestDTO.articleNumber,
        crmArticleId: requestDTO.crmArticleId,
        supplierArticleNumber: requestDTO.supplierArticleNumber,
        caption: requestDTO.caption,
        shortDescription: requestDTO.shortDescription,
        description: requestDTO.description,
        mainPictureId: requestDTO.mainPictureId,
        picture1Id: requestDTO.picture1Id,
        picture2Id: requestDTO.picture2Id,
        picture3Id: requestDTO.picture3Id,
        hasShipping: requestDTO.hasShipping,
        additionalInfo: requestDTO.additionalInfo,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
        isPublished: requestDTO.isPublished,
        taxId: requestDTO.taxId,
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
        hasLicense: requestDTO.hasLicense,
        isDownload: requestDTO.isDownload,
        digitalAssetId: requestDTO.digitalAssetId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopArticlesByDigitalAssetId(request: GetShopArticlesByDigitalAssetIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticlesByDigitalAssetIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-articles-by-digital-asset-id'] ?? 'ShopArticles/ByDigitalAssetId/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        currentPage: requestDTO.currentPage,
        pageSize: requestDTO.pageSize,
        includeCount: requestDTO.includeCount,
        noPaging: requestDTO.noPaging,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticlePagingResultModel.toModel(dto));
  },
  /**  */
  async getShopArticleById(request: GetShopArticleByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticleByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-by-id'] ?? 'ShopArticles/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticleModel.toModel(dto));
  },
  /**  */
  async updateShopArticle(request: UpdateShopArticleRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopArticleRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-article'] ?? 'ShopArticles/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        supplierId: requestDTO.supplierId,
        articleNumber: requestDTO.articleNumber,
        crmArticleId: requestDTO.crmArticleId,
        supplierArticleNumber: requestDTO.supplierArticleNumber,
        caption: requestDTO.caption,
        shortDescription: requestDTO.shortDescription,
        description: requestDTO.description,
        mainPictureId: requestDTO.mainPictureId,
        picture1Id: requestDTO.picture1Id,
        picture2Id: requestDTO.picture2Id,
        picture3Id: requestDTO.picture3Id,
        hasShipping: requestDTO.hasShipping,
        additionalInfo: requestDTO.additionalInfo,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
        isPublished: requestDTO.isPublished,
        taxId: requestDTO.taxId,
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
        hasLicense: requestDTO.hasLicense,
        isDownload: requestDTO.isDownload,
        digitalAssetId: requestDTO.digitalAssetId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopArticlePrice(request: GetShopArticlePriceRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticlePriceRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-price'] ?? 'ShopArticles/{id}/Price';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopTaxedPriceModel.toModel(dto));
  },
});
