import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ShopOrdersApi from '../generated/api/ShopOrders';

export default (service: Servicelayer) => ({
  ...ShopOrdersApi(service),

  // Add custom calls here

});
