// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/update-order-info-request-schema',
  type: 'object',
  required: ['userId'],
  properties: {
    orderReference: {
      type: 'string',
      nullable: true,
    },
    paymentMethod: {
      enum: [
        1,
        2,
      ],
    },
    acceptAgb: {
      type: 'boolean',
    },
    userId: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
