import Vue from 'vue';
import MembershipResourceModel from '../../generated/types/model/resource/MembershipResourceModel';
import utils from '../utils';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';

export default class MembershipResourceViewModel extends MembershipResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get businessUnitContactSummary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('memberPortal.memberData.table.section'),
          items: [
            ...this.businessSummaryItems,
          ],
        },
      ],
    });
  }

  get regionContactSummary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('memberPortal.memberData.table.cantonalAssociation'),
          items: [
            ...this.regionSummaryItems,
          ],
        },
      ],
    });
  }
  get businessSummaryItems(): RequestSummaryItemResourceViewModel[] {
    return [
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.businessUnitContact.address.companyName, {
          label: Vue.$t('memberPortal.memberData.label.area'),
          displayValue: this.businessUnitContact.address.companyName,
        }),
      }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   ...utils.insertIf(!!this.businessUnitContact.address.salutation, {
      //     label: Vue.$t('form.address.salutation'),
      //     displayValue: this.businessUnitContact.address.salutationDisplay,
      //   }),
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.firstName'),
      //   displayValue: this.businessUnitContact.address.firstName,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.lastName'),
      //   displayValue: this.businessUnitContact.address.lastName,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   ...utils.insertIf(!!this.businessUnitContact.address.street, {
      //     label: Vue.$t('form.address.address'),
      //     displayValue: `${this.businessUnitContact.address.street} ${this.businessUnitContact.address.houseNumber ?? ''}`,
      //   }),
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.additionalAddress'),
      //   displayValue: this.businessUnitContact.address.addressLine1,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.post'),
      //   displayValue: this.businessUnitContact.address.poBox,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   ...utils.insertIf(!!this.businessUnitContact.address.postalCode, {
      //     label: `${Vue.$t('form.address.plz')} / ${Vue.$t('form.address.place')}`,
      //     displayValue: `${this.businessUnitContact.address.postalCode} ${this.businessUnitContact.address.city}`,
      //   }),
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.country'),
      //   displayValue: this.businessUnitContact.address.countryIso2,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.phone'),
      //   displayValue: Vue.$format.phonePretty(this.businessUnitContact.address.phoneNumber!),
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.mobile'),
      //   displayValue: Vue.$format.phonePretty(this.businessUnitContact.address.mobilePhoneNumber!),
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.email'),
      //   displayValue: this.businessUnitContact.address.eMail,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('memberPortal.memberData.table.website'),
      //   displayValue: this.businessUnitContact.address.website,
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.birthDate'),
      //   displayValue: Vue.$format.localDate(this.businessUnitContact.address.dateOfBirth!),
      // }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('memberPortal.createMembership.table.correspondenceLanguage'),
      //   displayValue: this.businessUnitContact.address.languageIso2?.toUpperCase(),
      // }),
    ].filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null);
  }

  get regionSummaryItems(): RequestSummaryItemResourceViewModel[] {
    return [
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.regionContact.address.companyName, {
          label: Vue.$t('memberPortal.memberData.label.association'),
          displayValue: this.regionContact.address.companyName,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.regionContact.address.salutation, {
          label: Vue.$t('form.address.salutation'),
          displayValue: this.regionContact.address.salutationDisplay,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.firstName'),
        displayValue: this.regionContact.address.firstName,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.lastName'),
        displayValue: this.regionContact.address.lastName,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.regionContact.address.street, {
          label: Vue.$t('form.address.address'),
          displayValue: `${this.regionContact.address.street} ${this.regionContact.address.houseNumber ?? ''}`,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.additionalAddress'),
        displayValue: this.regionContact.address.addressLine1,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.post'),
        displayValue: this.regionContact.address.poBox,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.regionContact.address.postalCode, {
          label: `${Vue.$t('form.address.plz')} / ${Vue.$t('form.address.place')}`,
          displayValue: `${this.regionContact.address.postalCode} ${this.regionContact.address.city}`,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.country'),
        displayValue: this.regionContact.address.countryIso2,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.phone'),
        displayValue: Vue.$format.phonePretty(this.regionContact.address.phoneNumber!),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.mobile'),
        displayValue: Vue.$format.phonePretty(this.regionContact.address.mobilePhoneNumber!),
      }),
      // new RequestSummaryItemResourceViewModel().fromModel({
      //   label: Vue.$t('form.address.email'),
      //   displayValue: this.regionContact.address.eMail,
      // }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.memberData.table.website'),
        displayValue: this.regionContact.address.website,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.birthDate'),
        displayValue: Vue.$format.localDate(this.regionContact.address.dateOfBirth!),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.createMembership.table.correspondenceLanguage'),
        displayValue: this.regionContact.address.languageIso2?.toUpperCase(),
      }),
    ].filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null);
  }
}
