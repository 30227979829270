import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import HotelClassificationsApi from '../generated/api/HotelClassifications';
import HotelClassificationBusinessListResourceListResultViewModel from '../viewModel/resource/HotelClassificationBusinessListResourceListResultViewModel';
import CreateHotelClassificationRequestViewModel from '../viewModel/resource/CreateHotelClassificationRequestViewModel';

export default (service: Servicelayer) => ({
  ...HotelClassificationsApi(service),

  getHasHotelClassification() {
    const getSubscribeStatus = Vue.$auth.getClaim('gs-HotelClassification') ?? '0';
    return getSubscribeStatus === '2';
  },

  async addHotelClassification(model: Omit<CreateHotelClassificationRequestViewModel, 'userId'>) {
    const request = new CreateHotelClassificationRequestViewModel().fromModel(model);
    request.userId = Vue.$auth.user?.id!;
    return HotelClassificationsApi(service).addHotelClassification(request);
  },

  async getMyHotelClassificationBusinesses() {
    const user = await Vue.$auth.getUser();
    const userId = user?.id!;
    if (!userId) {
      return new HotelClassificationBusinessListResourceListResultViewModel();
    }
    return this.getHotelClassificationBusinesses(userId);
  },

});
