<template>
  <div :class="classList">
    <component :is="'h' + titleDepth" v-if="!isLoading && depth === 0">
      {{ computedDefinition.description }}
    </component>
    <p-conditional-wrapper :show="depth !== 0">
      <p-accordion class="mt-1" :title="computedDefinition.description">
        <template v-if="isProcessCompleted && _.component.hasSlot(this, 'completed')">
          <div class="my-4">
            <slot name="completed"></slot>
          </div>
          <p-divider class="my-4"></p-divider>
          <p-row>
            <p-button @click="goBack">
              OK
            </p-button>
          </p-row>
        </template>
        <template v-else>
          <slot v-if="depth===0" name="prepend-form"></slot>

          <gs-form-address
            v-if="showAddressForm"
            v-model="contactData"
            has-personal-info
            company-name-required
          ></gs-form-address>

          <p-progress-linear v-if="isLoading" class="my-4" indeterminate></p-progress-linear>

          <p-conditional-wrapper :show="depth === 0">
            <p-form :model="flatForm">
              <p-row column>
                <p-col v-for="field in sortedFieldDefinitions" :key="field.dynamicFormFieldDefinitionId">
                  <component
                    :is="mapTypeToComponent(field)"
                    :loading="isFieldLoading[field.dynamicFormFieldDefinitionId]"
                    :disabled="isFieldLoading[field.dynamicFormFieldDefinitionId]"
                    :value="flatForm.fields[field.dynamicFormFieldDefinitionId]"
                    :label="field.description"
                    :items="getChoices(field)"
                    item-display="description"
                    item-value="value"
                    :errors="fieldErrors[field.dynamicFormFieldDefinitionId]"
                    :required="field.isMandatory"
                    :definition="field"
                    :depth="depth + 1"
                    @input="setFieldValue(field.dynamicFormFieldDefinitionId, $event)"
                  >
                    {{ field.description }}
                  </component>
                </p-col>
              </p-row>
            </p-form>
            <p-row column>
              <slot name="append-form"></slot>
            </p-row>
            <p-row v-if="depth===0 && !isLoading">
              <p-button :disabled="isSending || submitDisabled" :loading="isSending" @click="onSubmit">
                {{ $t('app.send') }}
              </p-button>
            </p-row>
          </p-conditional-wrapper>
        </template>
      </p-accordion>
      <p-divider class="mb-4"></p-divider>
    </p-conditional-wrapper>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
  import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
  import PTextField from '@glittr/frontend-core/src/components/p-text-field/p-text-field.vue';
  import PNumberField from '@glittr/frontend-core/src/components/p-number-field/p-number-field.vue';
  import PDateField from '@glittr/frontend-core/src/components/p-date-field/p-date-field.vue';
  import PTextArea from '@glittr/frontend-core/src/components/p-textarea/p-textarea.vue';
  import PFileSelection from '@glittr/frontend-core/src/components/p-file-selection/p-file-selection.vue';
  import PSelect from '@glittr/frontend-core/src/components/p-select/p-select.vue';
  import DynamicFormFieldResourceViewModel from '../services/v1/viewModel/resource/DynamicFormFieldResourceViewModel';
  import CreateDynamicFormRequestViewModel from '../services/v1/viewModel/resource/CreateDynamicFormRequestViewModel';
  import CreateDynamicFormFieldRequestViewModel from '../services/v1/viewModel/resource/CreateDynamicFormFieldRequestViewModel';
  import DynamicFormFieldFileResourceViewModel from '../services/v1/viewModel/resource/DynamicFormFieldFileResourceViewModel';
  import ContactResourceViewModel from '../services/v1/viewModel/resource/ContactResourceViewModel';
  import DynamicFormChoiceResourceViewModel from '../services/v1/viewModel/resource/DynamicFormChoiceResourceViewModel';
  import ContactAddressResourceViewModel from '../services/v1/viewModel/resource/ContactAddressResourceViewModel';
  import DynamicFormResourceViewModel from '../services/v1/viewModel/resource/DynamicFormResourceViewModel';

  export class DynamicFormFlatValuesViewModel extends BaseModel<any> {
    protected transformToDTO(): void {}
    protected transformFromDTO(): void {}

    get fields(): Record<string, any> { return this.dto.fields; }
    set fields(value) { this.dto.fields = value; }
  }

  export default Vue.extend({
    name: 'OMDynamicForm',
    props: {
      value: { type: Object, default: () => ({}) },
      definition: { type: Object, default: () => (undefined) },
      depth: { type: Number, default: 0 },
      contactId: { type: [Number, String], default: undefined },
      submitDisabled: { type: Boolean, default: false },
    },
    data: () => ({
      isProcessCompleted: false,
      contactData: new ContactResourceViewModel().fromModel({
        address: new ContactAddressResourceViewModel(),
      }),
      isLoading: false,
      isSending: false,
      isFieldLoading: {} as Record<string, boolean>,
      fieldErrors: {} as Record<string, boolean>,
      flatForm: new DynamicFormFlatValuesViewModel().fromModel({ fields: {} }),
    }),
    computed: {
      computedDefinition(): DynamicFormResourceViewModel {
        return this.definition ?? {};
      },
      showAddressForm(): boolean {
        return this.depth === 0 && !this.contactId;
      },
      titleDepth(): number {
        return Math.min(6, this.depth + 3);
      },
      sortedFieldDefinitions(): DynamicFormFieldResourceViewModel[] {
        const sortedFieldDefinitions = this.computedFormFieldDefinitions ?? [];
        const formFieldsCopy = [...sortedFieldDefinitions];
        formFieldsCopy.sort((a, b) => (a?.orderSequence ?? 0) - (b?.orderSequence ?? 0));
        return formFieldsCopy;
      },
      classList(): Record<string, boolean> {
        return {
          OMDynamicForm: true,
          [`ml-${this.depth}`]: !!this.depth,
          [`pl-${this.depth}`]: !!this.depth,
        };
      },
      hasFormFields(): boolean {
        return (this.definition?.formFields?.length ?? 0) > 0;
      },
      computedFormFieldDefinitions(): DynamicFormFieldResourceViewModel[] {
        if (this.hasFormFields) {
          return this.definition?.formFields;
        }
        return (this.definition as DynamicFormFieldResourceViewModel)?.groupFields;
      },
    },
    watch: {
      'contactData.address': {
        deep: true,
        handler() {
          this.emitOutput();
          this.$forceUpdate();
        },
      },
      definition: {
        immediate: true,
        handler() {
          this.isLoading = !this.definition;
          this.$nextTick(() => {
            if (this.computedFormFieldDefinitions) {
              this.prefillValues(this.computedFormFieldDefinitions);
              (this.flatForm as any).schema = this.generateSchema(this.computedFormFieldDefinitions);
            }
          });
        },
      },
    },
    beforeMount() {
      (this.contactData.address as any).schema = {
        ...queryBuilder.properties({
          salutation: { title: this.$t('form.address.salutation'), required: true },
          firstName: { title: this.$t('form.address.firstName'), required: true },
          lastName: { title: this.$t('form.address.lastName'), required: true },
          street: { title: this.$t('form.address.street'), required: true },
          houseNumber: { title: this.$t('form.address.number'), required: true },
          postalCode: { title: this.$t('form.address.plz'), required: true },
          city: { title: this.$t('form.address.place'), required: true },
        }),
      };
    },
    methods: {
      async goBack() {
        this.$router.back();
      },
      async onSubmit() {
        let notValid = !this.flatForm?.validate();
        if (!this.contactId) {
          if (!this.contactData.address.eMail) {
            const user = await this.$auth.getUser();
            this.contactData.address.eMail = user?.email;
          }
          notValid = !this.contactData?.address?.validate() || notValid;
        }
        if (notValid) {
          return;
        }
        this.isSending = true;
        const request = await this.convertToFormRequest();
        await this.$service.api.dynamicForms.createDynamicForm(request);
        this.isSending = false;
        this.$emit('submit');
        this.isProcessCompleted = true;
      },
      generateFlatPropsSchema(fieldDefinitions: DynamicFormFieldResourceViewModel[]) {
        let props = {} as Record<string, Parameters<typeof queryBuilder.properties>[0]>;
        fieldDefinitions.forEach((fieldDef) => {
          if (fieldDef.type === 2) {
            // recursively merge schema since the schema is needed as a whole
            const groupProps = this.generateFlatPropsSchema(fieldDef.groupFields);
            props = { ...props, ...groupProps };
          }
          props[fieldDef.dynamicFormFieldDefinitionId!] = {
            required: fieldDef.isMandatory as any,
            title: fieldDef.description,
          } as typeof props[0];
        });
        return props;
      },
      generateSchema(fieldDefinitions: DynamicFormFieldResourceViewModel[]) {
        const props = this.generateFlatPropsSchema(fieldDefinitions);
        return {
          ...queryBuilder.properties({
            fields: {
              type: 'object',
              properties: {
                ...props,
              },
            },
          }),
        };
      },
      getChoices(field: DynamicFormFieldResourceViewModel) {
        if (field.isMandatory) {
          return field.choices;
        }
        return [...field.choices];
      },
      prefillValues(fields: DynamicFormFieldResourceViewModel[]) {
        fields.forEach((field) => {
          field.choices.forEach((choice: DynamicFormChoiceResourceViewModel) => {
            if (choice.isChosenByDefault) {
              this.$set(this.flatForm.fields, field.dynamicFormFieldDefinitionId!, choice.value);
            }
          });
        });
      },
      setFieldValue(fieldId: string, value: any) {
        if (value instanceof CreateDynamicFormFieldRequestViewModel) {
          if (Array.isArray(value.groupFields)) {
            value.groupFields.forEach((field) => {
              this.setFieldValue(field.dynamicFormFieldDefinitionId!, field.value);
            });
          }
        }
        this.$set(this.flatForm.fields, fieldId, value);
        this.emitOutput();
        this.$forceUpdate();
      },
      async convertToFormField(definition: DynamicFormFieldResourceViewModel, fieldValue: any): Promise<CreateDynamicFormFieldRequestViewModel> {
        const formField = new CreateDynamicFormFieldRequestViewModel().fromModel({
          dynamicFormFieldDefinitionId: definition?.dynamicFormFieldDefinitionId,
        });
        let value = fieldValue;
        if (definition?.type === 2) {
          formField.groupFields = value?.groupFields ?? [];
        } else {
          if (definition?.type === 3) {
            // TODO: by calling this with every input, performance is not good
            // The component could actually have a prop/event for a base64-url output when a new file is selected
            // Make these changes in combination with creating the file-drop component
            const fileValue = value as File;
            const fileField = await new DynamicFormFieldFileResourceViewModel().fromFile(fileValue);
            value = fileField.getDTO();
          }
          if (definition?.type === 6) {
            value = this.$format.localDate(value);
          }
          if (typeof value === 'string') {
            value = value.trim();
          }
          formField.value = value;
        }
        return formField;
      },
      async convertToFormFields(fieldValues: Record<string, any>) {
        const allPromises = [] as Promise<any>[];
        if (!this.computedFormFieldDefinitions) {
          return [];
        }
        this.computedFormFieldDefinitions.forEach(async (definition) => {
          const promise = this.convertToFormField(definition, fieldValues[definition.dynamicFormFieldDefinitionId!]);
          allPromises.push(promise);
        });
        const formFields = await Promise.all<CreateDynamicFormFieldRequestViewModel>(allPromises);
        return formFields;
      },
      async convertToFormRequest() {
        const formFields = await this.convertToFormFields(this.flatForm.fields);
        const output = new CreateDynamicFormRequestViewModel();
        output.dynamicFormDefinitionId = this.definition?.id;
        output.languageIso = this.$translation.get();
        output.contactId = this.contactId?.toString();
        output.contact = new ContactResourceViewModel().fromModel(this.contactData);
        output.formFields = formFields;
        return output;
      },
      async emitOutput() {
        this.$debounce(async () => {
          const formFields = await this.convertToFormFields(this.flatForm.fields);
          // At root
          if (this.depth === 0) {
            const output = await this.convertToFormRequest();
            this.$emit('input', output);
          } else {
            const output = new CreateDynamicFormFieldRequestViewModel();
            output.groupFields = formFields;
            this.$emit('input', output);
          }
        }, 300, this)();
      },
      mapTypeToComponent(fieldDefinition: DynamicFormFieldResourceViewModel) {
        const { type } = fieldDefinition;
        switch (type) {
        case 1: // Heading
          return `h${Math.min(6, this.titleDepth + 2)}`;
        case 2: // Group
          return 'OMDynamicForm';
        case 3: // File
          return PFileSelection;
        case 4: // String
          if (fieldDefinition.enableMultiline) {
            return PTextArea;
          }
          return PTextField;
        case 5: // Number
          return PNumberField;
        case 6: // Date
          return PDateField;
        case 7: // Select
          return PSelect;
        default:
          console.error('[dynamic-form] Unable to resolve the proper component to be used, defaulting to a simple div');
          return 'div';
        }
      },
    },
  });
</script>
