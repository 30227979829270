// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';

export default (service: Servicelayer) => ({
  /**  */
  async deleteShopArticleDigitalAssetById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-article-digital-asset-by-id'] ?? 'ShopArticleDigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async downloadAsset(orderId: number, orderItemId: number, assetId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-asset'] ?? 'ShopArticleDigitalAsset/Download/{orderId}/{orderItemId}/{assetId}';
    endpointPath = endpointPath.replace(/\{orderId\}/g, `${orderId}`);
    endpointPath = endpointPath.replace(/\{orderItemId\}/g, `${orderItemId}`);
    endpointPath = endpointPath.replace(/\{assetId\}/g, `${assetId}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
