<template>
  <p-select
    :value="value"
    class="gs-shop-category-lookup"
    v-bind="$props"
    :label="label ? label : 'Kategorie'"
    can-search
    :clearable="clearable"
    :errors="errorMessage"
    :loading="isLoading"
    :items="items"
    @input="$emit('input', $event)"
    @update:search="onSearch"
  ></p-select>
</template>

<script lang="ts">
  import LookupCategoriesRequestViewModel from '@/src/services/v1/viewModel/request/ShopCategories/LookupCategoriesRequestViewModel';
  import Int64LookupResourceViewModel from '@/src/services/v1/viewModel/resource/Int64LookupResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsShopCategoryLookup',
    props: {
      value: { type: undefined, default: undefined },
      label: { type: String, default: undefined },
      clearable: { type: Boolean, default: false },
    },
    data: () => ({
      items: [] as Int64LookupResourceViewModel[],
      errorMessage: undefined as undefined | string,
      isLoading: false,
      filter: new LookupCategoriesRequestViewModel(),
    }),
    mounted() {
      this.populateData();
    },
    methods: {
      onSearch(searchCriteria: string) {
        this.filter.searchCriteria = searchCriteria;
        this.populateData();
      },
      async populateData() {
        this.isLoading = true;
        try {
          const response = await this.$service.api.shopCategories.lookupCategories(this.filter);
          this.items = response.items;
        } catch (error: any) {
          this.errorMessage = error?.message ?? error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
