import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ShopArticlesApi from '../generated/api/ShopArticles';

export default (service: Servicelayer) => ({
  ...ShopArticlesApi(service),

  // Add custom calls here

});
