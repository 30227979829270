// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopArticleResourceViewModel from '../../../../viewModel/resource/ShopArticleResourceViewModel';
import ShopArticleResourceDTO from '../../dto/resource/ShopArticleResourceDTO';

export default abstract class ShopArticleResourceModel extends BaseModel<ShopArticleResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get supplierId() { return this.dto.supplierId; }
  set supplierId(value) { this.dto.supplierId = value; }
  /**
  */
  get articleNumber() { return this.dto.articleNumber; }
  set articleNumber(value) { this.dto.articleNumber = value; }
  /**
  */
  get crmArticleId() { return this.dto.crmArticleId; }
  set crmArticleId(value) { this.dto.crmArticleId = value; }
  /**
  */
  get supplierArticleNumber() { return this.dto.supplierArticleNumber; }
  set supplierArticleNumber(value) { this.dto.supplierArticleNumber = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get shortDescription() { return this.dto.shortDescription; }
  set shortDescription(value) { this.dto.shortDescription = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  * @type {int64}
  */
  get mainPictureId() { return this.dto.mainPictureId; }
  set mainPictureId(value) { this.dto.mainPictureId = value; }
  /**
  * @type {int64}
  */
  get picture1Id() { return this.dto.picture1Id; }
  set picture1Id(value) { this.dto.picture1Id = value; }
  /**
  * @type {int64}
  */
  get picture2Id() { return this.dto.picture2Id; }
  set picture2Id(value) { this.dto.picture2Id = value; }
  /**
  * @type {int64}
  */
  get picture3Id() { return this.dto.picture3Id; }
  set picture3Id(value) { this.dto.picture3Id = value; }
  /**
  */
  get hasShipping() { return this.dto.hasShipping; }
  set hasShipping(value) { this.dto.hasShipping = value; }
  /**
  */
  get additionalInfo() { return this.dto.additionalInfo; }
  set additionalInfo(value) { this.dto.additionalInfo = value; }
  /**
  * @type {date-time}
  */
  get validFrom() { return this.dto.validFrom; }
  set validFrom(value) { this.dto.validFrom = value; }
  /**
  * @type {date-time}
  */
  get validTo() { return this.dto.validTo; }
  set validTo(value) { this.dto.validTo = value; }
  /**
  */
  get isPublished() { return this.dto.isPublished; }
  set isPublished(value) { this.dto.isPublished = value; }
  /**
  * @type {int64}
  */
  get taxId() { return this.dto.taxId; }
  set taxId(value) { this.dto.taxId = value; }
  /**
  */
  get translatedLanguage_ISO2() { return this.dto.translatedLanguage_ISO2; }
  set translatedLanguage_ISO2(value) { this.dto.translatedLanguage_ISO2 = value; }
  /**
  */
  get hasLicense() { return this.dto.hasLicense; }
  set hasLicense(value) { this.dto.hasLicense = value; }
  /**
  */
  get isDownload() { return this.dto.isDownload; }
  set isDownload(value) { this.dto.isDownload = value; }
  /**
  * @type {int64}
  */
  get digitalAssetId() { return this.dto.digitalAssetId; }
  set digitalAssetId(value) { this.dto.digitalAssetId = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.supplierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.supplierId = value;
    this.dto.articleNumber = qs.articlenumber;
    this.dto.crmArticleId = qs.crmarticleid;
    this.dto.supplierArticleNumber = qs.supplierarticlenumber;
    this.dto.caption = qs.caption;
    this.dto.shortDescription = qs.shortdescription;
    this.dto.description = qs.description;
    value = Number.parseFloat(qs.mainpictureid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.mainPictureId = value;
    value = Number.parseFloat(qs.picture1id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.picture1Id = value;
    value = Number.parseFloat(qs.picture2id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.picture2Id = value;
    value = Number.parseFloat(qs.picture3id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.picture3Id = value;
    value = qs.hasShipping === 'true' ? true : qs.hasshipping;
    value = qs.hasShipping === 'false' ? false : undefined;
    this.dto.hasShipping = value;
    this.dto.additionalInfo = qs.additionalinfo;
    this.dto.validFrom = qs.validfrom;
    this.dto.validTo = qs.validto;
    value = qs.isPublished === 'true' ? true : qs.ispublished;
    value = qs.isPublished === 'false' ? false : undefined;
    this.dto.isPublished = value;
    value = Number.parseFloat(qs.taxid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxId = value;
    this.dto.translatedLanguage_ISO2 = qs.translatedlanguage_iso2;
    value = qs.hasLicense === 'true' ? true : qs.haslicense;
    value = qs.hasLicense === 'false' ? false : undefined;
    this.dto.hasLicense = value;
    value = qs.isDownload === 'true' ? true : qs.isdownload;
    value = qs.isDownload === 'false' ? false : undefined;
    this.dto.isDownload = value;
    value = Number.parseFloat(qs.digitalassetid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.digitalAssetId = value;
  }
}
