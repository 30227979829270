// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/set-payment-request-schema',
  type: 'object',
  required: ['amount', 'paymentMethod', 'userId'],
  properties: {
    transactionId: {
      type: 'string',
      nullable: true,
    },
    amount: {
      type: 'number',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    paymentMethod: {
      enum: [
        1,
        2,
      ],
    },
    onlinePaymentMethod: {
      enum: [
        0,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
      ],
    },
    userId: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
