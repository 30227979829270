// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SubscriptionContractModel from '../../model/subscription-contract-model';
import SubscriptionContractDTO from '../dto/subscription-contract-dto';
import dtoSchema from '../schemas/subscription-contract-schema';
import ContactModel from '../../model/contact-model';
import DeliveryContactModel from '../../model/delivery-contact-model';
import SubscriptionArticleModel from '../../model/subscription-article-model';

export default abstract class SubscriptionContractModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SubscriptionContractDTO>) {
    super();
    if (data) {
      this.subscriptionType = data.subscriptionType;
      this.subscriptionStartDate = data.subscriptionStartDate;
      this.billingContact = data.billingContact ? ContactModel.toModel(data.billingContact) : undefined;
      this.deliveryContact = data.deliveryContact ? DeliveryContactModel.toModel(data.deliveryContact) : undefined;
      this.subscriptionArticle = data.subscriptionArticle ? SubscriptionArticleModel.toModel(data.subscriptionArticle) : undefined;
      this.canBeCancelled = data.canBeCancelled;
      this.hasCombiSubscription = data.hasCombiSubscription;
    }
  }
  /**
  * @type {int32}
  */
  subscriptionType?: 1 | 2; // SubscriptionTypeDTO
  /**
  * @type {date-time}
  */
  subscriptionStartDate?: string;
  /**
  */
  billingContact?: ContactModel;
  /**
  */
  deliveryContact?: DeliveryContactModel;
  /**
  */
  subscriptionArticle?: SubscriptionArticleModel;
  /**
  */
  canBeCancelled?: boolean;
  /**
  */
  hasCombiSubscription?: boolean;

  static toModel(dto: DeepPartial<SubscriptionContractDTO>): SubscriptionContractModel;
  static toModel(dto: DeepPartial<SubscriptionContractDTO> | undefined | null): SubscriptionContractModel | undefined;
  static toModel(dto: DeepPartial<SubscriptionContractDTO> | undefined | null): SubscriptionContractModel | undefined {
    return dto ? new SubscriptionContractModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SubscriptionContractModel> | SubscriptionContractModel): SubscriptionContractDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      subscriptionType: unwrapped.subscriptionType,
      subscriptionStartDate: unwrapped.subscriptionStartDate,
      billingContact: unwrapped.billingContact,
      deliveryContact: unwrapped.deliveryContact,
      subscriptionArticle: unwrapped.subscriptionArticle,
      canBeCancelled: unwrapped.canBeCancelled,
      hasCombiSubscription: unwrapped.hasCombiSubscription,
    } as SubscriptionContractDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SubscriptionContractModel, can not map to SubscriptionContractDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
