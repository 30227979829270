// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import RemoveFromBasketRequestDTO from '../../dto/request/RemoveFromBasketRequestDTO';

export default abstract class RemoveFromBasketRequestModel extends BaseModel<RemoveFromBasketRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  * @type {uuid}
  */
  get publicBasketId() { return this.dto.publicBasketId; }
  set publicBasketId(value) { this.dto.publicBasketId = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.userid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.publicBasketId = qs.publicbasketid;
  }
}
