// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetBusinessUnitByPostalCodeRequestModel from '../../model/get-business-unit-by-postal-code-request-model';
import GetBusinessUnitByUserIdRequestModel from '../../model/get-business-unit-by-user-id-request-model';
import BusinessUnitModel from '../../model/business-unit-model';

export default (service: Servicelayer) => ({
  /**  */
  async getBusinessUnitByPostalCode(request: GetBusinessUnitByPostalCodeRequestModel, config?: RequestConfig) {
    const requestDTO = GetBusinessUnitByPostalCodeRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-business-unit-by-postal-code'] ?? 'BusinessUnits/PostalCode';
    const response = await service.get<any>(endpointPath, {
      query: {
        postalCode: requestDTO.postalCode,
        postalCodeAddition: requestDTO.postalCodeAddition,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(BusinessUnitModel.toModel(dto));
  },
  /**  */
  async getBusinessUnitByUserId(request: GetBusinessUnitByUserIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetBusinessUnitByUserIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-business-unit-by-user-id'] ?? 'BusinessUnits/UserId/{userId}';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(BusinessUnitModel.toModel(dto));
  },
});
