// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopOrderPagedListRequestModel from '../../model/get-shop-order-paged-list-request-model';
import CreateShopOrderRequestModel from '../../model/create-shop-order-request-model';
import GetShopOrdersByDigitalAssetIdRequestModel from '../../model/get-shop-orders-by-digital-asset-id-request-model';
import GetShopOrderRequestModel from '../../model/get-shop-order-request-model';
import UpdateShopOrderRequestModel from '../../model/update-shop-order-request-model';
import DeleteShopOrderRequestModel from '../../model/delete-shop-order-request-model';
import GetShopOrderItemListRequestModel from '../../model/get-shop-order-item-list-request-model';
import GetShopPaymentListRequestModel from '../../model/get-shop-payment-list-request-model';
import ShopOrderPagingResultModel from '../../model/shop-order-paging-result-model';
import ShopOrderModel from '../../model/shop-order-model';
import ShopOrderItemListResultModel from '../../model/shop-order-item-list-result-model';
import ShopPaymentListResultModel from '../../model/shop-payment-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getShopOrderPagedList(request: GetShopOrderPagedListRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopOrderPagedListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-order-paged-list'] ?? 'ShopOrders';
    const response = await service.get<any>(endpointPath, {
      query: {
        orderStateId: requestDTO.orderStateId,
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopOrderPagingResultModel.toModel(dto));
  },
  /**  */
  async createShopOrder(request: CreateShopOrderRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-order'] ?? 'ShopOrders';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        userId: requestDTO.userId,
        orderNumber: requestDTO.orderNumber,
        orderReference: requestDTO.orderReference,
        orderStateId: requestDTO.orderStateId,
        orderDate: requestDTO.orderDate,
        currency: requestDTO.currency,
        totalGrossPrice: requestDTO.totalGrossPrice,
        totalNetPrice: requestDTO.totalNetPrice,
        totalTaxAmount: requestDTO.totalTaxAmount,
        invoiceAddress_Salutation: requestDTO.invoiceAddress_Salutation,
        invoiceAddress_Company: requestDTO.invoiceAddress_Company,
        invoiceAddress_FirstName: requestDTO.invoiceAddress_FirstName,
        invoiceAddress_LastName: requestDTO.invoiceAddress_LastName,
        invoiceAddress_Street: requestDTO.invoiceAddress_Street,
        invoiceAddress_Number: requestDTO.invoiceAddress_Number,
        invoiceAddress_POBox: requestDTO.invoiceAddress_POBox,
        invoiceAddress_AdditionalLine: requestDTO.invoiceAddress_AdditionalLine,
        invoiceAddress_PostalCode: requestDTO.invoiceAddress_PostalCode,
        invoiceAddress_City: requestDTO.invoiceAddress_City,
        invoiceAddress_CountryIso2: requestDTO.invoiceAddress_CountryIso2,
        invoiceAddress_Phone: requestDTO.invoiceAddress_Phone,
        invoiceAddress_MobilePhone: requestDTO.invoiceAddress_MobilePhone,
        invoiceAddress_Email: requestDTO.invoiceAddress_Email,
        invoiceAddress_CrmContactId: requestDTO.invoiceAddress_CrmContactId,
        deliveryAddress_IsDifferent: requestDTO.deliveryAddress_IsDifferent,
        deliveryAddress_Salutation: requestDTO.deliveryAddress_Salutation,
        deliveryAddress_Company: requestDTO.deliveryAddress_Company,
        deliveryAddress_FirstName: requestDTO.deliveryAddress_FirstName,
        deliveryAddress_LastName: requestDTO.deliveryAddress_LastName,
        deliveryAddress_Street: requestDTO.deliveryAddress_Street,
        deliveryAddress_Number: requestDTO.deliveryAddress_Number,
        deliveryAddress_POBox: requestDTO.deliveryAddress_POBox,
        deliveryAddress_AdditionalLine: requestDTO.deliveryAddress_AdditionalLine,
        deliveryAddress_PostalCode: requestDTO.deliveryAddress_PostalCode,
        deliveryAddress_City: requestDTO.deliveryAddress_City,
        deliveryAddress_CountryIso2: requestDTO.deliveryAddress_CountryIso2,
        deliveryAddress_Phone: requestDTO.deliveryAddress_Phone,
        deliveryAddress_MobilePhone: requestDTO.deliveryAddress_MobilePhone,
        deliveryAddress_Email: requestDTO.deliveryAddress_Email,
        language_ISO2: requestDTO.language_ISO2,
        membershipId: requestDTO.membershipId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopOrdersByDigitalAssetId(request: GetShopOrdersByDigitalAssetIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopOrdersByDigitalAssetIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-orders-by-digital-asset-id'] ?? 'ShopOrders/ByDigitalAssetId/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        currentPage: requestDTO.currentPage,
        pageSize: requestDTO.pageSize,
        includeCount: requestDTO.includeCount,
        noPaging: requestDTO.noPaging,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopOrderPagingResultModel.toModel(dto));
  },
  /**  */
  async getShopOrder(request: GetShopOrderRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-order'] ?? 'ShopOrders/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopOrderModel.toModel(dto));
  },
  /**  */
  async updateShopOrder(request: UpdateShopOrderRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-order'] ?? 'ShopOrders/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        userId: requestDTO.userId,
        orderNumber: requestDTO.orderNumber,
        orderReference: requestDTO.orderReference,
        orderStateId: requestDTO.orderStateId,
        orderDate: requestDTO.orderDate,
        currency: requestDTO.currency,
        totalGrossPrice: requestDTO.totalGrossPrice,
        totalNetPrice: requestDTO.totalNetPrice,
        totalTaxAmount: requestDTO.totalTaxAmount,
        invoiceAddress_Salutation: requestDTO.invoiceAddress_Salutation,
        invoiceAddress_Company: requestDTO.invoiceAddress_Company,
        invoiceAddress_FirstName: requestDTO.invoiceAddress_FirstName,
        invoiceAddress_LastName: requestDTO.invoiceAddress_LastName,
        invoiceAddress_Street: requestDTO.invoiceAddress_Street,
        invoiceAddress_Number: requestDTO.invoiceAddress_Number,
        invoiceAddress_POBox: requestDTO.invoiceAddress_POBox,
        invoiceAddress_AdditionalLine: requestDTO.invoiceAddress_AdditionalLine,
        invoiceAddress_PostalCode: requestDTO.invoiceAddress_PostalCode,
        invoiceAddress_City: requestDTO.invoiceAddress_City,
        invoiceAddress_CountryIso2: requestDTO.invoiceAddress_CountryIso2,
        invoiceAddress_Phone: requestDTO.invoiceAddress_Phone,
        invoiceAddress_MobilePhone: requestDTO.invoiceAddress_MobilePhone,
        invoiceAddress_Email: requestDTO.invoiceAddress_Email,
        invoiceAddress_CrmContactId: requestDTO.invoiceAddress_CrmContactId,
        deliveryAddress_IsDifferent: requestDTO.deliveryAddress_IsDifferent,
        deliveryAddress_Salutation: requestDTO.deliveryAddress_Salutation,
        deliveryAddress_Company: requestDTO.deliveryAddress_Company,
        deliveryAddress_FirstName: requestDTO.deliveryAddress_FirstName,
        deliveryAddress_LastName: requestDTO.deliveryAddress_LastName,
        deliveryAddress_Street: requestDTO.deliveryAddress_Street,
        deliveryAddress_Number: requestDTO.deliveryAddress_Number,
        deliveryAddress_POBox: requestDTO.deliveryAddress_POBox,
        deliveryAddress_AdditionalLine: requestDTO.deliveryAddress_AdditionalLine,
        deliveryAddress_PostalCode: requestDTO.deliveryAddress_PostalCode,
        deliveryAddress_City: requestDTO.deliveryAddress_City,
        deliveryAddress_CountryIso2: requestDTO.deliveryAddress_CountryIso2,
        deliveryAddress_Phone: requestDTO.deliveryAddress_Phone,
        deliveryAddress_MobilePhone: requestDTO.deliveryAddress_MobilePhone,
        deliveryAddress_Email: requestDTO.deliveryAddress_Email,
        language_ISO2: requestDTO.language_ISO2,
        membershipId: requestDTO.membershipId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopOrder(request: DeleteShopOrderRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-order'] ?? 'ShopOrders/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopOrderItemList(request: GetShopOrderItemListRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopOrderItemListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-order-item-list'] ?? 'ShopOrders/{id}/Items';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopOrderItemListResultModel.toModel(dto));
  },
  /**  */
  async getShopPaymentList(request: GetShopPaymentListRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopPaymentListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-payment-list'] ?? 'ShopOrders/{id}/Payments';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopPaymentListResultModel.toModel(dto));
  },
});
