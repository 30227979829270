// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import CalculateMembershipRequestViewModel from '../../viewModel/request/Memberships/CalculateMembershipRequestViewModel';
import LinkMembershipRequestViewModel from '../../viewModel/request/Memberships/LinkMembershipRequestViewModel';
import GetInitialMembershipRequestRequestViewModel from '../../viewModel/request/Memberships/GetInitialMembershipRequestRequestViewModel';
import GetBusinessesRequestViewModel from '../../viewModel/request/Memberships/GetBusinessesRequestViewModel';
import GetMembershipDocumentsRequestViewModel from '../../viewModel/request/Memberships/GetMembershipDocumentsRequestViewModel';
import MembershipStatusResourceViewModel from '../../viewModel/resource/MembershipStatusResourceViewModel';
import BasketResourceViewModel from '../../viewModel/resource/BasketResourceViewModel';
import CreateMembershipRequestViewModel from '../../viewModel/resource/CreateMembershipRequestViewModel';
import MembershipResourceViewModel from '../../viewModel/resource/MembershipResourceViewModel';
import MembershipBusinessListResourcePagingResultViewModel from '../../viewModel/resource/MembershipBusinessListResourcePagingResultViewModel';
import MembershipBusinessResourceViewModel from '../../viewModel/resource/MembershipBusinessResourceViewModel';
import MembershipDocumentListResourcePagingResultViewModel from '../../viewModel/resource/MembershipDocumentListResourcePagingResultViewModel';
import MembershipOpenInvoiceListResourcePagingResultViewModel from '../../viewModel/resource/MembershipOpenInvoiceListResourcePagingResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getMembershipStatus(membershipKey: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-status'] ?? 'Memberships/Status/{membershipKey}';
    endpointPath = endpointPath.replace(/\{membershipKey\}/g, `${membershipKey}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new MembershipStatusResourceViewModel().fromDTO(dto);
  },
  /**  */
  async calculateMembership(businessUnitId: string, categoryId: number, request: CalculateMembershipRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-calculate-membership'] ?? 'Memberships/Calculate/BusinessUnit/{businessUnitId}/Category/{categoryId}';
    endpointPath = endpointPath.replace(/\{businessUnitId\}/g, `${businessUnitId}`);
    endpointPath = endpointPath.replace(/\{categoryId\}/g, `${categoryId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        isMembershipReEntry: requestDTO.isMembershipReEntry,
        postalCode: requestDTO.postalCode,
      },
      ...config,
    });
    const dto = response.data.result;
    return new BasketResourceViewModel().fromDTO(dto);
  },
  /**  */
  async calculateMembershipByEntryDate(membershipEntryDate: string, model: BasketResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-calculate-membership-by-entry-date'] ?? 'Memberships/CalculateBasket/{membershipEntryDate}';
    endpointPath = endpointPath.replace(/\{membershipEntryDate\}/g, `${membershipEntryDate}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new BasketResourceViewModel().fromDTO(dto);
  },
  /**  */
  async linkMembership(request: LinkMembershipRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-link-membership'] ?? 'Memberships/Link';
    const response = await service.post<any>(endpointPath, {
      query: {
        verificationCode: requestDTO.verificationCode,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getInitialMembershipRequest(request: GetInitialMembershipRequestRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-initial-membership-request'] ?? 'Memberships/GetInitialRequest';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new CreateMembershipRequestViewModel().fromDTO(dto);
  },
  /**  */
  async createMembership(model: CreateMembershipRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-membership'] ?? 'Memberships';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async getMembershipDetail(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-detail'] ?? 'Memberships/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new MembershipResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getBusinesses(id: number, request: GetBusinessesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-businesses'] ?? 'Memberships/{id}/Businesses';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new MembershipBusinessListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getBusinessDetail(id: number, businessId: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-business-detail'] ?? 'Memberships/{id}/Businesses/{businessId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{businessId\}/g, `${businessId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new MembershipBusinessResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getMembershipDocuments(id: number, request: GetMembershipDocumentsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-documents'] ?? 'Memberships/{id}/Documents';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new MembershipDocumentListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getMembershipOpenInvoices(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-open-invoices'] ?? 'Memberships/{id}/OpenInvoices';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new MembershipOpenInvoiceListResourcePagingResultViewModel().fromDTO(dto);
  },
});
