// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateDynamicFormFieldRequestModel from '../../model/create-dynamic-form-field-request-model';
import CreateDynamicFormFieldRequestDTO from '../dto/create-dynamic-form-field-request-dto';
import dtoSchema from '../schemas/create-dynamic-form-field-request-schema';

export default abstract class CreateDynamicFormFieldRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateDynamicFormFieldRequestDTO>) {
    super();
    if (data) {
      this.dynamicFormFieldDefinitionId = data.dynamicFormFieldDefinitionId;
      this.value = data.value ?? undefined;
      this.groupFields = data.groupFields?.map((i) => CreateDynamicFormFieldRequestModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {uuid}
  */
  dynamicFormFieldDefinitionId?: string;
  /**
  */
  value?: unknown;
  /**
  */
  groupFields?: CreateDynamicFormFieldRequestModel[];

  static toModel(dto: DeepPartial<CreateDynamicFormFieldRequestDTO>): CreateDynamicFormFieldRequestModel;
  static toModel(dto: DeepPartial<CreateDynamicFormFieldRequestDTO> | undefined | null): CreateDynamicFormFieldRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateDynamicFormFieldRequestDTO> | undefined | null): CreateDynamicFormFieldRequestModel | undefined {
    return dto ? new CreateDynamicFormFieldRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateDynamicFormFieldRequestModel> | CreateDynamicFormFieldRequestModel): CreateDynamicFormFieldRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      dynamicFormFieldDefinitionId: unwrapped.dynamicFormFieldDefinitionId,
      value: unwrapped.value,
      groupFields: unwrapped.groupFields,
    } as CreateDynamicFormFieldRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateDynamicFormFieldRequestModel, can not map to CreateDynamicFormFieldRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
