// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopBasketItemsListResourceViewModel from '../../../../viewModel/resource/ShopBasketItemsListResourceViewModel';
import ShopBasketItemsListResourceDTO from '../../dto/resource/ShopBasketItemsListResourceDTO';

export default abstract class ShopBasketItemsListResourceModel extends BaseModel<ShopBasketItemsListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get basketId() { return this.dto.basketId; }
  set basketId(value) { this.dto.basketId = value; }
  /**
  * @type {uuid}
  */
  get basketPublicId() { return this.dto.basketPublicId; }
  set basketPublicId(value) { this.dto.basketPublicId = value; }
  /**
  * @type {int64}
  */
  get shopArticleId() { return this.dto.shopArticleId; }
  set shopArticleId(value) { this.dto.shopArticleId = value; }
  /**
  * @type {double}
  */
  get shopArticleTaxId() { return this.dto.shopArticleTaxId; }
  set shopArticleTaxId(value) { this.dto.shopArticleTaxId = value; }
  /**
  * @type {double}
  */
  get taxRate() { return this.dto.taxRate; }
  set taxRate(value) { this.dto.taxRate = value; }
  /**
  */
  get taxName() { return this.dto.taxName; }
  set taxName(value) { this.dto.taxName = value; }
  /**
  */
  get taxShortName() { return this.dto.taxShortName; }
  set taxShortName(value) { this.dto.taxShortName = value; }
  /**
  */
  get articleNumber() { return this.dto.articleNumber; }
  set articleNumber(value) { this.dto.articleNumber = value; }
  /**
  * @type {int64}
  */
  get supplierId() { return this.dto.supplierId; }
  set supplierId(value) { this.dto.supplierId = value; }
  /**
  */
  get supplierArticleNumber() { return this.dto.supplierArticleNumber; }
  set supplierArticleNumber(value) { this.dto.supplierArticleNumber = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {int64}
  */
  get mainPictureId() { return this.dto.mainPictureId; }
  set mainPictureId(value) { this.dto.mainPictureId = value; }
  /**
  * @type {int32}
  */
  get quantity() { return this.dto.quantity; }
  set quantity(value) { this.dto.quantity = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get shortDescription() { return this.dto.shortDescription; }
  set shortDescription(value) { this.dto.shortDescription = value; }
  /**
  * @type {double}
  */
  get totalNetPrice() { return this.dto.totalNetPrice; }
  set totalNetPrice(value) { this.dto.totalNetPrice = value; }
  /**
  * @type {double}
  */
  get totalGrossPrice() { return this.dto.totalGrossPrice; }
  set totalGrossPrice(value) { this.dto.totalGrossPrice = value; }
  /**
  * @type {double}
  */
  get totalTaxAmount() { return this.dto.totalTaxAmount; }
  set totalTaxAmount(value) { this.dto.totalTaxAmount = value; }
  /**
  * @type {double}
  */
  get unitGrossPrice() { return this.dto.unitGrossPrice; }
  set unitGrossPrice(value) { this.dto.unitGrossPrice = value; }
  /**
  * @type {double}
  */
  get unitNetPrice() { return this.dto.unitNetPrice; }
  set unitNetPrice(value) { this.dto.unitNetPrice = value; }
  /**
  * @type {double}
  */
  get taxAmount() { return this.dto.taxAmount; }
  set taxAmount(value) { this.dto.taxAmount = value; }
  /**
  */
  get hasShipping() { return this.dto.hasShipping; }
  set hasShipping(value) { this.dto.hasShipping = value; }
  /**
  */
  get isDeliveryCost() { return this.dto.isDeliveryCost; }
  set isDeliveryCost(value) { this.dto.isDeliveryCost = value; }
  /**
  */
  get hasLicense() { return this.dto.hasLicense; }
  set hasLicense(value) { this.dto.hasLicense = value; }
  /**
  */
  get isDownload() { return this.dto.isDownload; }
  set isDownload(value) { this.dto.isDownload = value; }
  /**
  * @type {int64}
  */
  get digitalAssetId() { return this.dto.digitalAssetId; }
  set digitalAssetId(value) { this.dto.digitalAssetId = value; }
  /**
  * @type {int64}
  */
  get crmItemPriceNumber() { return this.dto.crmItemPriceNumber; }
  set crmItemPriceNumber(value) { this.dto.crmItemPriceNumber = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.basketid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.basketId = value;
    this.dto.basketPublicId = qs.basketpublicid;
    value = Number.parseFloat(qs.shoparticleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopArticleId = value;
    value = Number.parseFloat(qs.shoparticletaxid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopArticleTaxId = value;
    value = Number.parseFloat(qs.taxrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxRate = value;
    this.dto.taxName = qs.taxname;
    this.dto.taxShortName = qs.taxshortname;
    this.dto.articleNumber = qs.articlenumber;
    value = Number.parseFloat(qs.supplierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.supplierId = value;
    this.dto.supplierArticleNumber = qs.supplierarticlenumber;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.mainpictureid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.mainPictureId = value;
    value = Number.parseFloat(qs.quantity);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.quantity = value;
    this.dto.description = qs.description;
    this.dto.shortDescription = qs.shortdescription;
    value = Number.parseFloat(qs.totalnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalNetPrice = value;
    value = Number.parseFloat(qs.totalgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalGrossPrice = value;
    value = Number.parseFloat(qs.totaltaxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalTaxAmount = value;
    value = Number.parseFloat(qs.unitgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitGrossPrice = value;
    value = Number.parseFloat(qs.unitnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitNetPrice = value;
    value = Number.parseFloat(qs.taxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxAmount = value;
    value = qs.hasShipping === 'true' ? true : qs.hasshipping;
    value = qs.hasShipping === 'false' ? false : undefined;
    this.dto.hasShipping = value;
    value = qs.isDeliveryCost === 'true' ? true : qs.isdeliverycost;
    value = qs.isDeliveryCost === 'false' ? false : undefined;
    this.dto.isDeliveryCost = value;
    value = qs.hasLicense === 'true' ? true : qs.haslicense;
    value = qs.hasLicense === 'false' ? false : undefined;
    this.dto.hasLicense = value;
    value = qs.isDownload === 'true' ? true : qs.isdownload;
    value = qs.isDownload === 'false' ? false : undefined;
    this.dto.isDownload = value;
    value = Number.parseFloat(qs.digitalassetid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.digitalAssetId = value;
    value = Number.parseFloat(qs.crmitempricenumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.crmItemPriceNumber = value;
  }
}
