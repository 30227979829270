<template>
  <div v-if="loading">
    <p-progress-linear class="my-4" indeterminate></p-progress-linear>
  </div>
  <div v-else-if="readonly || disabled">
    <p-row>
      <p-col xs12 sm10 md9 lg8>
        <gs-summary-display :value="innerValue" dense as-table></gs-summary-display>
      </p-col>
    </p-row>
  </div>
  <div v-else>
    <p-conditional-wrapper :show="innerValue.schema">
      <p-form v-if="value" :model="innerValue" no-summary>
        <p-request-alert v-model="errorResponse"></p-request-alert>
        <p-row v-if="hasCompany" align-center class="mb-3">
          <p-col v-if="hasCompanyExtended" xs12>
            <!-- Person/Firma - extended -->
            <gs-toggle-value
              v-model="innerValue.isCompany"
              :label="$t('memberPortal.createMembership.table.legalForm')"
              :caption-one="$t('memberPortal.createMembership.label.company')"
              :caption-two="$t('memberPortal.createMembership.label.person')"
            ></gs-toggle-value>
            <template v-if="innerValue.isCompany">
              <p-text-field
                v-model="innerValue.companyName"
                :required="companyNameRequired"
                autocomplete="organization"
                :label="computedCompanyNameLabel"
              ></p-text-field>
              <p-select
                v-model="innerValue.companyLegalFormId"
                :items="legalFormTypes"
                :label="$t('memberPortal.createMembership.table.legalForm')"
                required
                @display="innerValue.companyLegalFormDisplayValue = $event"
              ></p-select>
              <!-- Handelsregister -->
              <template v-if="hasCompanyExtended">
                <gs-toggle-value v-model="innerValue.isCompanyRegistered" :label="$t('memberPortal.createMembership.label.registeredCommercialRegister')"></gs-toggle-value>
                <template v-if="innerValue.isCompanyRegistered">
                  <p-text-field v-model="innerValue.companyRegisterNr" placeholder="CHE-123.123.123" :label="$t('memberPortal.createMembership.label.registerUID')"></p-text-field>
                </template>
              </template>
            </template>
          </p-col>
          <p-col v-else xs12>
            <!-- Nur Firma - simpel -->
            <p-text-field
              v-model="innerValue.companyName"
              autocomplete="organization"
              :label="computedCompanyNameLabel"
              :required="companyNameRequired"
            ></p-text-field>
          </p-col>
        </p-row>
        <template v-if="hasPersonalInfo">
          <p-row align-end class="salutation">
            <p-col xs12>
              <gs-toggle-value
                v-model="innerValue.salutation"
                :caption-one="$t('form.address.salutationEnum.0')"
                :caption-two="$t('form.address.salutationEnum.1')"
                :value-one="0"
                :value-two="1"
                :label="$t('form.address.salutation')"
              ></gs-toggle-value>
            <!-- <div>
          <input id="male" v-model="innerValue.salutationIsMale" type="radio" name="salutation" :value="true">
          <label class="ml-2" for="male">{{ $t('form.address.salutationEnum.0') }}</label><br>
        </div>
        <div>
          <input id="female" v-model="innerValue.salutationIsMale" type="radio" name="salutation" :value="false">
          <label class="ml-2" for="female">{{ $t('form.address.salutationEnum.1') }}</label><br>
        </div> -->
            </p-col>
          </p-row>
          <p-row>
            <p-col xs12>
              <p-text-field v-model="innerValue.firstName" name="fname" autocomplete="cc-given-name" :label="$t('form.address.firstName')" required></p-text-field>
            </p-col>
          </p-row>
          <p-row>
            <p-col xs12>
              <p-text-field v-model="innerValue.lastName" name="lname" autocomplete="cc-family-name" :label="$t('form.address.lastName')" required></p-text-field>
            </p-col>
          </p-row>
        </template>
        <p-row>
          <p-col xs9>
            <p-text-field v-model="innerValue.street" autocomplete="address-line1" :label="$t('form.address.street')" required></p-text-field>
          </p-col>
          <p-col xs3>
            <p-text-field v-model="innerValue.houseNumber" autocomplete="house-number" :label="$t('form.address.number')" required></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field v-model="innerValue.poBox" autocomplete="po-box" :label="$t('form.address.post')"></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field v-model="innerValue.addressLine1" :label="$t('form.address.additionalAddress')" autocomplete="address-line2"></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs3>
            <p-text-field v-model="innerValue.postalCode" :disabled="disablePostalCode" autocomplete="postal-code" :label="$t('form.address.plz')" required></p-text-field>
          </p-col>
          <p-col xs9>
            <p-text-field v-model="innerValue.city" :disabled="disableCity" autocomplete="address-level2" :label="$t('form.address.place')" required></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <gs-country-lookup v-model="innerValue.countryIso2" autocomplete="country" :label="$t('form.address.country')" required></gs-country-lookup>
          </p-col>
        </p-row>
        <p-row v-if="hasEmail">
          <p-col xs12>
            <p-text-field
              v-model="innerValue.eMail"
              type="email"
              name="email"
              autocomplete="email"
              :label="$t('form.address.email')"
              :required="emailRequired"
              :disabled="disabledEmail"
            ></p-text-field>
          </p-col>
        </p-row>
        <p-row v-if="hasTelephone">
          <p-col xs12>
            <p-phone-field
              v-model="innerValue.phoneNumber"
              autocomplete="tel"
              :label="$t('form.address.phone')"
            ></p-phone-field>
          </p-col>
        </p-row>
        <p-row v-if="hasMobile">
          <p-col xs12>
            <p-phone-field
              v-model="innerValue.mobilePhoneNumber"
              autocomplete="mobile"
              :label="$t('form.address.mobile')"
            ></p-phone-field>
          </p-col>
        </p-row>
        <p-row v-if="hasWebsite">
          <p-col xs12>
            <p-text-field
              v-model="innerValue.website"
              icon-right="public"
              autocomplete="url"
              :label="$t('form.address.webAddress')"
            ></p-text-field>
          </p-col>
        </p-row>
        <p-row v-if="hasBirthdate">
          <p-col xs12>
            <p-date-field
              v-model="innerValue.dateOfBirth"
              :min="$date.now().subtract(150, 'years').format('YYYY-MM-DD')"
              :max="$date.now().subtract(16, 'years').format('YYYY-MM-DD')"
              autocomplete="bday"
              :label="$t('form.address.birthDate')"
              :required="birthdateRequired"
            ></p-date-field>
          </p-col>
        </p-row>
      </p-form>
    </p-conditional-wrapper>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: { type: Object, default: () => undefined },
      readonly: { type: Boolean, default: false },
      companyNameLabel: { type: String, default: undefined },
      hasPersonalInfo: { type: Boolean, default: false },
      hasWebsite: { type: Boolean, default: false },
      hasCompany: { type: Boolean, default: false },
      hasCompanyExtended: { type: Boolean, default: false },
      hasBirthdate: { type: Boolean, default: false },
      hasEmail: { type: Boolean, default: false },
      hasTelephone: { type: Boolean, default: false },
      hasMobile: { type: Boolean, default: false },
      emailRequired: { type: Boolean, default: false },
      companyNameRequired: { type: Boolean, default: false },
      birthdateRequired: { type: Boolean, default: false },
      disabledEmail: { type: Boolean, default: false },
      disablePostalCode: { type: Boolean, default: false },
      disableCity: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
    },
    data: () => ({
      legalFormTypes: [] as any,
      errorResponse: undefined as any,
    }),
    computed: {
      computedCompanyNameLabel(): string {
        return this.companyNameLabel ?? this.$t('form.address.company');
      },
      innerValue: {
        get(): any {
          return this.value?.address ?? this.value;
        },
        set(value: any) {
          this.$emit('input', value);
        },
      },
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          const value = this.value as any;
          this.$withoutWatchers(() => {
            if (value && !this.disabled && !this.readonly) {
              value.languageIso2 = value.languageIso2 ?? Vue.$translation.get();
              if (value.address) {
                value.address.languageIso2 = value.address.languageIso2 ?? Vue.$translation.get();
              }
            }
          });
        },
      },
    },
    async beforeMount() {
      try {
        const list = await this.$service.api.attributes.getLegalFormTypes();
        this.legalFormTypes = list.items;
      } catch (error) {
        this.errorResponse = error;
      }
    },
  });
</script>
