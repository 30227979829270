<template>
  <div v-if="loading">
    <p-progress-linear class="my-4" indeterminate></p-progress-linear>
  </div>
  <div v-else>
    <p-conditional-wrapper v-if="value" :show="!!innerValue.schema">
      <p-form :model="innerValue" no-summary>
        <p-request-alert v-model="errorResponse"></p-request-alert>
        <p-row>
          <p-col xs12>
            <p-text-field
              v-model="innerValue.company"
              autocomplete="organization"
              :label="$t('form.address.company')"
              :disabled="readonly || disabled"
              :required="_.isSet(innerValue.company)"
            ></p-text-field>
          </p-col>
        </p-row>
        <p-row align-end class="salutation">
          <p-col xs12>
            <gs-toggle-value
              v-model="innerValue.salutation"
              :required="isNameValidationRequired"
              :caption-one="$t('form.address.salutationEnum.0')"
              :caption-two="$t('form.address.salutationEnum.1')"
              :value-one="0"
              :value-two="1"
              :label="$t('form.address.salutation')"
              :disabled="(readonly || disabled || salutationDisabled) && salutationDisabled !== false"
            ></gs-toggle-value>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field
              v-model="innerValue.firstName"
              :disabled="(readonly || disabled || firstNameDisabled) && firstNameDisabled !== false"
              name="fname"
              autocomplete="cc-given-name"
              :label="$t('form.address.firstName')"
              :required="isNameValidationRequired"
            ></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field
              v-model="innerValue.lastName"
              :disabled="(readonly || disabled || lastNameDisabled) && lastNameDisabled !== false"
              name="lname"
              autocomplete="cc-family-name"
              :label="$t('form.address.lastName')"
              :required="isNameValidationRequired"
            ></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs9>
            <p-text-field v-model="innerValue.street" :disabled="readonly || disabled" autocomplete="address-line1" :label="$t('form.address.street')" required></p-text-field>
          </p-col>
          <p-col xs3>
            <p-text-field v-model="innerValue.houseNumber" :disabled="readonly || disabled" autocomplete="house-number" :label="$t('form.address.number')"></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field v-model="innerValue.poBox" :disabled="readonly || disabled" autocomplete="po-box" :label="$t('form.address.post')"></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field v-model="innerValue.additionalLine" :disabled="readonly || disabled" :label="$t('form.address.additionalAddress')" autocomplete="address-line2"></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs3>
            <p-text-field v-model="innerValue.postalCode" :disabled="readonly || disabled" autocomplete="postal-code" :label="$t('form.address.plz')" required></p-text-field>
          </p-col>
          <p-col xs9>
            <p-text-field v-model="innerValue.city" :disabled="readonly || disabled" autocomplete="address-level2" :label="$t('form.address.place')" required></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <gs-shop-country-lookup v-model="innerValue.countryIso2" :disabled="readonly || disabled" autocomplete="country" :label="$t('form.address.country')" required></gs-shop-country-lookup>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-text-field
              v-model="innerValue.eMail"
              type="email"
              name="email"
              autocomplete="email"
              :label="$t('form.address.email')"
              required
              :disabled="(readonly || disabled || emailDisabled) && emailDisabled !== false"
            ></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-phone-field
              v-model="innerValue.phone"
              :disabled="!phoneInvalid && (readonly || disabled)"
              autocomplete="tel"
              :label="$t('form.address.phone')"
            ></p-phone-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12>
            <p-phone-field
              v-model="innerValue.mobilePhone"
              :disabled="!mobilePhoneInvalid && (readonly || disabled)"
              autocomplete="mobile"
              :label="$t('form.address.mobile')"
            ></p-phone-field>
          </p-col>
        </p-row>
      </p-form>
    </p-conditional-wrapper>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: { type: Object, default: () => undefined },
      readonly: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      firstNameDisabled: { type: Boolean, default: undefined },
      lastNameDisabled: { type: Boolean, default: undefined },
      salutationDisabled: { type: Boolean, default: undefined },
      emailDisabled: { type: Boolean, default: undefined },
      isDeliveryAddress: { type: Boolean, default: false },
      isNameRequired: { type: Boolean, default: false },
    },
    data: () => ({
      legalFormTypes: [] as any,
      phoneInvalid: false,
      mobilePhoneInvalid: false,
      errorResponse: undefined as any,
    }),
    computed: {
      innerValue: {
        get(): any {
          return this.value?.address ?? this.value;
        },
        set(value: any) {
          this.$emit('input', value);
        },
      },
      isNameValidationRequired: {
        get(): boolean {
          if (this.isDeliveryAddress) {
            return !this._.isSet(this.innerValue.company) || this.isNameRequired;
          }
          return true;
        },
      },
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          const value = this.value as any;
          this.phoneInvalid = !this.$format.phoneValid(value.phone);
          if (!this.phoneInvalid)value.phone = this.$format.phoneData(value.phone)
          this.mobilePhoneInvalid = !this.$format.phoneValid(value.mobilePhone);
          if (!this.mobilePhoneInvalid)value.mobilePhone = this.$format.phoneData(value.mobilePhone)
          this.$withoutWatchers(() => {
            if (value && !this.disabled && !this.readonly) {
              value.languageIso2 = value.languageIso2 ?? Vue.$translation.get();
              if (value.address) {
                value.address.languageIso2 = value.address.languageIso2 ?? Vue.$translation.get();
              }
            }
          });
        },
      },
    },
    async beforeMount() {
      if (this.innerValue) {
        this.innerValue.isCompany = false;
      }
    },
  });
</script>
