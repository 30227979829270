import Vue from 'vue';
import ContactAddressResourceModel from '../../generated/types/model/resource/ContactAddressResourceModel';
import utils from '../utils';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import RequestSummarySectionResourceViewModel from './RequestSummarySectionResourceViewModel';

type ContactAddressResourceSummaryOptions = {
  hideLanguageIso2?: boolean,
};
export default class ContactAddressResourceViewModel extends ContactAddressResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get companyLegalFormDisplayValue() { return (this.dto as any).companyLegalFormDisplayValue; }
  set companyLegalFormDisplayValue(value) { (this.dto as any).companyLegalFormDisplayValue = value; }

  get contactHasChanged() {
    const isSame = this.dto.isCompany === this.originalDto.isCompany
    // Email & language is set programatically and should not be considered as "user-changed"
    // && this.dto.eMail === this.originalDto.eMail
    // && this.dto.languageIso2 === this.originalDto.languageIso2
    && this.dto.isCompanyRegistered === this.originalDto.isCompanyRegistered
    && this.dto.companyRegisterNr === this.originalDto.companyRegisterNr
    && this.dto.companyLegalFormId === this.originalDto.companyLegalFormId
    && this.dto.companyName === this.originalDto.companyName
    && this.dto.salutation === this.originalDto.salutation
    && this.dto.firstName === this.originalDto.firstName
    && this.dto.lastName === this.originalDto.lastName
    && this.dto.dateOfBirth === this.originalDto.dateOfBirth
    && this.dto.phoneNumber === this.originalDto.phoneNumber
    && this.dto.mobilePhoneNumber === this.originalDto.mobilePhoneNumber
    && this.dto.street === this.originalDto.street
    && this.dto.houseNumber === this.originalDto.houseNumber
    && this.dto.addressLine1 === this.originalDto.addressLine1
    && this.dto.poBox === this.originalDto.poBox
    && this.dto.postalCode === this.originalDto.postalCode
    && this.dto.city === this.originalDto.city
    && this.dto.countryIso2 === this.originalDto.countryIso2
    && this.dto.website === this.originalDto.website;

    return !isSame;
  }

  get salutationDisplay() { return Vue.$t(`form.address.salutationEnum.${this.salutation!}`); }

  get summary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromModel({
      sections: [
        new RequestSummarySectionResourceViewModel().fromModel({
          items: [...this.getSummaryItems()],
        }),
      ],
    });
  }

  getSummaryItems(options?: ContactAddressResourceSummaryOptions): RequestSummaryItemResourceViewModel[] {
    return [
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(this.isCompany! && this.isCompanyRegistered!, {
          label: Vue.$t('memberPortal.createMembership.label.registerUID'),
          displayValue: this.companyRegisterNr,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(this.isCompany!, {
          label: Vue.$t('memberPortal.createMembership.table.legalForm'),
          displayValue: this.companyLegalFormDisplayValue,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.companyName, {
          label: Vue.$t('form.address.company'),
          displayValue: this.companyName,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(Vue._.isSet(this.salutation), {
          label: Vue.$t('form.address.salutation'),
          displayValue: this.salutationDisplay,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.firstName'),
        displayValue: this.firstName,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.lastName'),
        displayValue: this.lastName,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.street, {
          label: Vue.$t('form.address.address'),
          displayValue: `${this.street} ${this.houseNumber ?? ''}`,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.additionalAddress'),
        displayValue: this.addressLine1,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.post'),
        displayValue: this.poBox,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!!this.postalCode, {
          label: `${Vue.$t('form.address.plz')} / ${Vue.$t('form.address.place')}`,
          displayValue: `${this.postalCode} ${this.city}`,
        }),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.country'),
        displayValue: this.countryIso2,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.phone'),
        displayValue: Vue.$format.phonePretty(this.phoneNumber!),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.mobile'),
        displayValue: Vue.$format.phonePretty(this.mobilePhoneNumber!),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.email'),
        displayValue: this.eMail,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.memberData.table.website'),
        displayValue: this.website,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('form.address.birthDate'),
        displayValue: Vue.$format.localDate(this.dateOfBirth!),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        ...utils.insertIf(!options?.hideLanguageIso2!, {
          label: Vue.$t('memberPortal.createMembership.table.correspondenceLanguage'),
          displayValue: this.languageIso2?.toUpperCase(),
        }),
      }),
    ].filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null);
  }
}
