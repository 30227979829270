<template>
  <p-form-element>
    <p-select
      :value="value"
      :items="items"
      v-bind="$props"
      @input="$emit('input', $event)"
      v-on="$listeners"
    >
    </p-select>
  </p-form-element>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: { default: undefined, type: undefined },
      disabled: { default: false, type: Boolean },
      readonly: { default: false, type: Boolean },
      solo: { default: false, type: Boolean },
      placeholder: { default: undefined, type: String },
      label: { default: undefined, type: String },
    },
    data: () => ({
      items: [
        { caption: 'CH', id: 'CH' },
        { caption: 'DE', id: 'DE' },
        { caption: 'AT', id: 'AT' },
        { caption: 'IT', id: 'IT' },
        { caption: 'FR', id: 'FR' },
      ],
    }),
    mounted() {
      if (!this.value) {
        this.$emit('input', 'CH');
      }
    },
  });
</script>
