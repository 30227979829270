import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

export default class DynamicFormFieldFileResourceViewModel extends BaseModel<any> {
  protected transformFromDTO(): void {}
  protected transformToDTO(): void {}

  get fileName() { return this.dto.fileName; }
  set fileName(value) { this.dto.fileName = value; }
  get base64FileContent() { return this.dto.base64FileContent; }
  set base64FileContent(value) { this.dto.base64FileContent = value; }

  fromFile(file: File): Promise<this> {
    return new Promise((resolve) => {
      if (!file) {
        resolve(this);
        return;
      }
      this.fileName = file?.name;
      const reader = new window.FileReader();
      reader.onload = () => {
        this.base64FileContent = reader.result;
        resolve(this);
      };
      reader.onabort = () => {
        resolve(this);
      };
      reader.onerror = (error) => {
        console.error(`[Dynamic-form] Unable to convert File to base64 representation ${error}`);
        resolve(this);
      };
      reader.readAsDataURL(file);
    });
  }
}
