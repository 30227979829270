<template>
  <p-form-element
    show-messages
    :errors="innerErrors"
    :messages="innerMessages"
    :class="{
      'gs-toggle-value': true,
      'required': innerRequired
    }"
  >
    <span v-if="innerLabel" :id="'toggle-value-label-' + _uid" class="toggle-label" role="label">
      {{ innerLabel }}
    </span>
    <p-row column dense role="listbox" :aria-labelledby="'toggle-value-label-' + _uid">
      <p-row class="gs-toggle-values-wrapper">
        <p-radio-field
          v-if="removeOptionOne === false"
          v-model="computedValue"
          :disabled="disabled || disableOptionOne"
          role="option"
          :aria-selected="oneChecked"
          :checked="oneChecked"
          :name="groupName"
          :true-value="valueOne"
          :label="$tAlt(captionOne, captionOne)"
        ></p-radio-field>
        <p-radio-field
          v-if="removeOptionTwo === false"
          v-model="computedValue"
          :disabled="disabled || disableOptionTwo"
          class="ml-2"
          role="option"
          :aria-selected="twoChecked"
          :checked="twoChecked"
          :name="groupName"
          :true-value="valueTwo"
          :label="$tAlt(captionTwo, captionTwo)"
        ></p-radio-field>
      </p-row>
    </p-row>
  </p-form-element>
</template>

<script lang="ts">
  import Vue from 'vue';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';

  export default Vue.extend({
    mixins: [validatable],
    props: {
      disabled: { type: Boolean, default: false },
      label: { type: String, default: undefined },
      value: { type: undefined, default: undefined },
      valueOne: { type: undefined, default: true },
      valueTwo: { type: undefined, default: false },
      captionOne: { type: String, default: 'app.yes' },
      captionTwo: { type: String, default: 'app.no' },
      removeOptionOne: { type: undefined, default: false },
      removeOptionTwo: { type: undefined, default: false },
      disableOptionOne: { type: Boolean, default: false },
      disableOptionTwo: { type: Boolean, default: false },
    },
    data: () => ({
      oneChecked: false,
      twoChecked: false,
    }),
    computed: {
      computedValue: {
        get(): any {
          return this.value;
        },
        set(value: any): void {
          this.$emit('input', value);
        },
      },
      groupName(): string {
        // eslint-disable-next-line no-underscore-dangle
        const label = `${this.label}_${(this as any)._uid.toString()}`;
        return label.replace(/\s/g, '-');
      },
    },
    watch: {
      removeOptionOne: {
        immediate: true,
        handler(newValue: boolean) {
          if (newValue) {
            this.$emit('input', this.valueTwo);
          }
        },
      },
      removeOptionTwo: {
        immediate: true,
        handler(newValue: boolean) {
          if (newValue) {
            this.$emit('input', this.valueOne);
          }
        },
      },
      value: {
        immediate: true,
        handler() {
          if (this.value === this.valueOne) {
            this.oneChecked = true;
            this.twoChecked = false;
          } else if (this.value === this.valueTwo) {
            this.oneChecked = false;
            this.twoChecked = true;
          }
        },
      },
    },
  });
</script>

<style lang="scss" scoped>
  .toggle-label{
    display: block;
    font-size: 12px;
    cursor: pointer;
  }
</style>
