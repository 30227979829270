// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactAddressSchema from './contact-address-schema';

const schema = {
  $id: '/correspondence-contact-schema',
  type: 'object',
  required: [],
  properties: {
    customerNumber: {
      type: 'integer',
      nullable: true,
    },
    contactId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    parentContactId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    languageIso2: {
      type: 'string',
      nullable: true,
    },
    address: {
      ...ContactAddressSchema,
    },
    isEqualToDefaultAddress: {
      type: 'boolean',
    },
    isTrusteeAddress: {
      type: 'boolean',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
