// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/e-surance-membership-schema',
  type: 'object',
  required: ['isReEntry', 'entryDate', 'businessPostalCode', 'businessPostalCodeAddition', 'businessCity', 'payrollCategoryKey', 'payrollCategoryName', 'socialInsuranceTypeId', 'socialInsuranceTypeName'],
  properties: {
    isReEntry: {
      type: 'boolean',
    },
    entryDate: {
      type: 'string',
      format: 'date-time',
    },
    businessPostalCode: {
      type: 'string',
      minLength: 1,
    },
    businessPostalCodeAddition: {
      type: 'string',
      minLength: 1,
    },
    businessCity: {
      type: 'string',
      minLength: 1,
    },
    payrollCategoryKey: {
      type: 'integer',
    },
    payrollCategoryName: {
      type: 'string',
      minLength: 1,
    },
    socialInsuranceTypeId: {
      type: 'string',
      format: 'uuid',
    },
    socialInsuranceTypeName: {
      type: 'string',
      minLength: 1,
    },
  },
} as IJSONSchema;

export default schema;
