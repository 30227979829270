import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import DocumentsApi from '../generated/api/Documents';
import UploadDocumentRequestViewModel from '../viewModel/request/Documents/UploadDocumentRequestViewModel';

export default (service: Servicelayer) => ({
  ...DocumentsApi(service),

  UploadMultipleDocuments(files: File[] | Blob[]) {
    const allPromises = [] as Promise<string>[];
    files.forEach((file) => {
      const request = new UploadDocumentRequestViewModel();
      request.uploadFile = file as File;
      const promise = this.uploadDocument(request);
      allPromises.push(promise as Promise<string>);
    });
    const ids = Promise.all(allPromises);
    return ids;
  },

});
