// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ESuranceGastronomyInfoModel from '../../model/e-surance-gastronomy-info-model';
import ESuranceGastronomyInfoDTO from '../dto/e-surance-gastronomy-info-dto';
import dtoSchema from '../schemas/e-surance-gastronomy-info-schema';

export default abstract class ESuranceGastronomyInfoModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ESuranceGastronomyInfoDTO>) {
    super();
    if (data) {
      this.hasGastronomy = data.hasGastronomy;
      this.gastronomyTypeId = data.gastronomyTypeId;
      this.gastronomyTypeName = data.gastronomyTypeName;
      this.countOfSeatsIndoor = data.countOfSeatsIndoor;
      this.countOfSeatsOutdoor = data.countOfSeatsOutdoor;
      this.countOfSeatsHall = data.countOfSeatsHall;
      this.isWheelchairAccessible = data.isWheelchairAccessible;
      this.isHandicappedRestroomAvailable = data.isHandicappedRestroomAvailable;
      this.hasSmokingRoom = data.hasSmokingRoom;
    }
  }
  /**
  */
  hasGastronomy?: boolean;
  /**
  * @type {uuid}
  */
  gastronomyTypeId?: string;
  /**
  * @minimum 1
  */
  gastronomyTypeName?: string;
  /**
  * @type {int32}
  */
  countOfSeatsIndoor?: number;
  /**
  * @type {int32}
  */
  countOfSeatsOutdoor?: number;
  /**
  * @type {int32}
  */
  countOfSeatsHall?: number;
  /**
  */
  isWheelchairAccessible?: boolean;
  /**
  */
  isHandicappedRestroomAvailable?: boolean;
  /**
  */
  hasSmokingRoom?: boolean;

  static toModel(dto: DeepPartial<ESuranceGastronomyInfoDTO>): ESuranceGastronomyInfoModel;
  static toModel(dto: DeepPartial<ESuranceGastronomyInfoDTO> | undefined | null): ESuranceGastronomyInfoModel | undefined;
  static toModel(dto: DeepPartial<ESuranceGastronomyInfoDTO> | undefined | null): ESuranceGastronomyInfoModel | undefined {
    return dto ? new ESuranceGastronomyInfoModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ESuranceGastronomyInfoModel> | ESuranceGastronomyInfoModel): ESuranceGastronomyInfoDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      hasGastronomy: unwrapped.hasGastronomy,
      gastronomyTypeId: unwrapped.gastronomyTypeId,
      gastronomyTypeName: unwrapped.gastronomyTypeName,
      countOfSeatsIndoor: unwrapped.countOfSeatsIndoor,
      countOfSeatsOutdoor: unwrapped.countOfSeatsOutdoor,
      countOfSeatsHall: unwrapped.countOfSeatsHall,
      isWheelchairAccessible: unwrapped.isWheelchairAccessible,
      isHandicappedRestroomAvailable: unwrapped.isHandicappedRestroomAvailable,
      hasSmokingRoom: unwrapped.hasSmokingRoom,
    } as ESuranceGastronomyInfoDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ESuranceGastronomyInfoModel, can not map to ESuranceGastronomyInfoDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
