import Vue from 'vue';

export default () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
  }

  let deferredPrompt: any;

  // present install prompt to user
  function presentAddToHome() {
    deferredPrompt.prompt(); // Wait for the user to respond to the prompt
    deferredPrompt.userChoice
      .then((choice: { outcome: string; }) => {
        if (choice.outcome === 'accepted') {
          Vue.$store.values.showPwaInstallPromptFunction = undefined;
        } else {
          // console.log('User dismissed');
        }
      });
  }
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // store the pwa function in global storage for use in layout-xx files
    Vue.$store.values.showPwaInstallPromptFunction = presentAddToHome;
  });
};
