<template>
  <p-row align-end class="gs-add-to-basket">
    <p-number-field
      v-if="hasQuantity"
      v-model="quantity"
      :label="$t('shop.basket.quantity')"
      :min="1"
      class="ix-5"
      :solo="solo"
    ></p-number-field>
    <p-button :disabled="!_.isSet(quantity) || quantity < 1" :loading="isLoading" :stretch="stretch" @click.stop="addToBasket(quantity)">
      <p-icon left>
        add_shopping_cart
      </p-icon>
      <span>{{ $t('shop.basket.addToBasket') }}</span>
    </p-button>
  </p-row>
</template>

<script lang="ts">
  import AddShopBasketItemRequestViewModel from '@/src/services/v1/viewModel/resource/AddShopBasketItemRequestViewModel';
  import pNumberField from '@glittr/frontend-core/src/components/p-number-field/p-number-field.vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsAddToBasket',
    components: { pNumberField },
    props: {
      hasQuantity: { type: Boolean, default: false },
      stretch: { type: Boolean, default: false },
      articleId: { type: Number, default: undefined },
      solo: { type: Boolean, default: false },
    },
    data: () => ({
      quantity: 1,
      isLoading: false,
    }),
    methods: {
      async addToBasket(quantity: number = 1) {
        this.isLoading = true;
        const request = new AddShopBasketItemRequestViewModel();
        request.articleId = this.articleId;
        request.quantity = quantity;
        await this.$service.api.shopBasket.addToBasket(request);
        this.isLoading = false;
      },
    },
  });
</script>
