// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import CorrespondenceContactSchema from './correspondence-contact-schema';

const schema = {
  $id: '/membership-schema',
  type: 'object',
  required: ['payrollCategoryId', 'membershipTemplateNumber'],
  properties: {
    membershipNumber: {
      type: 'string',
      nullable: true,
    },
    entryDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    registrationDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    payrollCategoryId: {
      type: 'integer',
    },
    isActive: {
      type: 'boolean',
    },
    membershipTemplateNumber: {
      type: 'integer',
    },
    businessContact: {
      ...ContactSchema,
    },
    memberContact: {
      ...ContactSchema,
    },
    partnerContact: {
      ...ContactSchema,
    },
    billingContact: {
      ...CorrespondenceContactSchema,
    },
    debtorContact: {
      ...ContactSchema,
    },
    businessUnitContact: {
      ...ContactSchema,
    },
    regionContact: {
      ...ContactSchema,
    },
  },
} as IJSONSchema;

export default schema;
