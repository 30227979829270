import Vue from 'vue';
import AccommodationResourceModel from '../../generated/types/model/resource/AccommodationResourceModel';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';

export default class AccommodationResourceViewModel extends AccommodationResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get summaryItems(): RequestSummaryItemResourceViewModel[] {
    return [
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.createMembership.table.accommodationType'),
        displayValue: this.accommodationTypeName,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.createMembership.table.classified'),
        displayValue: this.isClassified ? Vue.$t('app.yes') : Vue.$t('app.no'),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.createMembership.table.numberOfStars'),
        displayValue: `${this.classificationStarsCount ?? 0}`,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.classifyHotel.label.numberOfRooms'),
        displayValue: `${this.classificationRoomCount ?? 0}`,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('memberPortal.classifyHotel.label.numberOfBeds'),
        displayValue: `${this.classificationBedCount ?? 0}`,
      }),
    ].filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null);
  }
}
