// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import DynamicFormResourceViewModel from '../../viewModel/resource/DynamicFormResourceViewModel';
import CreateDynamicFormRequestViewModel from '../../viewModel/resource/CreateDynamicFormRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getDynamicFormById(id: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-dynamic-form-by-id'] ?? 'DynamicForms/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DynamicFormResourceViewModel().fromDTO(dto);
  },
  /**  */
  async createDynamicForm(model: CreateDynamicFormRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-dynamic-form'] ?? 'DynamicForms';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
});
