// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetInitialHoklaRequestRequestViewModel from '../../viewModel/request/HotelClassifications/GetInitialHoklaRequestRequestViewModel';
import HotelClassificationBusinessListResourceListResultViewModel from '../../viewModel/resource/HotelClassificationBusinessListResourceListResultViewModel';
import CreateHotelClassificationRequestViewModel from '../../viewModel/resource/CreateHotelClassificationRequestViewModel';
import GuidLookupResourceListResultViewModel from '../../viewModel/resource/GuidLookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getHotelClassificationBusinesses(userId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-hotel-classification-businesses'] ?? 'HotelClassifications/{userId}/Businesses';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new HotelClassificationBusinessListResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async addHotelClassification(model: CreateHotelClassificationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-hotel-classification'] ?? 'HotelClassifications';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getInitialHoklaRequest(request: GetInitialHoklaRequestRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-initial-hokla-request'] ?? 'HotelClassifications/GetInitialRequest';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new CreateHotelClassificationRequestViewModel().fromDTO(dto);
  },
  /**  */
  async getAccommodationTypeItems(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-accommodation-type-items'] ?? 'HotelClassifications/AccommodationTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new GuidLookupResourceListResultViewModel().fromDTO(dto);
  },
});
