<template>
  <p-img
    :src="src"
    :height="height"
    :width="width"
    :ratio="ratio"
    :contain="cover ? false : contain"
    :cover="cover"
    :to="to"
    :class="classList"
  >
    <template #loading>
      <p-surface layer="3">
        <p-container fill-height>
          <p-row fill-height align-center>
            <p-progress-linear indeterminate color="surface-5" height="3"></p-progress-linear>
          </p-row>
        </p-container>
      </p-surface>
    </template>
    <template #error>
      <p-img
        :src="require('@src/assets/article-placeholder.png')"
        :height="height"
        :width="width"
        :ratio="ratio"
        :contain="cover ? false : contain"
        :cover="cover"
        :to="to"
        :class="classList"
      ></p-img>
    </template>
  </p-img>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsArticleImage',
    props: {
      imageId: { type: String, default: undefined },
      height: { type: [String, Number], default: undefined },
      width: { type: [String, Number], default: undefined },
      ratio: { type: [String, Number], default: undefined },
      contain: { type: Boolean, default: true },
      cover: { type: Boolean, default: false },
      to: { type: String, default: undefined },
    },
    data: () => ({

    }),
    computed: {
      classList(): Record<string, boolean> {
        return {
          'gs-article-image': true,
          'article-image': true,
          'hover-effect': !!this.to,
        };
      },
      src(): string {
        const configValueName = 'gs-article-images-path';
        let configPath = this.$config.values[configValueName];
        if (configPath) {
          if (configPath[configPath.length - 1] !== '/') {
            configPath += '/';
          }
          return `${configPath + this.imageId}.jpg`;
        }
        throw new Error(`No article image path configured, please set "${configValueName}" in the config`);
      },
    },
  });
</script>
