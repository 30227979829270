// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDigitalAssetsRequestModel from '../../model/get-digital-assets-request-model';
import GetDigitalAssetRequestModel from '../../model/get-digital-asset-request-model';
import UpdateDigitalAssetRequestModel from '../../model/update-digital-asset-request-model';
import DeleteDigitalAssetRequestModel from '../../model/delete-digital-asset-request-model';
import UploadDigitalAssetRequestModel from '../../model/upload-digital-asset-request-model';
import DigitalAssetFileNameByIdRequestModel from '../../model/digital-asset-file-name-by-id-request-model';
import AdminDownloadAssetRequestModel from '../../model/admin-download-asset-request-model';
import DigitalAssetPagingResultModel from '../../model/digital-asset-paging-result-model';
import DigitalAssetWithCountAndFileNameModel from '../../model/digital-asset-with-count-and-file-name-model';

export default (service: Servicelayer) => ({
  /**  */
  async getDigitalAssets(request: GetDigitalAssetsRequestModel, config?: RequestConfig) {
    const requestDTO = GetDigitalAssetsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-digital-assets'] ?? 'DigitalAsset';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        filename: requestDTO.filename,
        extension: requestDTO.extension,
        isAssigned: requestDTO.isAssigned,
        isSold: requestDTO.isSold,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DigitalAssetPagingResultModel.toModel(dto));
  },
  /**  */
  async getDigitalAsset(request: GetDigitalAssetRequestModel, config?: RequestConfig) {
    const requestDTO = GetDigitalAssetRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-digital-asset'] ?? 'DigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DigitalAssetWithCountAndFileNameModel.toModel(dto));
  },
  /**  */
  async updateDigitalAsset(request: UpdateDigitalAssetRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateDigitalAssetRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-digital-asset'] ?? 'DigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      query: {
        fileName: requestDTO.fileName,
      },
      ...config,
    });
  },
  /**  */
  async deleteDigitalAsset(request: DeleteDigitalAssetRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteDigitalAssetRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-digital-asset'] ?? 'DigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async uploadDigitalAsset(request: UploadDigitalAssetRequestModel, config?: RequestConfig) {
    const requestDTO = UploadDigitalAssetRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-digital-asset'] ?? 'DigitalAsset/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      query: {
        name: requestDTO.name,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async digitalAssetFileNameById(request: DigitalAssetFileNameByIdRequestModel, config?: RequestConfig) {
    const requestDTO = DigitalAssetFileNameByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-digital-asset-file-name-by-id'] ?? 'DigitalAsset/DigitalAssetFileNameById/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async adminDownloadAsset(request: AdminDownloadAssetRequestModel, config?: RequestConfig) {
    const requestDTO = AdminDownloadAssetRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-admin-download-asset'] ?? 'DigitalAsset/DownloadAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
