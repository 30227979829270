// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateBasketItemRequestModel from '../../model/update-basket-item-request-model';
import UpdateBasketItemRequestDTO from '../dto/update-basket-item-request-dto';
import dtoSchema from '../schemas/update-basket-item-request-schema';

export default abstract class UpdateBasketItemRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateBasketItemRequestDTO>) {
    super();
    if (data) {
      this.articleId = data.articleId;
      this.quantity = data.quantity;
      this.id = data.id;
      this.userId = data.userId;
      this.publicBasketId = data.publicBasketId;
    }
  }
  /**
  * @type {int64}
  */
  articleId?: number;
  /**
  * @type {int32}
  */
  quantity?: number;
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  * @type {uuid}
  */
  publicBasketId?: string;

  static toModel(dto: DeepPartial<UpdateBasketItemRequestDTO>): UpdateBasketItemRequestModel;
  static toModel(dto: DeepPartial<UpdateBasketItemRequestDTO> | undefined | null): UpdateBasketItemRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateBasketItemRequestDTO> | undefined | null): UpdateBasketItemRequestModel | undefined {
    return dto ? new UpdateBasketItemRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateBasketItemRequestModel> | UpdateBasketItemRequestModel): UpdateBasketItemRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      articleId: unwrapped.articleId,
      quantity: unwrapped.quantity,
      id: unwrapped.id,
      userId: unwrapped.userId,
      publicBasketId: unwrapped.publicBasketId,
    } as UpdateBasketItemRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateBasketItemRequestModel, can not map to UpdateBasketItemRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
