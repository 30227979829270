import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ShopArticleTaxesApi from '../generated/api/ShopArticleTaxes';

export default (service: Servicelayer) => ({
  ...ShopArticleTaxesApi(service),

  // Add custom calls here

});
