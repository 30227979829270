<template>
  <div>
    <div v-if="tableDisplay">
      <p-table dense>
        <template #rows>
          <template v-for="(item, i) in listItems">
            <tr v-if="!_.isEmpty(item) && !item.divider" :key="i">
              <td>
                {{ item.label }}
              </td>
              <td>
                {{ item.value }}
              </td>
            </tr>
          </template>
        </template>
      </p-table>
    </div>
    <div v-else class="smaller-text">
      <div v-for="(item, i) in listItems" :key="i">
        <template v-if="item.divider">
          <p-divider class="ix-6 my-3"></p-divider>
        </template>
        <template v-else>
          <span v-if="!item.labelOptional && item.label">
            {{ item.label }}:
          </span>
          <span>
            {{ item.value }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import ContactResourceViewModel from '../services/v1/viewModel/resource/ContactResourceViewModel';
  import utils from '../services/v1/viewModel/utils';

  export default Vue.extend({
    props: {
      value: { type: Object, default: () => ({}) },
      tableDisplay: { type: Boolean, default: false },
      showLanguage: { type: Boolean, default: false },
      notDifferentText: { type: String, default: undefined },
      showCustomerNumber: { type: Boolean, default: false },
    },
    computed: {
      listItems(): {label?: string, value?: string, divider?: boolean, labelOptional?: boolean}[] {
        if (this.ifIsNotDifferent) {
          if (this.notDifferentText) {
            return [{ value: this.notDifferentText }];
          }
          return [{ value: this.$t('form.address.notDifferent') }];
        }

        return [
          {
            ...utils.insertIf(!!this.value.customerNumber && this.showCustomerNumber, {
              label: this.$t('memberPortal.memberData.table.memberNumber'),
              value: this.value.customerNumber,
            }),
          },
          {
            ...utils.insertIf(!!this.value.customerNumber && this.showCustomerNumber, {
              divider: true,
            }),
          },
          {
            ...utils.insertIf(!!this.language && this.showLanguage, {
              label: this.$t('memberPortal.memberData.table.language'),
              value: this.language,
            }),
          },
          {
            ...utils.insertIf(!!this.isTrusteeDisplay, {
              label: this.$t('memberPortal.memberData.table.isTrustee'),
              value: this.isTrusteeDisplay,
            }),
          },
          {
            ...utils.insertIf((!!this.language && this.showLanguage) || !!this.isTrusteeDisplay, {
              divider: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.companyName, {
              label: this.$t('form.address.company'),
              value: this.address.companyName,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.companyLegalFormDisplayValue, {
              label: this.$t('memberPortal.createMembership.table.legalForm'),
              value: this.address.companyLegalFormDisplayValue,
            }),
          },
          {
            ...utils.insertIf(!!this.address.isCompanyRegistered, {
              label: this.$t('memberPortal.createMembership.label.registerUID'),
              value: this.address.companyRegisterNr,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(Vue._.isSet(this.salutation), {
              label: this.$t('form.address.salutation'),
              value: this.salutation,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.firstName, {
              label: this.$t('form.address.name'),
              value: `${this.address.firstName} ${this.address.lastName}`,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.street, {
              label: this.$t('form.address.street'),
              value: `${this.address.street} ${this.address.houseNumber}`,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.addressLine1, {
              label: this.$t('form.address.additionalAddress'),
              value: this.address.addressLine1,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.poBox, {
              label: this.$t('form.address.post'),
              value: this.address.poBox,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.postalCode, {
              label: `${this.$t('form.address.plz')} / ${this.$t('form.address.place')}`,
              value: `${this.address.postalCode} ${this.address.city}`,
              labelOptional: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.phoneNumber || !!this.address.mobilePhoneNumber || !!this.address.eMail || !!this.address.website || !!this.address.dateOfBirth, {
              divider: true,
            }),
          },
          {
            ...utils.insertIf(!!this.address.phoneNumber, {
              label: this.$t('form.address.phone'),
              value: this.$format.phonePretty(this.address.phoneNumber!),
            }),
          },
          {
            ...utils.insertIf(!!this.address.mobilePhoneNumber, {
              label: this.$t('form.address.mobile'),
              value: this.$format.phonePretty(this.address.mobilePhoneNumber!),
            }),
          },
          {
            ...utils.insertIf(!!this.address.eMail, {
              label: this.$t('form.address.email'),
              value: this.address.eMail,
            }),
          },
          {
            ...utils.insertIf(!!this.address.website, {
              label: this.$t('form.address.webAddress'),
              value: this.address.website,
            }),
          },
          {
            ...utils.insertIf(!!this.address.dateOfBirth, {
              label: this.$t('memberPortal.memberData.table.birthDate'),
              value: this.$format.localDate(this.address.dateOfBirth!),
            }),
          },
        ];
      },
      language(): string | undefined {
        const value = this.value as any ?? {};
        return value.languageIso2;
      },
      isTrusteeDisplay(): string | undefined {
        const value = this.value as any ?? {};
        if (value.isTrusteeAddress === true) {
          return this.$t('app.yes');
        } if (value.isTrusteeAddress === false) {
          return this.$t('app.no');
        }
        return undefined;
      },
      salutation(): string | undefined {
        const salutationEnum = this.address.salutation;
        if (!salutationEnum) {
          return undefined;
        }
        return this.$t(`form.address.salutationEnum.${salutationEnum}`);
      },
      ifIsNotDifferent(): boolean {
        const value = this.value as any ?? {};
        return value.isDifferentFromOwner === false || value.isEqualToDefaultAddress;
      },
      address(): ContactResourceViewModel['address'] {
        const value = this.value as any;
        return value?.address ?? this.value ?? {};
      },
    },
  });
</script>
