// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/business-unit-schema',
  type: 'object',
  required: ['number'],
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    number: {
      type: 'integer',
    },
    name: {
      type: 'string',
      nullable: true,
    },
    unitName: {
      type: 'string',
      nullable: true,
    },
    isMembershipAdditionalInfoRequired: {
      type: 'boolean',
    },
    isMembershipOnlinePaymentAllowed: {
      type: 'boolean',
    },
    isMembershipDirectRegistration: {
      type: 'boolean',
    },
    emailAddress: {
      type: 'string',
      nullable: true,
    },
    stateCode: {
      type: 'string',
      nullable: true,
    },
    phoneNumber: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
