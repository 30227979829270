// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateOrderInfoRequestModel from '../../model/update-order-info-request-model';
import UpdateOrderInfoRequestDTO from '../dto/update-order-info-request-dto';
import dtoSchema from '../schemas/update-order-info-request-schema';

export default abstract class UpdateOrderInfoRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateOrderInfoRequestDTO>) {
    super();
    if (data) {
      this.orderReference = data.orderReference ?? undefined;
      this.paymentMethod = data.paymentMethod;
      this.acceptAgb = data.acceptAgb;
      this.userId = data.userId;
    }
  }
  /**
  */
  orderReference?: string;
  /**
  * @type {int64}
  */
  paymentMethod?: 1 | 2; // ShopPaymentMethodDTO
  /**
  */
  acceptAgb?: boolean;
  /**
  * @type {int64}
  */
  userId?: number;

  static toModel(dto: DeepPartial<UpdateOrderInfoRequestDTO>): UpdateOrderInfoRequestModel;
  static toModel(dto: DeepPartial<UpdateOrderInfoRequestDTO> | undefined | null): UpdateOrderInfoRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateOrderInfoRequestDTO> | undefined | null): UpdateOrderInfoRequestModel | undefined {
    return dto ? new UpdateOrderInfoRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateOrderInfoRequestModel> | UpdateOrderInfoRequestModel): UpdateOrderInfoRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      orderReference: unwrapped.orderReference,
      paymentMethod: unwrapped.paymentMethod,
      acceptAgb: unwrapped.acceptAgb,
      userId: unwrapped.userId,
    } as UpdateOrderInfoRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateOrderInfoRequestModel, can not map to UpdateOrderInfoRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
