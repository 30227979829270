// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetIssuuDocumentsRequestModel from '../../model/get-issuu-documents-request-model';
import GetIssuuEmbedDocumentRequestModel from '../../model/get-issuu-embed-document-request-model';
import GetIssuuDocumentCoverRequestModel from '../../model/get-issuu-document-cover-request-model';
import IssuuLookupListResultModel from '../../model/issuu-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getIssuuDocuments(request: GetIssuuDocumentsRequestModel, config?: RequestConfig) {
    const requestDTO = GetIssuuDocumentsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-issuu-documents'] ?? 'IssuuDocuments/GetIssuuDocuments';
    const response = await service.get<any>(endpointPath, {
      query: {
        pageSize: requestDTO.pageSize,
        startIndex: requestDTO.startIndex,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(IssuuLookupListResultModel.toModel(dto));
  },
  /**  */
  async getIssuuEmbedDocument(request: GetIssuuEmbedDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = GetIssuuEmbedDocumentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-issuu-embed-document'] ?? 'IssuuDocuments/GetIssuuDocument/{id}/embed';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async getIssuuDocumentCover(request: GetIssuuDocumentCoverRequestModel, config?: RequestConfig) {
    const requestDTO = GetIssuuDocumentCoverRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-issuu-document-cover'] ?? 'IssuuDocuments/GetIssuuDocument/{id}/cover';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
});
