// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSubscriptionArticlesRequestModel from '../../model/get-subscription-articles-request-model';
import GetSubscriptionContractRequestModel from '../../model/get-subscription-contract-request-model';
import GetInitialSubscriptionRequestRequestModel from '../../model/get-initial-subscription-request-request-model';
import AddSubscriptionRequestModel from '../../model/add-subscription-request-model';
import SubscriptionArticleListResultModel from '../../model/subscription-article-list-result-model';
import SubscriptionContractModel from '../../model/subscription-contract-model';
import CreateSubscriptionContractRequestModel from '../../model/create-subscription-contract-request-model';

export default (service: Servicelayer) => ({
  /**  */
  async getSubscriptionArticles(request: GetSubscriptionArticlesRequestModel, config?: RequestConfig) {
    const requestDTO = GetSubscriptionArticlesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-subscription-articles'] ?? 'Subscriptions/{subscriptionType}/Articles';
    endpointPath = endpointPath.replace(/\{subscriptionType\}/g, `${requestDTO.subscriptionType}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SubscriptionArticleListResultModel.toModel(dto));
  },
  /**  */
  async getSubscriptionContract(request: GetSubscriptionContractRequestModel, config?: RequestConfig) {
    const requestDTO = GetSubscriptionContractRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-subscription-contract'] ?? 'Subscriptions/{subscriptionType}/{userId}';
    endpointPath = endpointPath.replace(/\{subscriptionType\}/g, `${requestDTO.subscriptionType}`);
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SubscriptionContractModel.toModel(dto));
  },
  /**  */
  async getInitialSubscriptionRequest(request: GetInitialSubscriptionRequestRequestModel, config?: RequestConfig) {
    const requestDTO = GetInitialSubscriptionRequestRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-initial-subscription-request'] ?? 'Subscriptions/GetInitialRequest';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CreateSubscriptionContractRequestModel.toModel(dto));
  },
  /**  */
  async addSubscription(request: AddSubscriptionRequestModel, config?: RequestConfig) {
    const requestDTO = AddSubscriptionRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-subscription'] ?? 'Subscriptions/{subscriptionType}';
    endpointPath = endpointPath.replace(/\{subscriptionType\}/g, `${requestDTO.subscriptionType}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        subscriptionType: requestDTO.subscriptionType,
        subscriptionTemplateNumber: requestDTO.subscriptionTemplateNumber,
        entryDate: requestDTO.entryDate,
        isBillingContactReadonly: requestDTO.isBillingContactReadonly,
        billingContact: requestDTO.billingContact,
        isDeliveryContactReadonly: requestDTO.isDeliveryContactReadonly,
        deliveryContact: requestDTO.deliveryContact,
        orderBasket: requestDTO.orderBasket,
        summary: requestDTO.summary,
        newsletter: requestDTO.newsletter,
        languageIso2: requestDTO.languageIso2,
        acceptGdpr: requestDTO.acceptGdpr,
        acceptAgb: requestDTO.acceptAgb,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
