import Vue from 'vue';
import DynamicFormFieldResourceModel from '../../generated/types/model/resource/DynamicFormFieldResourceModel';

export default class DynamicFormFieldResourceViewModel extends DynamicFormFieldResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  // get label() {
  //   const lang = Vue.$translation.get();
  //   return this.description[lang.toUpperCase()];
  // }
}
