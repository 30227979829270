// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import LookupCategoriesRequestDTO from '../../dto/request/LookupCategoriesRequestDTO';

export default abstract class LookupCategoriesRequestModel extends BaseModel<LookupCategoriesRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get key() { return this.dto.key; }
  set key(value) { this.dto.key = value; }
  /**
  */
  get searchCriteria() { return this.dto.searchCriteria; }
  set searchCriteria(value) { this.dto.searchCriteria = value; }
  /**
  * @type {int32}
  */
  get 'paging.CurrentPage'() { return this.dto['paging.CurrentPage']; }
  set 'paging.CurrentPage'(value) { this.dto['paging.CurrentPage'] = value; }
  /**
  * @type {int32}
  */
  get 'paging.PageSize'() { return this.dto['paging.PageSize']; }
  set 'paging.PageSize'(value) { this.dto['paging.PageSize'] = value; }
  /**
  */
  get 'paging.IncludeCount'() { return this.dto['paging.IncludeCount']; }
  set 'paging.IncludeCount'(value) { this.dto['paging.IncludeCount'] = value; }
  /**
  */
  get 'paging.NoPaging'() { return this.dto['paging.NoPaging']; }
  set 'paging.NoPaging'(value) { this.dto['paging.NoPaging'] = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.key = qs.key;
    this.dto.searchCriteria = qs.searchcriteria;
    value = Number.parseFloat(qs['paging.currentpage']);
    value = Number.isNaN(value) ? undefined : value;
    this.dto['paging.CurrentPage'] = value;
    value = Number.parseFloat(qs['paging.pagesize']);
    value = Number.isNaN(value) ? undefined : value;
    this.dto['paging.PageSize'] = value;
    value = qs['paging.IncludeCount'] === 'true' ? true : qs['paging.includecount'];
    value = qs['paging.IncludeCount'] === 'false' ? false : undefined;
    this.dto['paging.IncludeCount'] = value;
    value = qs['paging.NoPaging'] === 'true' ? true : qs['paging.nopaging'];
    value = qs['paging.NoPaging'] === 'false' ? false : undefined;
    this.dto['paging.NoPaging'] = value;
  }
}
