import Vue from 'vue';
import BasketItemResourceModel from '../../generated/types/model/resource/BasketItemResourceModel';

export default class BasketItemResourceViewModel extends BasketItemResourceModel {
  protected transformToDTO() {
    this.dto.grossPrice = this.grossPrice;
  }
  protected transformFromDTO() {}

  get grossPrice() {
    const price = this.unitPrice ?? 0;
    const quantity = this.quantity ?? 0;
    return price * quantity;
  }

  get articleDisplay() {
    return `${this.caption} - ${Vue.$format.currency(this.unitPrice!, this.currency as any ?? 'CHF')}`;
  }
}
