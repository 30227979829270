<template>
  <span class="language-selection">
    <a :class="{'active-language': currentLanguage == 'de'}" @click="setLanguage('de')">DE</a> |
    <a :class="{'active-language': currentLanguage == 'it'}" @click="setLanguage('it')">IT</a> |
    <a :class="{'active-language': currentLanguage == 'fr'}" @click="setLanguage('fr')">FR</a>
  </span>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    computed: {
      currentLanguage(): string {
        return this.$translation.get() ?? this.$config.values['language-default'];
      },
    },
    mounted() {
      this.setLanguage(this.currentLanguage);
    },
    methods: {
      setLanguage(languageCode: string) {
        if (languageCode !== this.$translation.get()) {
          this.$translation.set(languageCode);
          this.$nextTick(() => {
            window.location.reload();
          });
        }
      },
    },
  });
</script>
