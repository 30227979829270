import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ShopBasketApi from '../generated/api/ShopBasket';
import AddToBasketRequestViewModel from '../viewModel/request/ShopBasket/AddToBasketRequestViewModel';
import AddShopBasketItemRequestViewModel from '../viewModel/resource/AddShopBasketItemRequestViewModel';
import GetBasketRequestViewModel from '../viewModel/request/ShopBasket/GetBasketRequestViewModel';
import GetMiniBasketRequestViewModel from '../viewModel/request/ShopBasket/GetMiniBasketRequestViewModel';
import RemoveFromBasketRequestViewModel from '../viewModel/request/ShopBasket/RemoveFromBasketRequestViewModel';
import UpdateBasketItemRequestViewModel from '../viewModel/request/ShopBasket/UpdateBasketItemRequestViewModel';
import UpdateShopBasketItemRequestViewModel from '../viewModel/resource/UpdateShopBasketItemRequestViewModel';

export default (service: Servicelayer) => ({
  ...ShopBasketApi(service),

  getPublicBasketId() {
    return Vue.$sessionStorage.get<string>('publicBasketId') ?? undefined;
  },

  savePublicBasketId(publicBasketId: string) {
    Vue.$sessionStorage.set('publicBasketId', publicBasketId);
  },

  clearPublicBasketId() {
    Vue.$sessionStorage.remove('publicBasketId');
  },

  refreshPublicBasketId(publicBasketId: string) {
    // To avoid duplicating baskets between different users
    // (X: Login, Logout  ->  ANON: check basket  ->  ANON has X Basket)
    // So we make sure to clear the storage when logged in
    if (!Vue.$auth.isLoggedIn) {
      this.savePublicBasketId(publicBasketId!);
    }
  },

  async addToBasket(request: AddShopBasketItemRequestViewModel, config?: RequestConfig) {
    const query = new AddToBasketRequestViewModel();
    query.publicBasketId = this.getPublicBasketId();
    if (Vue.$auth.isLoggedIn) {
      const user = await Vue.$auth.getUser();
      query.userId = user?.id as undefined | number;
    }
    const publicBasketId = await ShopBasketApi(service).addToBasket(request, query, config);
    this.refreshPublicBasketId(publicBasketId!);
    Vue.$eventbus.emit('basketChange');
    return publicBasketId;
  },

  async getBasket(config?: RequestConfig) {
    const request = new GetBasketRequestViewModel();
    request.publicBasketId = this.getPublicBasketId();
    if (Vue.$auth.isLoggedIn) {
      const user = await Vue.$auth.getUser();
      request.userId = user?.id as undefined | number;
    }
    const basket = await ShopBasketApi(service).getBasket(request, config);
    if (Vue.$auth.isLoggedIn) {
      this.clearPublicBasketId();
    }
    this.refreshPublicBasketId(basket.publicBasketId!);
    return basket;
  },

  async getMiniBasket(config?: RequestConfig) {
    const request = new GetMiniBasketRequestViewModel();
    request.publicBasketId = this.getPublicBasketId();
    if (Vue.$auth.isLoggedIn) {
      const user = await Vue.$auth.getUser();
      request.userId = user?.id as undefined | number;
    }
    const basket = await ShopBasketApi(service).getMiniBasket(request, config);
    if (Vue.$auth.isLoggedIn) {
      this.clearPublicBasketId();
    }
    this.refreshPublicBasketId(basket.publicBasketId!);
    return basket;
  },

  async removeFromBasket(id: number, config?: RequestConfig) {
    const request = new RemoveFromBasketRequestViewModel();
    request.publicBasketId = this.getPublicBasketId();
    if (Vue.$auth.isLoggedIn) {
      const user = await Vue.$auth.getUser();
      request.userId = user?.id as undefined | number;
    }
    const publicBasketId = await ShopBasketApi(service).removeFromBasket(id, request);
    this.refreshPublicBasketId(publicBasketId!);
    Vue.$eventbus.emit('basketChange');
    return publicBasketId;
  },

  async updateBasketItem(model: UpdateShopBasketItemRequestViewModel, config?: RequestConfig) {
    const query = new UpdateBasketItemRequestViewModel();
    query.publicBasketId = this.getPublicBasketId();
    if (Vue.$auth.isLoggedIn) {
      const user = await Vue.$auth.getUser();
      query.userId = user?.id as undefined | number;
    }
    const publicBasketId = await ShopBasketApi(service).updateBasketItem(model.articleId, model, query);
    this.refreshPublicBasketId(publicBasketId!);
    Vue.$eventbus.emit('basketChange');
    return publicBasketId;
  },

});
