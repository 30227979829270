// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GastronomyModel from '../../model/gastronomy-model';
import GastronomyDTO from '../dto/gastronomy-dto';
import dtoSchema from '../schemas/gastronomy-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class GastronomyModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GastronomyDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.businessTypeId = data.businessTypeId ?? undefined;
      this.businessTypeName = data.businessTypeName ?? undefined;
      this.placesHall = data.placesHall ?? undefined;
      this.placesTerrace = data.placesTerrace ?? undefined;
      this.placesInHouse = data.placesInHouse ?? undefined;
      this.isWheelchairAccessible = data.isWheelchairAccessible ?? undefined;
      this.isRestRoomHandicappedAccessible = data.isRestRoomHandicappedAccessible ?? undefined;
      this.hasSmokingRoom = data.hasSmokingRoom ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {uuid}
  */
  businessTypeId?: string;
  /**
  */
  businessTypeName?: string;
  /**
  * @maximum 1000000
  * @type {int32}
  */
  placesHall?: number;
  /**
  * @maximum 1000000
  * @type {int32}
  */
  placesTerrace?: number;
  /**
  * @maximum 1000000
  * @type {int32}
  */
  placesInHouse?: number;
  /**
  */
  isWheelchairAccessible?: boolean;
  /**
  */
  isRestRoomHandicappedAccessible?: boolean;
  /**
  */
  hasSmokingRoom?: boolean;

  static toModel(dto: DeepPartial<GastronomyDTO>): GastronomyModel;
  static toModel(dto: DeepPartial<GastronomyDTO> | undefined | null): GastronomyModel | undefined;
  static toModel(dto: DeepPartial<GastronomyDTO> | undefined | null): GastronomyModel | undefined {
    return dto ? new GastronomyModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GastronomyModel> | GastronomyModel): GastronomyDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      businessTypeId: unwrapped.businessTypeId,
      businessTypeName: unwrapped.businessTypeName,
      placesHall: unwrapped.placesHall,
      placesTerrace: unwrapped.placesTerrace,
      placesInHouse: unwrapped.placesInHouse,
      isWheelchairAccessible: unwrapped.isWheelchairAccessible,
      isRestRoomHandicappedAccessible: unwrapped.isRestRoomHandicappedAccessible,
      hasSmokingRoom: unwrapped.hasSmokingRoom,
    } as GastronomyDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GastronomyModel, can not map to GastronomyDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
