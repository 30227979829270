// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ConsultingCaseStatusResourceViewModel from '../../../../viewModel/resource/ConsultingCaseStatusResourceViewModel';
import ConsultingCaseListResourceViewModel from '../../../../viewModel/resource/ConsultingCaseListResourceViewModel';
import ConsultingCaseListResourceDTO from '../../dto/resource/ConsultingCaseListResourceDTO';

export default abstract class ConsultingCaseListResourceModel extends BaseModel<ConsultingCaseListResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {uuid}
  */
  get customerContactId() { return this.dto.customerContactId; }
  set customerContactId(value) { this.dto.customerContactId = value; }
  /**
  */
  get subject() { return this.dto.subject; }
  set subject(value) { this.dto.subject = value; }
  /**
  * @type {date-time}
  */
  get lastEdited() { return this.dto.lastEdited; }
  set lastEdited(value) { this.dto.lastEdited = value; }
  /**
  * @type {int32}
  */
  get status() { return this.dto.status; }
  set status(value) { this.dto.status = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.customerContactId = qs.customercontactid;
    this.dto.subject = qs.subject;
    this.dto.lastEdited = qs.lastedited;
    value = Number.parseFloat(qs.status);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.status = value;
  }
}
