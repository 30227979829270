// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateShopArticleRequestModel from '../../model/update-shop-article-request-model';
import UpdateShopArticleRequestDTO from '../dto/update-shop-article-request-dto';
import dtoSchema from '../schemas/update-shop-article-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class UpdateShopArticleRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateShopArticleRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.supplierId = data.supplierId ?? undefined;
      this.articleNumber = data.articleNumber ?? undefined;
      this.crmArticleId = data.crmArticleId ?? undefined;
      this.supplierArticleNumber = data.supplierArticleNumber ?? undefined;
      this.caption = data.caption ?? undefined;
      this.shortDescription = data.shortDescription ?? undefined;
      this.description = data.description ?? undefined;
      this.mainPictureId = data.mainPictureId ?? undefined;
      this.picture1Id = data.picture1Id ?? undefined;
      this.picture2Id = data.picture2Id ?? undefined;
      this.picture3Id = data.picture3Id ?? undefined;
      this.hasShipping = data.hasShipping;
      this.additionalInfo = data.additionalInfo ?? undefined;
      this.validFrom = data.validFrom;
      this.validTo = data.validTo ?? undefined;
      this.isPublished = data.isPublished;
      this.taxId = data.taxId;
      this.translatedLanguage_ISO2 = data.translatedLanguage_ISO2;
      this.hasLicense = data.hasLicense;
      this.isDownload = data.isDownload;
      this.digitalAssetId = data.digitalAssetId ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  supplierId?: number;
  /**
  */
  articleNumber?: string;
  /**
  */
  crmArticleId?: string;
  /**
  */
  supplierArticleNumber?: string;
  /**
  */
  caption?: string;
  /**
  */
  shortDescription?: string;
  /**
  */
  description?: string;
  /**
  * @type {int64}
  */
  mainPictureId?: number;
  /**
  * @type {int64}
  */
  picture1Id?: number;
  /**
  * @type {int64}
  */
  picture2Id?: number;
  /**
  * @type {int64}
  */
  picture3Id?: number;
  /**
  */
  hasShipping?: boolean;
  /**
  */
  additionalInfo?: string;
  /**
  * @type {date-time}
  */
  validFrom?: string;
  /**
  * @type {date-time}
  */
  validTo?: string;
  /**
  */
  isPublished?: boolean;
  /**
  * @type {int64}
  */
  taxId?: number;
  /**
  * @minimum 1
  */
  translatedLanguage_ISO2?: string;
  /**
  */
  hasLicense?: boolean;
  /**
  */
  isDownload?: boolean;
  /**
  * @type {int64}
  */
  digitalAssetId?: number;

  static toModel(dto: DeepPartial<UpdateShopArticleRequestDTO>): UpdateShopArticleRequestModel;
  static toModel(dto: DeepPartial<UpdateShopArticleRequestDTO> | undefined | null): UpdateShopArticleRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateShopArticleRequestDTO> | undefined | null): UpdateShopArticleRequestModel | undefined {
    return dto ? new UpdateShopArticleRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateShopArticleRequestModel> | UpdateShopArticleRequestModel): UpdateShopArticleRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      supplierId: unwrapped.supplierId,
      articleNumber: unwrapped.articleNumber,
      crmArticleId: unwrapped.crmArticleId,
      supplierArticleNumber: unwrapped.supplierArticleNumber,
      caption: unwrapped.caption,
      shortDescription: unwrapped.shortDescription,
      description: unwrapped.description,
      mainPictureId: unwrapped.mainPictureId,
      picture1Id: unwrapped.picture1Id,
      picture2Id: unwrapped.picture2Id,
      picture3Id: unwrapped.picture3Id,
      hasShipping: unwrapped.hasShipping,
      additionalInfo: unwrapped.additionalInfo,
      validFrom: unwrapped.validFrom,
      validTo: unwrapped.validTo,
      isPublished: unwrapped.isPublished,
      taxId: unwrapped.taxId,
      translatedLanguage_ISO2: unwrapped.translatedLanguage_ISO2,
      hasLicense: unwrapped.hasLicense,
      isDownload: unwrapped.isDownload,
      digitalAssetId: unwrapped.digitalAssetId,
    } as UpdateShopArticleRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateShopArticleRequestModel, can not map to UpdateShopArticleRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
