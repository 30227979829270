import Vue from 'vue';
import apiKeysServices from '../api/ApiKeys';
import appServices from '../api/App';
import appLogsServices from '../api/AppLogs';
import appSystemServices from '../api/AppSystem';
import appSystemsServices from '../api/AppSystems';
import appSystemWebApisServices from '../api/AppSystemWebApis';
import attributesServices from '../api/Attributes';
import authServices from '../api/Auth';
import businessUnitsServices from '../api/BusinessUnits';
import callLogsServices from '../api/CallLogs';
import consultingCasesServices from '../api/ConsultingCases';
import contactIdMappingsServices from '../api/ContactIdMappings';
import digitalAssetServices from '../api/DigitalAsset';
import documentsServices from '../api/Documents';
import dynamicFormServices from '../api/DynamicForm';
import dynamicFormsServices from '../api/DynamicForms';
import esuranceServices from '../api/ESurance';
import fieldMappingServices from '../api/FieldMapping';
import hotelClassificationsServices from '../api/HotelClassifications';
import httpMethodLookupServices from '../api/HttpMethodLookup';
import hygienecontrolServices from '../api/Hygienecontrol';
import integrationCallsServices from '../api/IntegrationCalls';
import integrationTasksServices from '../api/IntegrationTasks';
import issuuDocumentsServices from '../api/IssuuDocuments';
import locationsServices from '../api/Locations';
import meServices from '../api/Me';
import membershipsServices from '../api/Memberships';
import onlinePaymentTransactionServices from '../api/OnlinePaymentTransaction';
import processesServices from '../api/Processes';
import sequencenumbersServices from '../api/Sequencenumbers';
import shopArticleDigitalAssetServices from '../api/ShopArticleDigitalAsset';
import shopArticlesServices from '../api/ShopArticles';
import shopArticleTaxesServices from '../api/ShopArticleTaxes';
import shopArticleTaxHistoriesServices from '../api/ShopArticleTaxHistories';
import shopBasketServices from '../api/ShopBasket';
import shopCategoriesServices from '../api/ShopCategories';
import shopCheckoutServices from '../api/ShopCheckout';
import shopOrderItemsServices from '../api/ShopOrderItems';
import shopOrdersServices from '../api/ShopOrders';
import shopPaymentsServices from '../api/ShopPayments';
import shopSuppliersServices from '../api/ShopSuppliers';
import subscriptionsServices from '../api/Subscriptions';
import tenantsServices from '../api/Tenants';
import usersServices from '../api/Users';

export default () => ({
  apiKeys: { ...apiKeysServices(Vue.$service) },
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  appSystems: { ...appSystemsServices(Vue.$service) },
  appSystemWebApis: { ...appSystemWebApisServices(Vue.$service) },
  attributes: { ...attributesServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  businessUnits: { ...businessUnitsServices(Vue.$service) },
  callLogs: { ...callLogsServices(Vue.$service) },
  consultingCases: { ...consultingCasesServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  digitalAsset: { ...digitalAssetServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForm: { ...dynamicFormServices(Vue.$service) },
  dynamicForms: { ...dynamicFormsServices(Vue.$service) },
  esurance: { ...esuranceServices(Vue.$service) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  hotelClassifications: { ...hotelClassificationsServices(Vue.$service) },
  httpMethodLookup: { ...httpMethodLookupServices(Vue.$service) },
  hygienecontrol: { ...hygienecontrolServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  issuuDocuments: { ...issuuDocumentsServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  memberships: { ...membershipsServices(Vue.$service) },
  onlinePaymentTransaction: { ...onlinePaymentTransactionServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  shopArticleDigitalAsset: { ...shopArticleDigitalAssetServices(Vue.$service) },
  shopArticles: { ...shopArticlesServices(Vue.$service) },
  shopArticleTaxes: { ...shopArticleTaxesServices(Vue.$service) },
  shopArticleTaxHistories: { ...shopArticleTaxHistoriesServices(Vue.$service) },
  shopBasket: { ...shopBasketServices(Vue.$service) },
  shopCategories: { ...shopCategoriesServices(Vue.$service) },
  shopCheckout: { ...shopCheckoutServices(Vue.$service) },
  shopOrderItems: { ...shopOrderItemsServices(Vue.$service) },
  shopOrders: { ...shopOrdersServices(Vue.$service) },
  shopPayments: { ...shopPaymentsServices(Vue.$service) },
  shopSuppliers: { ...shopSuppliersServices(Vue.$service) },
  subscriptions: { ...subscriptionsServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
});
