import coreRoutes from '@glittr/frontend-core/src/routes';

export default {

  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    // #region redirects
    // redirects from old to new link because old links are still circulating
    {
      path: '/gs/rosetta',
      name: 'redirect-dashboard',
      redirect: '/gs/memberPortal',
    },
    {
      path: '/gs/rosetta/dynamic-form-test',
      name: 'redirect-dynamic-form-test',
      redirect: '/gs/memberPortal/dynamic-form-test',
    },
    {
      path: '/gs/rosetta/member-data',
      name: 'redirect-member-data',
      redirect: '/gs/memberPortal/member-data',
    },
    {
      path: '/gs/rosetta/member-data/member-data-detail',
      name: 'redirect-member-data-detail',
      redirect: '/gs/memberPortal/member-data/member-data-detail',
    },
    {
      path: '/gs/rosetta/member-data/businesses',
      name: 'redirect-businesses-list',
      redirect: '/gs/memberPortal/member-data/businesses',
    },
    {
      path: '/gs/rosetta/member-data/businesses/:id',
      name: 'redirect-businesses-detail',
      redirect: '/gs/memberPortal/member-data/businesses/:id',
    },
    {
      path: '/gs/rosetta/member-data/documents',
      name: 'redirect-documents-list',
      redirect: '/gs/memberPortal/member-data/documents',
    },
    {
      path: '/gs/rosetta/member-data/change-member-address',
      name: 'redirect-change-member-address',
      redirect: '/gs/memberPortal/member-data/change-member-address',
    },
    {
      path: '/gs/rosetta/legal-service',
      name: 'redirect-legal-service',
      redirect: '/gs/memberPortal/legal-service',
    },
    {
      path: '/gs/rosetta/legal-service/legal-enquiry-overview',
      name: 'redirect-legal-service-overview',
      redirect: '/gs/memberPortal/legal-service/legal-enquiry-overview',
    },
    {
      path: '/gs/rosetta/legal-service/send-legal-enquiry',
      name: 'redirect-legal-service-send-enquiry',
      redirect: '/gs/memberPortal/legal-service/send-legal-enquiry',
    },
    {
      path: '/gs/rosetta/legal-service/legal-enquiry-sent',
      name: 'redirect-legal-service-enquiry-sent',
      redirect: '/gs/memberPortal/legal-service/legal-enquiry-sent',
    },
    {
      path: '/gs/rosetta/gastro-journal',
      name: 'redirect-gastro-journal',
      redirect: '/gs/memberPortal/gastro-journal',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/detail',
      name: 'redirect-gastro-journal-subscription-detail',
      redirect: '/gs/memberPortal/gastro-journal/subscription/detail',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/forward',
      name: 'redirect-gastro-journal-subscription-forward',
      redirect: '/gs/memberPortal/gastro-journal/subscription/forward',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/interrupt',
      name: 'redirect-gastro-journal-subscription-interrupt',
      redirect: '/gs/memberPortal/gastro-journal/subscription/interrupt',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/list',
      name: 'redirect-gastro-journal-subscription-list',
      redirect: '/gs/memberPortal/gastro-journal/subscription/list',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/pause',
      name: 'redirect-gastro-journal-subscription-pause',
      redirect: '/gs/memberPortal/gastro-journal/subscription/pause',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/terminate',
      name: 'redirect-gastro-journal-subscription-terminate',
      redirect: '/gs/memberPortal/gastro-journal/subscription/terminate',
    },
    {
      path: '/gs/rosetta/gastro-journal-publications',
      name: 'redirect-gastro-journal-publications',
      redirect: '/gs/memberPortal/gastro-journal-publications',
    },
    {
      path: '/gs/rosetta/gastro-professional',
      name: 'redirect-gastro-professional',
      redirect: '/gs/memberPortal/branchen-know-how',
    },
    {
      path: '/gs/memberPortal/gastro-professional',
      name: 'redirect-gastro-professional-legacy',
      redirect: '/gs/memberPortal/branchen-know-how',
    },
    {
      path: '/gs/rosetta/hotel-classification',
      name: 'redirect-hotel-classification',
      redirect: '/gs/memberPortal/hotel-classification',
    },
    {
      path: '/gs/rosetta/hotel-classification/list',
      name: 'redirect-hotel-classification-list',
      redirect: '/gs/memberPortal/hotel-classification/list',
    },
    {
      path: '/gs/rosetta/processes/create-membership',
      name: 'redirect-processes-create-membership',
      redirect: '/gs/memberPortal/processes/create-membership',
    },
    {
      path: '/gs/rosetta/processes/link-membership',
      name: 'redirect-processes-link-membership',
      redirect: '/gs/memberPortal/processes/link-membership',
    },
    {
      path: '/gs/rosetta/processes/classify-hotel',
      name: 'redirect-processes-classify-hotel',
      redirect: '/gs/memberPortal/processes/classify-hotel',
    },
    {
      path: '/gs/rosetta/processes/classify-hotel/:id',
      name: 'redirect-processes-classify-hotel-edit',
      redirect: '/gs/memberPortal/processes/classify-hotel/:id',
    },
    {
      path: '/gs/rosetta/processes/new-gastro-journal-subscription',
      name: 'redirect-processes-new-gastro-journal-subscription',
      redirect: '/gs/memberPortal/processes/new-gastro-journal-subscription',
    },
    {
      path: '/gs/rosetta/support',
      name: 'redirect-support-list',
      redirect: '/gs/memberPortal/support',
    },
    {
      path: '/gs/rosetta/support/feedback',
      name: 'redirect-support-feedback',
      redirect: '/gs/memberPortal/support/feedback',
    },
    // #endregion

    // #region routes
    // routes to pages
    {
      path: '/_exterior/login',
      name: 'gs-login',
      component: () => import('./views/pages/_exterior/login.vue'),
    },
    {
      path: '/_exterior/register',
      name: 'gs-registration',
      component: () => import('./views/pages/_exterior/registration.vue'),
    },
    {
      path: '/_exterior/link-with-code',
      name: 'gs-link-with-code',
      component: () => import('./views/pages/_exterior/link-with-code.vue'),
    },
    {
      path: '/_exterior/confirm-new-password',
      name: 'confirm-new-password',
      component: () => import('./views/pages/_exterior/confirm-new-password.vue'),
    },
    {
      path: '/_exterior/confirm-new-password/:email',
      name: 'confirm-new-password-email',
      component: () => import('./views/pages/_exterior/confirm-new-password.vue'),
    },
    {
      path: '/',
      name: 'gs-home',
      redirect: '/gs/',
    },
    ...coreRoutes,
    {
      path: '/:tenant',
      name: 'dashboard',
      component: () => import('./views/pages/index.vue'),
    },
    {
      path: '/_admin/shop/orders',
      name: 'shop-orders-admin',
      component: () => import('./views/pages/_admin/shop/orders/list.vue'),
    },
    {
      path: '/_admin/shop/digital-assets',
      name: 'shop-digital-assets',
      component: () => import('./views/pages/_admin/shop/digital-assets/list.vue'),
    },
    {
      path: '/_admin/shop/digital-assets/create',
      name: 'shop-digital-assets-create',
      component: () => import('./views/pages/_admin/shop/digital-assets/detailEdit.vue'),
    },
    {
      path: '/_admin/shop/digital-assets/edit/:id',
      name: 'shop-digital-assets-edit',
      component: () => import('./views/pages/_admin/shop/digital-assets/detailEdit.vue'),
    },
    {
      path: '/_admin/shop/orders/:id',
      name: 'shop-orders-edit-admin',
      component: () => import('./views/pages/_admin/shop/orders/detail.vue'),
    },
    {
      path: '/_admin/shop/articles',
      name: 'shop-articles-admin',
      component: () => import('./views/pages/_admin/shop/articles/list.vue'),
    },
    {
      path: '/:tenant/shop/',
      name: 'shop-dashboard',
      component: () => import('./views/pages/shop/index.vue'),
    },
    {
      path: '/:tenant/shop/articles',
      name: 'shop-articles-list',
      component: () => import('./views/pages/shop/articles/list.vue'),
    },
    {
      path: '/:tenant/shop/digital-asset/download/:orderId/:orderItemId/:assetId',
      name: 'shop-digital-asset-downlad',
      component: () => import('./views/pages/shop/digital-asset/download.vue'),
    },
    {
      path: '/:tenant/shop/articles/create',
      name: 'shop-articles-create',
      component: () => import('./views/pages/_admin/shop/articles/detailEdit.vue'),
    },
    {
      path: '/:tenant/shop/articles/:id',
      name: 'shop-articles-detail',
      component: () => import('./views/pages/shop/articles/detail.vue'),
    },
    {
      path: '/:tenant/shop/articles/:id/edit',
      name: 'shop-articles-edit',
      component: () => import('./views/pages/_admin/shop/articles/detailEdit.vue'),
    },
    {
      path: '/:tenant/shop/categories/:id/edit',
      name: 'shop-categories-detail-edit',
      component: () => import('./views/pages/shop/categories/detailEdit.vue'),
    },
    {
      path: '/:tenant/shop/categories/create',
      name: 'shop-categories-create',
      component: () => import('./views/pages/shop/categories/detailEdit.vue'),
    },
    {
      path: '/:tenant/shop/basket',
      name: 'shop-basket',
      component: () => import('./views/pages/shop/basket/detail.vue'),
    },
    {
      path: '/:tenant/shop/checkout',
      name: 'shop-checkout',
      component: () => import('./views/pages/shop/checkout/checkout.vue'),
    },
    {
      path: '/:tenant/memberPortal/dynamic-form-test',
      name: 'dynamic-form-test',
      component: () => import('./views/pages/test/dynamicFormTest.vue'),
    },
    {
      path: '/:tenant/memberPortal',
      name: 'memberPortal-dashboard',
      component: () => import('./views/pages/memberPortal/memberData/memberDataOverview.vue'),
    },
    {
      path: '/:tenant/memberPortal/member-data',
      name: 'member-data',
      component: () => import('./views/pages/memberPortal/memberData/memberDataOverview.vue'),
    },
    {
      path: '/:tenant/memberPortal/member-data/member-data-detail',
      name: 'member-data-detail',
      component: () => import('./views/pages/memberPortal/memberData/memberDataDetail.vue'),
    },
    {
      path: '/:tenant/memberPortal/member-data/businesses',
      name: 'businesses-list',
      component: () => import('./views/pages/memberPortal/memberData/businessesList.vue'),
    },
    {
      path: '/:tenant/memberPortal/member-data/businesses/:id',
      name: 'businesses-detail',
      component: () => import('./views/pages/memberPortal/memberData/businessDetail.vue'),
    },
    {
      path: '/:tenant/memberPortal/member-data/documents',
      name: 'documents-list',
      component: () => import('./views/pages/memberPortal/memberData/documentsList.vue'),
    },
    {
      path: '/:tenant/memberPortal/member-data/change-member-address',
      name: 'change-member-address',
      component: () => import('./views/pages/memberPortal/memberData/changeMemberAddress.vue'),
    },
    {
      path: '/:tenant/memberPortal/legal-service',
      name: 'legal-service',
      component: () => import('./views/pages/memberPortal/legalService/legalService.vue'),
    },
    {
      path: '/:tenant/memberPortal/legal-service/legal-enquiry-overview',
      name: 'legal-service-overview',
      component: () => import('./views/pages/memberPortal/legalService/legalEnquiryOverview.vue'),
    },
    {
      path: '/:tenant/memberPortal/legal-service/send-legal-enquiry',
      name: 'legal-service-send-enquiry',
      component: () => import('./views/pages/memberPortal/legalService/sendLegalEnquiry.vue'),
    },
    {
      path: '/:tenant/memberPortal/legal-service/legal-enquiry-sent',
      name: 'legal-service-enquiry-sent',
      component: () => import('./views/pages/memberPortal/legalService/legalEnquirySent.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal',
      name: 'gastro-journal',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournal.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal/subscription/detail',
      name: 'gastro-journal-subscription-detail',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalSubscriptionDetail.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal/subscription/forward',
      name: 'gastro-journal-subscription-forward',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalSubscriptionForward.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal/subscription/interrupt',
      name: 'gastro-journal-subscription-interrupt',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalSubscriptionInterrupt.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal/subscription/list',
      name: 'gastro-journal-subscription-list',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalSubscriptionList.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal/subscription/pause',
      name: 'gastro-journal-subscription-pause',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalSubscriptionPause.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal/subscription/terminate',
      name: 'gastro-journal-subscription-terminate',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalSubscriptionTerminate.vue'),
    },
    {
      path: '/:tenant/memberPortal/gastro-journal-publications',
      name: 'gastro-journal-publications',
      component: () => import('./views/pages/memberPortal/gastroJournal/gastroJournalPublications.vue'),
    },
    {
      path: '/:tenant/memberPortal/branchen-know-how',
      name: 'branchen-know-how',
      component: () => import('./views/pages/memberPortal/branchenKnowHow/index.vue'),
    },
    {
      path: '/:tenant/memberPortal/hotel-classification',
      name: 'hotel-classification',
      component: () => import('./views/pages/memberPortal/hotelClassification/hotelClassification.vue'),
    },
    {
      path: '/:tenant/memberPortal/hotel-classification/list',
      name: 'hotel-classification-list',
      component: () => import('./views/pages/memberPortal/hotelClassification/hotelClassificationList.vue'),
    },
    {
      path: '/:tenant/memberPortal/processes/create-membership',
      name: 'processes-create-membership',
      component: () => import('./views/pages/memberPortal/processes/createMembership/createMembership.vue'),
    },
    {
      path: '/:tenant/memberPortal/processes/link-membership',
      name: 'processes-link-membership',
      component: () => import('./views/pages/memberPortal/processes/linkMembership/linkMembership.vue'),
    },
    {
      path: '/:tenant/memberPortal/processes/classify-hotel',
      name: 'processes-classify-hotel',
      component: () => import('./views/pages/memberPortal/processes/classifyHotel/classifyHotel.vue'),
    },
    {
      path: '/:tenant/memberPortal/processes/classify-hotel/:id',
      name: 'processes-classify-hotel-edit',
      component: () => import('./views/pages/memberPortal/processes/classifyHotel/classifyHotel.vue'),
    },
    {
      path: '/:tenant/memberPortal/processes/new-gastro-journal-subscription',
      name: 'processes-new-gastro-journal-subscription',
      component: () => import('./views/pages/memberPortal/processes/newGastroJournalSubscription/newGastroJournalSubscription.vue'),
    },
    {
      path: '/:tenant/memberPortal/support',
      name: 'support-list',
      component: () => import('./views/pages/memberPortal/support/support.vue'),
    },
    {
      path: '/:tenant/memberPortal/support/feedback',
      name: 'support-feedback',
      component: () => import('./views/pages/memberPortal/support/support-feedback.vue'),
    },
    // #endregion
  ],
};
